import { Config } from "../../configs/Config";
import { OneCallBack, PostDatasetCallback } from "../middleware/Middleware";

export const fetchAddedServices = (id, _callback) => {
    const url = `${Config().API_URL}jobservicelist/get/${id}`;
    OneCallBack(url, _callback);
};

export const deleteAddedServices = (id, _callback) => {
    const url = `${Config().API_URL}jobservicelist/destroylist/${id}`;
    OneCallBack(url, _callback);
};

export const jobServiceSave = (dataset, _callback) => {
    const url = `${Config().API_URL}jobservicelist/store`;
    PostDatasetCallback(url, dataset, _callback);
};

export const jobServiceUpdate = (id, id2, dataset, _callback) => {
    const url = `${Config().API_URL}jobservicelist/update/${id}/${id2}`;
    PostDatasetCallback(url, dataset, _callback);
};
