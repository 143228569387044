export const LoginCheck = () => {
    if (window.sessionStorage.getItem("token") === null || window.sessionStorage.getItem("token") === "null") {
        window.location.replace("/login");
        return (false);
    } else {
        return (true);
    }
    // return new Promise((resolve) => {
    //     if (window.sessionStorage.getItem("token") === null || window.sessionStorage.getItem("token") === "null") {
    //         window.location.replace("/login");
    //         resolve(false);
    //     } else {
    //         resolve(true);
    //     }
    // });
};