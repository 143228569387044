import { Link } from "react-router-dom";
import { LoginCheck } from "./LoginCheck";
import PropTypes from "prop-types";

function Header(props) {
    const loggedIN = LoginCheck();
    let name = window.sessionStorage.getItem("f_name") + " " + window.sessionStorage.getItem("l_name");
    if (loggedIN) {
        const logout = () => {

            window.sessionStorage.setItem("token", null);
            window.sessionStorage.setItem("f_name", null);
            window.sessionStorage.setItem("l_name", null);
            window.sessionStorage.setItem("user_id", null);
            window.location.replace("/login");
        };

        return (
            <header className="header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-6">
                            <div className="header-left d-flex align-items-center">
                                <div className="menu-toggle-btn mr-20">
                                    <button
                                        id="menu-toggle"
                                        className="main-btn btn-dark btn-hover"
                                    >
                                        <i className="lni lni-chevron-left me-2"></i> Menu
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-6">
                            <div className="header-right">
                                <div className="profile-box ml-15">
                                    <button
                                        className="dropdown-toggle bg-transparent border-0"
                                        type="button"
                                        id="profile"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <div className="profile-info">
                                            <div className="info">
                                                <h6>{name}</h6>
                                            </div>
                                        </div>
                                        <i className="lni lni-chevron-down"></i>
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="profile"
                                    >
                                        <li>
                                            <div onClick={logout}><i className="lni lni-exit"></i> Sign Out </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                            {props.quickButton ? <Link className="quick_button float-end" to={props.quickButton.link}>
                                <button className="btn btn-warning float-end" type="button">{"<--" + props.quickButton.text}</button>
                            </Link> : null}
                        </div>
                    </div>
                </div>
            </header>
        );
    }

}

Header.propTypes = {
    quickButton: PropTypes.any,
};

export default Header;