import { Config } from "../../configs/Config";
import { OneCallBackParam, OneCallBack } from "../middleware/Middleware";

export const fetchYearById = (id, param, _callback) => {
    const url = `${Config().API_URL}modelyear/show/${id}`;
    OneCallBackParam(url, param, _callback);
};

export const fetchYearList = (_callback) => {
    const url = `${Config().API_URL}modelyear/get`;
    OneCallBack(url, _callback);
};