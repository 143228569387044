import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchPartsPaginate, fetchPartsPaginatePage, fetchPartsSearchPaginate } from "../../../api/parts/Parts";
import Layout from "../Layout";

function Parts() {
    const title = "Part List";


    const [loading_status, setLoadingStatus] = useState("hide");
    const [data, setData] = useState([]);
    const [data_fetched, setDataFetched] = useState(false);
    const [show_deactivated, setShowDeactivated] = useState(false);
    const [show_out_of_stock, setShowOutOfStock] = useState(true);

    const { branchid } = useParams();
    const branch_id = branchid;

    const [items_per_page] = useState(20);
    const [links, setLinks] = useState([]);
    const [page_data, setPageData] = useState([]);

    useEffect(function () {
        if (!data_fetched) {
            setLoadingStatus("show");
            fetchPartsPaginate(branch_id, items_per_page, setData);
            setDataFetched(true);
        }
    }, [data_fetched]);

    useEffect(function () {
        if (data_fetched) {
            setLoadingStatus("hide");
            if (data.length !== 0) {
                setLinks(data.links);
                setPageData(data.data);

            }
        }
    }, [data]);

    const loadPage = (url) => {
        if (url !== null) {
            setLoadingStatus("show");
            fetchPartsPaginatePage(url, setData);
        }

    };

    const searchPart = (txt) => {
        if (txt === "") {
            fetchPartsPaginate(branch_id, items_per_page, setData);
        } else {
            fetchPartsSearchPaginate(branch_id, txt, items_per_page, setData);
        }

    };

    const handleOnChangeDe = () => {
        setShowDeactivated(!show_deactivated);
    };
    const handleOnChangeOFS = () => {
        setShowOutOfStock(!show_out_of_stock);
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/parts/add", "text": "Add Parts" });


    const Content =
        <div className="row">
            <div className="col-lg-6">
                <div className="input-style-1">
                    <label>Search Part</label>
                    <input onChange={e => searchPart(e.target.value)} name="search_part" type="text" placeholder="Search Part" />
                </div>
            </div>
            <div className="col-lg-6">
                <label>Filter Part</label>
                <div className="col-lg-12">
                    <div className="col-lg-6">
                        <div className="form-check checkbox-style mb-20">
                            <input className="form-check-input" type="checkbox" onChange={handleOnChangeDe} checked={show_deactivated} id="checkbox-1" />
                            <label className="form-check-label" htmlFor="checkbox-1">
                                Show Deactivated Parts</label>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-check checkbox-style mb-20">
                            <input className="form-check-input" type="checkbox" onChange={handleOnChangeOFS} checked={show_out_of_stock} id="checkbox-2" />
                            <label className="form-check-label" htmlFor="checkbox-2">
                                Show OUT-OF-STOCK Parts</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="card-style mb-30">
                    <div className="table-wrapper table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th><h6>Part Name</h6></th>
                                    <th><h6>Part Code</h6></th>
                                    <th><h6>Price</h6></th>
                                    <th><h6>Stock</h6></th>
                                    <th><h6>Status</h6></th>
                                    <th><h6>Actions</h6></th>
                                </tr>
                            </thead>
                            <tbody className={(show_deactivated ? "de-show" : "de-hidden") + " " + (show_out_of_stock ? "st-show" : "st-hidden")}>
                                {page_data.map((object) => (
                                    <tr key={object.part_id} className={"part-" + object.part_status + " st-" + object.count}>
                                        <td className="min-width">
                                            <div className="lead">
                                                <div className="lead-text">
                                                    <p>{object.part_name}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="min-width">
                                            <div className="lead-text">
                                                <p>{object.part_code}</p>
                                            </div>
                                        </td>
                                        <td className="min-width">
                                            <div className="lead-text">
                                                <p>{object.purchased_price}</p>
                                            </div>
                                        </td>
                                        <td className="min-width">
                                            <div className="lead-text">
                                                <p>{object.count}</p>
                                            </div>
                                        </td>
                                        <td className="min-width">
                                            <div className="lead-text">
                                                <p>{object.part_status}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                <Link to={`/manager/branch/${branch_id}/parts/edit/${object.part_id}`} >Edit</Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <nav aria-label="...">
                    <ul className="pagination">
                        {links.map((object) => (
                            <li key={object.label} className={`page-item ${(object.active ? "active" : "")}`}>
                                <div className="page-link" onClick={() => loadPage(object.url)}>{object.label}</div>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default Parts;