import React, { useEffect, useState } from "react";
import { fetchCity } from "../../../api/city/City";
import { customerSave, fetchCustomerByMobile } from "../../../api/customer/Customer";
import Swal from "sweetalert2";
import Layout from "../Layout";
import { useParams } from "react-router-dom";

function AddCustomer() {
    const title = "Add Customer";

    const [loading_status, setLoadingStatus] = useState("hide");
    const [f_name, setFirstName] = useState("");
    const [l_name, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [mobile, setMobile] = useState("");
    const [city_id, setCityId] = useState(0);
    const [city, setCity] = useState([]);
    const [data_fetched, setDataFetched] = useState(false);

    const [customer_ext, setCustomerExt] = useState(false);
    const [res, setres] = useState([]);
    const { branchid } = useParams();
    const branch_id = branchid;

    const checkCustomerExt = (res) => {
        setres(res);
        if (res.length !== 0) {
            setCustomerExt(true);
        } else {
            setCustomerExt(false);
        }
    };

    useEffect(function () {
        if (customer_ext) {
            Swal.fire(
                "Oops...!",
                "Customer " + res.f_name + " Already In Database/ Search By Phone Number " + res.mobile + "..!",
                "error"
            );
            setFirstName("");
            setLastName("");
            setAddress("");
            setMobile("");
            setCityId(0);
        }
    }, [customer_ext, res.mobile, res.f_name]);

    useEffect(function () {
        if (!data_fetched) {
            fetchCity(setCity);
            setDataFetched(true);
        }
    }, [data_fetched]);


    const saveReturn = (res) => {
        if (!isNaN(res)) {
            setFirstName("");
            setLastName("");
            setAddress("");
            setMobile("");
            setCityId(0);
            Swal.fire(
                "Good job!",
                "Customer Saved..!",
                "success"
            );
        }
        setLoadingStatus("hide");

    };

    const validateDataset = (dataset) => {
        if (dataset.f_name !== "" && dataset.l_name !== "" && dataset.address !== "" && dataset.mobile !== "" && city_id !== 0 && !isNaN(dataset.mobile)) {
            if ((dataset.mobile).length === 10) {
                setLoadingStatus("show");
                customerSave(branch_id, dataset, saveReturn);
            } else {
                Swal.fire(
                    "Error Mobile Number...!",
                    "Need to start with 0 and number count should be 10..!",
                    "error"
                );
            }

        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ f_name: f_name, l_name: l_name, address: address, mobile: mobile, city_id: city_id });
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/customer/list", "text": "Customer List" });

    const Content = <div className="row">
        <div className="col-lg-12">
            <form onSubmit={handleSubmit}>
                <div className="card-style mb-30">
                    <div className="input-style-1">
                        <label>First Name</label>
                        <input value={f_name} onChange={e => setFirstName(e.target.value)} name='f_name' type="text" placeholder="First Name" />
                    </div>
                    <div className="input-style-1">
                        <label>Last Name</label>
                        <input value={l_name} onChange={e => setLastName(e.target.value)} name='l_name' type="text" placeholder="Last Name" />
                    </div>
                    <div className="input-style-2">
                        <label>Address</label>
                        <input value={address} onChange={e => setAddress(e.target.value)} name='address' type="text" placeholder="Address" />
                    </div>
                    <div className="input-style-1">
                        <label>Mobile</label>
                        <input value={mobile} onChange={e => { setMobile(e.target.value); fetchCustomerByMobile(e.target.value, branch_id, checkCustomerExt); }} name='mobile' type="text" placeholder="Mobile" />
                    </div>
                    <div className="select-style-1">
                        <label>City</label>
                        <div className="select-position">
                            <select value={city_id} onChange={e => setCityId(e.target.value)} >
                                <option value={0} disabled="disabled">Select City</option>
                                {city.map((object) => (
                                    <option key={object.city_id} value={object.city_id}>{object.city_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <input className="btn btn-primary float-end" type="submit" value="Save Customer" />
            </form>
        </div>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default AddCustomer;