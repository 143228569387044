import { Config } from "../../configs/Config";
import { OneCallBack, PostDatasetCallback, OneCallBackPage } from "../middleware/Middleware";

export const fetchCustomer = (id, _callback) => {
    const url = `${Config().API_URL}customer/show/${id}`;
    OneCallBack(url, _callback);
};

export const fetchCustomerByMobile = (id, id2, _callback) => {
    const url = `${Config().API_URL}customer/showbymobile/${id}/${id2}`;
    OneCallBack(url, _callback);
};

export const fetchCustomers = (id, _callback) => {
    const url = `${Config().API_URL}customer/get/${id}`;
    OneCallBack(url, _callback);
};

export const fetchCustomersPaginate = (id, items, _callback) => {
    const url = `${Config().API_URL}customer/getpage/${id}/${items}`;
    OneCallBack(url, _callback);
};

export const fetchCustomersSearch = (id, txt, _callback) => {
    const url = `${Config().API_URL}customer/search/${id}/${txt}`;
    OneCallBack(url, _callback);
};

export const fetchCustomersPaginatePage = (url, _callback) => {
    OneCallBackPage(url, _callback);
};

export const customerSave = (id, dataset, _callback) => {
    const url = `${Config().API_URL}customer/store/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};

export const customerUpdate = (id, dataset, _callback) => {
    const url = `${Config().API_URL}customer/update/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};