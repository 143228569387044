import { useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import Layout from "../Layout";
import { fetchCustomers, fetchCustomer } from "../../../api/customer/Customer";
import { fetchCityById } from "../../../api/city/City";
import { partDecrementStock, fetchStockParts } from "../../../api/parts/Parts";
import { partSelInvoiceSave } from "../../../api/partsellinvoice/PartSellInvoice";
import { partInvoicePartSave } from "../../../api/partsellinvoiceparts/PartSellInvoicePart";
import { useParams } from "react-router-dom";

function AddPartsSellInvoice() {
    const [loading_status, setLoadingStatus] = useState("hide");

    const [disable_price_update] = useState(false);
    const [payment_status, setPaymentStatus] = useState("pending");

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customer_data, setCustomersData] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [customers, setCustomers] = useState(null);

    const [selectedPart, setSelectedPart] = useState(null);
    const [part_list, setPartList] = useState([]);
    const [part_data, setPartData] = useState(null);

    const [customer_id, setCustomerId] = useState(0);
    const { branchid } = useParams();
    const branch_id = branchid;

    const [f_name, setFirstName] = useState("");
    const [l_name, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [mobile, setMobile] = useState("");
    const [city_name, setCityName] = useState("");

    const [added_parts, setAddedParts] = useState([]);

    const [disable_part_invoice, setDisable] = useState(false);

    const [invoice_note, setInvoiceNote] = useState("");
    const [part_total, setPartsTotal] = useState(0);

    const clearPage = () => {
        setSelectedCustomer(null);
        setAddedParts([]);
        setCustomers(null);
        fetchCustomers(branch_id, setCustomers);
        setDisable(true);
    };

    useEffect(function () {
        if (customers == null) {
            fetchCustomers(branch_id, setCustomers);
        }
    }, [customers]);

    useEffect(function () {
        if (customer !== null) {
            setFirstName(customer.f_name);
            setLastName(customer.l_name);
            setAddress(customer.address);
            setMobile(customer.mobile);
            fetchCityById(customer.city_id, "city_name", setCityName);
        }
    }, [customer]);

    useEffect(function () {
        if (part_data == null) {
            fetchStockParts(branch_id, setPartData);
        } else {
            const part_list = part_data.map(({ part_name, part_code, part_id, count }) => {
                return {
                    label: part_name + " " + part_code,
                    value: part_id,
                    quantity: 0,
                    max: count,
                    invoice_part_price: 0
                };
            });
            setPartList(part_list);
        }
    }, [part_data]);

    useEffect(function () {
        if (customers !== null) {
            const listItems = customers.map(({ f_name, l_name, mobile, customer_id }) => {
                return {
                    label: f_name + " " + l_name + " " + mobile,
                    value: customer_id
                };
            });
            setCustomersData(listItems);
        }
    }, [customers]);

    useEffect(function () {
        if (selectedCustomer !== null) {
            setCustomerId(selectedCustomer.value);
            fetchCustomer(selectedCustomer.value, setCustomer);
        }
    }, [selectedCustomer]);

    const setPartPrice = (key, price) => {
        if (isNaN(price)) {
            return false;
        }
        var array = [...added_parts];
        array[key].invoice_part_price = price;
        setAddedParts([...array]);
    };

    const addSelectedPart = () => {
        if (!added_parts.some(item => selectedPart.value === item.value)) {
            if (selectedPart.max === 0) {
                Swal.fire(
                    "Opps...!",
                    selectedPart.label + " Stock value is 0",
                    "error"
                );
            } else {
                const new_data = {
                    label: selectedPart.label,
                    value: selectedPart.value,
                    quantity: selectedPart.quantity,
                    max: selectedPart.max,
                    invoice_part_price: selectedPart.invoice_part_price
                };
                setAddedParts([...added_parts, new_data]);
            }

        }
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Part Added",
            showConfirmButton: false,
            timer: 1000
        });
    };

    useEffect(function () {
        if (added_parts.length !== 0) {
            var new_part_price = 0;
            added_parts.forEach(element => {
                new_part_price = new_part_price + (parseFloat(element.quantity) * parseFloat(element.invoice_part_price));
                setPartsTotal(new_part_price);
            });
        }

    }, [added_parts]);

    const handleRemoveItem = (e) => {
        var value = e.target.value;
        var array = [...added_parts];
        if (value !== -1) {
            array.splice(value, 1);
            setAddedParts(array);
        }
    };

    const setQty = (key, quantity) => {
        var array = [...added_parts];
        array[key].quantity = quantity;
        setAddedParts([...array]);
    };

    const saveReturn = (res) => {
        if (!isNaN(res)) {
            added_parts.forEach(element => {
                if (!isNaN(element.quantity)) {
                    partInvoicePartSave({ part_sell_invoice_id: res, invoice_part_price: element.invoice_part_price, part_id: element.value, quantity: element.quantity }, savePartsReturn);
                    partDecrementStock(element.value, { value: element.quantity });
                }
            });
            Swal.fire(
                "Good job!",
                "Job Saved..!",
                "success"
            );
            clearPage();
            setTimeout(function () {
                window.location.replace("/manager/branch/" + branch_id + "/partsellinvoice/list");
            }, 1000);
        }
        setLoadingStatus("hide");
        setDisable(false);
    };

    const savePartsReturn = () => {

    };

    const validateStock = (ele) => {
        if (parseInt(ele.max) < parseInt(ele.value)) {
            Swal.fire(
                "Out Of Stock",
                "You have only " + ele.max + " in your stock..!",
                "error"
            );
            setDisable(true);
        } else {
            setDisable(false);
        }
    };

    const validateDataset = (dataset) => {
        if (dataset.customer_id !== 0) {
            setDisable(true);
            setLoadingStatus("show");
            partSelInvoiceSave(dataset, saveReturn);
        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ customer_id: customer_id, branch_id: branch_id, total: part_total, payment_status: payment_status, invoice_note: invoice_note });
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/partsellinvoice/list", "text": "Part Invoice List" });
    const title = "Add Part Invoice";

    const Content = <div className="row">
        <form onSubmit={handleSubmit}>
            <div className="col-lg-12">
                <div className="card-style mb-30">
                    <div className="select-style-1">
                        <label>Customer</label>
                        <div className="select-position">
                            <Select
                                defaultValue={customer_id}
                                onChange={setSelectedCustomer}
                                options={customer_data}
                            />
                        </div>
                    </div>
                    <div className="select-style-1">
                        <label>Parts</label>
                        <div className="select-position">
                            <Select
                                defaultValue={0}
                                onChange={setSelectedPart}
                                options={part_list}
                            />
                            <button className="btn btn-success" type="button" onClick={() => addSelectedPart()}>+ Add Part To Invoice</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="invoice-card card-style mb-30">
                <div className="invoice-header">
                    <div className="invoice-for">
                        <h2 className="mb-10">Invoice Details</h2>
                    </div>
                </div>
                <div className="invoice-address">
                    <div className="address-item">
                        <h5 className="text-bold">Customer:</h5>
                        <h6>{f_name} {l_name}</h6>
                        <p className="text-sm">
                            {address} {city_name}
                        </p>
                        <p className="text-sm">
                            {mobile}
                        </p>
                    </div>
                    <div className="address-item">
                        <h5 className="text-bold">Payment Status:</h5>
                        <div className="select-position">
                            <select value={payment_status} onChange={e => setPaymentStatus(e.target.value)} >
                                <option value="pending">Pending</option>
                                <option value="payed">Payed</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="invoice-table table">
                        <thead>
                            <tr>
                                <th className="service">
                                    <h6 className="text-sm text-medium">Parts</h6>
                                </th>
                                <th className="service">
                                    <h6 className="text-sm text-medium">Qty</h6>
                                </th>
                                <th className="desc">
                                    <h6 className="text-sm text-medium">Price</h6>
                                </th>
                                <th className="desc">
                                    <h6 className="text-sm text-medium dis-price">Total</h6>
                                </th>
                                <th className="desc">
                                    <h6 className="text-sm text-medium">Actions</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {added_parts.map((object, key) => (
                                <tr key={key}>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <p>{object.label}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <input className="qty" value={object.quantity} onChange={e => { setQty(key, e.target.value); validateStock(e.target); }} type="number" name="quantity" min="1" max={object.max}></input>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <input className="price" disabled={(disable_price_update ? true : false)} value={object.invoice_part_price} onChange={e => { setPartPrice(key, e.target.value); }} type="text" name="price"></input>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width price  ">
                                        <div className="lead">
                                            <div className="lead-text dis-price">
                                                {(parseFloat(object.invoice_part_price) * parseFloat(object.quantity)).toFixed(2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="action">
                                            <button type="button" className="btn btn-danger float-end" value={key} onClick={e => handleRemoveItem(e, "value")}>Remove</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td></td>
                                <td></td>
                                <td>
                                    <h4>Total</h4>
                                </td>
                                <td className="min-width price" >
                                    <div className="lead">
                                        <div className="lead-text dis-price tot">
                                            {(part_total).toFixed(2)}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="input-style-1">
                        <label>Invoice Note</label>
                        <textarea value={invoice_note} disabled={(disable_price_update ? true : false)} onChange={e => { setInvoiceNote(e.target.value); }} placeholder="Type Invoice Note" name="invoice_note" rows="5"></textarea>
                    </div>
                </div>
            </div>
            <input disabled={disable_part_invoice} className="btn btn-primary float-end" type="submit" value="Create Invoice" />
        </form>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default AddPartsSellInvoice;