import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchJobByVehicleCustomerPaginate, fetchJobPaginatePage } from "../../../api/job/Job";
import Select from "react-select";
import Layout from "../Layout";
import { fetchCustomersSearch } from "../../../api/customer/Customer";
import { fetchVehiclesSearch } from "../../../api/vehicle/Vehicle";
import PropTypes from "prop-types";

function Jobs() {
    const title = "Job List";

    const [data, setData] = useState([]);
    const [loading_status, setLoadingStatus] = useState("hide");
    const { branchid } = useParams();
    const branch_id = branchid;

    const [filter_by_customer, setFilterByCustomer] = useState({ value: "all", label: "All Customers" });
    const [filter_by_vehicle, setFilterByVehicle] = useState({ value: "all", label: "All Vehicles" });

    const [customer_data, setCustomerData] = useState([]);
    const [customer_fetched_data, setCustomerFetchedData] = useState([]);
    const [vehicle_data, setVehicleData] = useState([]);
    const [vehicle_fetched_data, setVehicleFetchedData] = useState([]);

    const [items_per_page] = useState(10);
    const [links, setLinks] = useState([]);
    const [page_data, setPageData] = useState([]);

    useEffect(function () {
        if (data.length !== 0) {
            setLinks(data.links);
            setPageData(data.data);
        }
        setLoadingStatus("hide");
    }, [data]);

    useEffect(function () {
        setLoadingStatus("show");
        fetchJobByVehicleCustomerPaginate(branch_id, filter_by_vehicle.value, filter_by_customer.value, items_per_page, setData);
    }, [filter_by_customer, filter_by_vehicle, items_per_page, branch_id]);

    function EditJob(props) {

        const payment_status = props.invoice_payment_status;
        if (payment_status !== "payed") {
            return <div className="action">
                <Link to={`/manager/branch/${branch_id}/job/edit/${props.job_id}`} >Edit Job</Link>
            </div>;
        }
        return false;
    }

    function EditInvoice(props) {

        const job_status = props.job_status;
        if (job_status === "done") {
            return <div className="action">
                <Link to={`/manager/branch/${branch_id}/invoice/edit/${props.invoice_id}`} >Edit Invoice</Link>
            </div>;
        }
        return false;
    }

    const loadPage = (url) => {
        if (url !== null) {
            setLoadingStatus("show");
            fetchJobPaginatePage(url, setData);
        }

    };

    const searchCustomer = (txt) => {
        if (txt !== "") {
            fetchCustomersSearch(branch_id, txt, setCustomerFetchedData);
        }
    };

    const searchVehicles = (txt) => {
        if (txt !== "") {
            fetchVehiclesSearch(branch_id, txt, setVehicleFetchedData);
        }
    };

    useEffect(function () {
        var temp_customer_data = [];
        customer_fetched_data.forEach(element => {
            temp_customer_data[element.customer_id] = {
                label: element.f_name + " " + element.l_name + " " + element.mobile,
                value: element.customer_id
            };
        });
        var customer_filtered = temp_customer_data.filter(function (el) {
            return el != null;
        });
        customer_filtered.unshift({
            label: "All Customers",
            value: "all"
        });
        setCustomerData([...customer_filtered]);
        //setLoadingStatus("hide")
    }, [customer_fetched_data]);

    useEffect(function () {
        var temp_vehicle_data = [];
        vehicle_fetched_data.forEach(element => {
            temp_vehicle_data[element.vehicle_id] = {
                label: element.vehicle_number,
                value: element.vehicle_id
            };
        });
        var vehicle_filtered = temp_vehicle_data.filter(function (el) {
            return el != null;
        });
        vehicle_filtered.unshift({
            label: "All Vehicles",
            value: "all"
        });
        setVehicleData([...vehicle_filtered]);
        //setLoadingStatus("hide")
    }, [vehicle_fetched_data]);

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/job/add", "text": "Add Jobs" });

    const Content = <div className="row">
        <div className="col-lg-12">
            <div className="card-style mb-30">
                <div className="select-style-1">
                    <label>Filter By Customer</label>
                    <div className="select-position">
                        <Select
                            defaultValue={filter_by_customer}
                            onChange={setFilterByCustomer}
                            onInputChange={searchCustomer}
                            options={customer_data}
                        />
                    </div>
                </div>
                <div className="select-style-1">
                    <label>Filter By Vehicle</label>
                    <div className="select-position">
                        <Select
                            defaultValue={filter_by_vehicle}
                            onChange={setFilterByVehicle}
                            onInputChange={searchVehicles}
                            options={vehicle_data}
                        />
                    </div>
                </div>
            </div>
            <div className="card-style mb-30">
                <div className="table-wrapper table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th><h6>Job ID</h6></th>
                                <th><h6>Customer Name</h6></th>
                                <th><h6>Vehicle Number</h6></th>
                                <th><h6>Date</h6></th>
                                <th><h6>Status</h6></th>
                                <th><h6>Actions</h6></th>
                                <th><h6>Invoice Payment</h6></th>
                            </tr>
                        </thead>
                        <tbody>
                            {page_data.map((object) => (
                                <tr key={object.job_id} className={"job-" + object.job_status}>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <p>{object.job_id}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <p>{object.f_name} {object.l_name}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead-text">
                                            <p>{object.vehicle_number}</p>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead-text">
                                            <p>{object.date}</p>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead-text">
                                            <p>{object.job_status}</p>
                                        </div>
                                    </td>
                                    <td>

                                        <EditJob invoice_payment_status={object.payment_status} job_id={object.job_id} />
                                        <EditInvoice job_status={object.job_status} invoice_id={object.invoice_id} />
                                    </td>
                                    <td className={"min-width inv-" + object.payment_status}>
                                        <div className="lead-text">
                                            <p>{object.total} RS<br></br>{object.payment_status}</p>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <nav aria-label="...">
                <ul className="pagination">
                    {links.map((object) => (
                        <li key={object.label} className={`page-item ${(object.active ? "active" : "")}`}>
                            <div className="page-link" onClick={() => loadPage(object.url)}>{object.label}</div>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

Jobs.propTypes = {
    invoice_payment_status: PropTypes.any,
    job_id: PropTypes.any,
    job_status: PropTypes.any,
    invoice_id: PropTypes.any,
};

export default Jobs;