import configDataLive from "../environment/live.json";
import configDataDev from "../environment/dev.json";
import configDataLocal from "../environment/local.json";
import configDataClientLocal from "../environment/clientlocal.json";

export const Config = () => {
    const url = window.location.href;
    if(url.includes("localhost:8081")){
        return configDataClientLocal;
    }else if (url.includes("localhost")) {
        return configDataLocal;
    }else if (url.includes("dev.")) {
        return configDataDev;
    } else {
        return configDataLive;
    }
};

export default Config;