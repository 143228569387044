import PropTypes from "prop-types";
function PartInvoiceTotal(props) {
    return (
        <div className="col-xl-3 col-lg-4 col-sm-6">
            <div className="icon-card mb-30">
                <div className="icon success">
                    <i className="lni lni-dollar"></i>
                </div>
                <div className="content">
                    <h6 className="mb-10">Part Invoice Total</h6>   
                    <h3 className="text-bold mb-10">{props.part_income} RS</h3>
                    <p className="text-sm text-success">
                    </p>
                </div>
            </div>
        </div>
    );
}

PartInvoiceTotal.propTypes = {
    part_income: PropTypes.any,
};

export default PartInvoiceTotal;