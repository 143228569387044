import { Config } from "../../configs/Config";

export const loginTo = (dataset, _callback) => {
    const url = `${Config().API_URL}login`;
    var request = new XMLHttpRequest();
    request.addEventListener("load", () => {
        if (typeof (_callback) === "function") {
            _callback(request.response);
        }
    });
    request.open("POST", url);
    request.setRequestHeader("Content-Type", "application/json");
    request.send(JSON.stringify(dataset));
};

export const signIn = (dataset, _callback) => {
    const url = `${Config().API_URL}signin`;
    var request = new XMLHttpRequest();
    request.addEventListener("load", () => {
        if (typeof (_callback) === "function") {
            _callback(request.response);
        }
    });
    request.open("POST", url);
    request.setRequestHeader("Content-Type", "application/json");
    request.send(JSON.stringify(dataset));
};