import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from "chart.js";
import { Line } from "react-chartjs-2";
import { getInvoiceTotals, getPartInvoiceTotals } from "../../../../../api/reporting/Reports";
import { FilterYears } from "../../../../../components/filter/FilterYears";
import { FilterMonths } from "../../../../../components/filter/FilterMonths";
import Loading from "../../../../common/Loading";
import PropTypes from "prop-types";

export const InvoiceReport = (props) => {
    const [loading_status, setLoadingStatus] = useState("hide");

    const [filter_type, setFilterType] = useState("month");
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month_numbers = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const days_numbers = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];

    const d = new Date();
    const [this_month_number, setThisMonth] = useState(month_numbers[d.getMonth()]);
    const [this_year, setThisYear] = useState(d.getFullYear());
    let this_day = d.getDay();

    const branch_id = props.branch_id;
    const [invoice_totals, setInvoiceTotals] = useState([]);
    const [part_invoice_totals, setPartInvoiceTotals] = useState([]);
    const [invoice_totals_fetched] = useState(false);

    useEffect(function () {
        setLoadingStatus("show");
        getInvoiceTotals(branch_id, { year: this_year, month: this_month_number, day: this_day, filter: filter_type }, setInvoiceTotals);
        getPartInvoiceTotals(branch_id, { year: this_year, month: this_month_number, day: this_day, filter: filter_type }, setPartInvoiceTotals);
    }, [invoice_totals_fetched, this_month_number, this_year, filter_type, branch_id, this_day]);

    const [labels_list, setLabelsList] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);

    useEffect(function () {
        if (invoice_totals.length !== 0) {
            const new_data = invoice_totals.map(({ date, payment_status, total }) => {
                return {
                    date: (date.split(" ")[0]),
                    payment_status: payment_status,
                    total: total
                };
            });
            getJobInvoiceTot(new_data);
        } else {
            setData1([]);
            setData2([]);
        }
        setLoadingStatus("hide");
    }, [invoice_totals, filter_type]);

    useEffect(function () {
        if (part_invoice_totals.length !== 0) {
            const new_data = part_invoice_totals.map(({ date, payment_status, total }) => {
                return {
                    date: (date.split(" ")[0]),
                    payment_status: payment_status,
                    total: total
                };
            });
            getPartInvoiceTot(new_data);
        } else {
            setData3([]);
            setData4([]);
        }
        setLoadingStatus("hide");
    }, [part_invoice_totals, filter_type]);

    const getPartInvoiceTot = (arr_st) => {
        try {
            if (filter_type === "month") {
                setLabelsList(days_numbers);
                monthData(arr_st, setData3, setData4);
            } else if (filter_type === "year") {
                setLabelsList(month);
                yearData(arr_st, setData3, setData4);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getJobInvoiceTot = (arr_st) => {
        try {
            if (filter_type === "month") {
                setLabelsList(days_numbers);
                monthData(arr_st, setData1, setData2);
            } else if (filter_type === "year") {
                setLabelsList(month);
                yearData(arr_st, setData1, setData2);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const monthData = (arr_st, _callback1, _callback2) => {
        var arr1 = new Array(31).fill(0);
        var arr2 = new Array(31).fill(0);
        arr_st.forEach(function callback(value) {
            if (value.payment_status === "payed") {
                arr1[parseInt((value.date).substr((value.date).length - 2)) - 1] = arr1[parseInt((value.date).substr((value.date).length - 2)) - 1] + parseInt(value.total);
            } else {
                arr2[parseInt((value.date).substr((value.date).length - 2)) - 1] = arr2[parseInt((value.date).substr((value.date).length - 2)) - 1] + parseInt(value.total);
            }
        });

        _callback1(arr1);
        _callback2(arr2);
    };

    const yearData = (arr_st, _callback1, _callback2) => {
        var yarr1 = new Array(12).fill(0);
        var yarr2 = new Array(12).fill(0);

        arr_st.forEach(function callback(value) {
            if (value.payment_status === "payed") {
                yarr1[parseInt((value.date).split("-")[1]) - 1] = yarr1[parseInt((value.date).split("-")[1]) - 1] + parseInt(value.total);
            } else {
                yarr2[parseInt((value.date).split("-")[1]) - 1] = yarr2[parseInt((value.date).split("-")[1]) - 1] + parseInt(value.total);
            }
        });

        _callback1(yarr1);
        _callback2(yarr2);
    };

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

    const options = {
        responsive: true,
        aspectRatio: 3,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Invoice Total (Revenue)",
            },
        },
    };

    var labels = labels_list;

    const data = {
        labels,
        datasets: [{label: "Job Invoice Payed",data: data1,borderColor: "rgb(255, 99, 132)",backgroundColor: "rgba(255, 99, 132, 0.5)",},{label: "Job Invoice Pending",data: data2,borderColor: "rgb(53, 162, 235)",backgroundColor: "rgba(53, 162, 235, 0.5)",},{label: "Part Direct Sell Payed",data: data3,borderColor: "rgb(52, 200, 65)",backgroundColor: "rgb(52, 200, 65, 0.5)",},{label: "Part Direct Sell Pending",data: data4,borderColor: "rgb(255, 241, 41)",backgroundColor: "rgb(255, 241, 41, 0.5)",},],
    };

    return (
        <div className="report-section">
            <Loading loading={loading_status} />
            <div className="select-style-1">
                <label>Invoice Report/Revenue By</label>
                <select onChange={e => setFilterType(e.target.value)}>
                    <option value="month" >Month</option>
                    <option value="year" >Year</option>
                </select>
            </div>
            <div className="select-style-1">
                <select value={this_year} onChange={e => setThisYear(e.target.value)}>
                    <FilterYears />
                </select>
                <select className={`on-filter-${filter_type}`} value={this_month_number} onChange={e => setThisMonth(e.target.value)}>
                    <FilterMonths />
                </select>
            </div>
            <Line options={options} data={data} />
        </div>
    );
};

InvoiceReport.propTypes = {
    branch_id: PropTypes.any,
};