import { Config } from "../../configs/Config";
import { PostDatasetCallbackJson } from "../middleware/Middleware";

export const getInvoiceTotals = (id, dataset, _callback) => {
    const url = `${Config().API_URL}report/getinvoicetotals/${id}`;
    PostDatasetCallbackJson(url, dataset, _callback);
};

export const getPartInvoiceTotals = (id, dataset, _callback) => {
    const url = `${Config().API_URL}report/getpartsinvoicetotals/${id}`;
    PostDatasetCallbackJson(url, dataset, _callback);
};

export const getPartTotals = (id, dataset, _callback) => {
    const url = `${Config().API_URL}report/getparttotals/${id}`;
    PostDatasetCallbackJson(url, dataset, _callback);
};

export const getPartsSellPartTotals = (id, dataset, _callback) => {
    const url = `${Config().API_URL}report/getpartssellparttotals/${id}`;
    PostDatasetCallbackJson(url, dataset, _callback);
};

export const getServiceTotals = (id, dataset, _callback) => {
    const url = `${Config().API_URL}report/getservicetotals/${id}`;
    PostDatasetCallbackJson(url, dataset, _callback);
};