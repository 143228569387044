import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchCustomersPaginate, fetchCustomersPaginatePage } from "../../../api/customer/Customer";
import Layout from "../Layout";
import CustomerView from "./CustomerView";

function Customer() {
    const title = "Customer List";
    const [loading_status, setLoadingStatus] = useState("hide");
    const [data, setData] = useState([]);
    const [data_fetched, setDataFetched] = useState(false);
    const { branchid } = useParams();
    const branch_id = branchid;

    const [items_per_page, setItemsPerPage] = useState(10);
    const [links, setLinks] = useState([]);
    const [page_data, setPageData] = useState([]);



    useEffect(function () {
        setItemsPerPage(10);
        if (!data_fetched) {
            setLoadingStatus("show");
            fetchCustomersPaginate(branch_id, items_per_page, setData);
            setDataFetched(true);
        }
    }, [data_fetched]);

    useEffect(function () {
        if (data_fetched) {
            setLoadingStatus("hide");
            if (data.length !== 0) {
                setLinks(data.links);
                setPageData(data.data);
            }
        }
    }, [data]);

    const loadPage = (url) => {
        if (url !== null) {
            setLoadingStatus("show");
            fetchCustomersPaginatePage(url, setData);
        }

    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/customer/add", "text": "Add Customer" });

    const Content = <div className="row">
        <div className="col-lg-12">
            <div className="card-style mb-30">
                <div className="table-wrapper table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th><h6>Customer Name</h6></th>
                                <th><h6>Address</h6></th>
                                <th><h6>Mobile</h6></th>
                                <th><h6>Actions</h6></th>
                            </tr>
                        </thead>
                        <tbody>
                            {page_data.map((object) => (
                                <tr key={object.customer_id}>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <p>{object.f_name} {object.l_name}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead-text">
                                            <p>{object.address}</p>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead-text">
                                            <p>{object.mobile}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <CustomerView id={`customer_view_${object.customer_id}`} branch_id={branch_id} data={object}></CustomerView>
                                        <div className="action">
                                            <Link to={`/manager/branch/${branch_id}/customer/edit/${object.customer_id}`} >Edit</Link>
                                        </div>
                                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#customer_view_${object.customer_id}`}>
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <nav aria-label="...">
                <ul className="pagination">
                    {links.map((object) => (
                        <li key={object.label} className={`page-item ${(object.active ? "active" : "")}`}>
                            <div className="page-link" onClick={() => loadPage(object.url)}>{object.label}</div>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default Customer;