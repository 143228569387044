import { Config } from "../../configs/Config";
import { OneCallBack, OneCallBackNoKey, OneCallBackParam } from "../middleware/Middleware";

export const fetchCityById = (id, param, _callback) => {
    const url = `${Config().API_URL}city/show/${id}`;
    OneCallBackParam(url, param, _callback);
};

export const fetchCity = (_callback) => {
    const url = `${Config().API_URL}city/get`;
    OneCallBack(url, _callback);
};

export const fetchCityNoKey = (_callback) => {
    const url = `${Config().API_URL}city/getnk`;
    OneCallBackNoKey(url, _callback);
};