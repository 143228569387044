import React, { useEffect, useState } from "react";
import Select from "react-select";
import { fetchYearById } from "../../../api/year/Year";
import { fetchVehicle, fetchVehicles } from "../../../api/vehicle/Vehicle";
import { fetchCustomer, fetchCustomers } from "../../../api/customer/Customer";
import { fetchMakeByModel } from "../../../api/modelmake/ModelMake";
import { fetchCityById } from "../../../api/city/City";
import { jobSave } from "../../../api/job/Job";
import { jobPartSave } from "../../../api/jobpart/JobPart";
import { jobServiceSave } from "../../../api/jobservice/JobService";
import { fetchStockParts } from "../../../api/parts/Parts";
import { fetchActiveServiceList } from "../../../api/service/Service";
import { invoiceSave } from "../../../api/invoice/Invoice";
import Swal from "sweetalert2";
import Layout from "../Layout";
import { Link, useParams } from "react-router-dom";

function AddJob() {
    const title = "Add Job";

    const [loading_status, setLoadingStatus] = useState("hide");
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customer_data, setCustomersData] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [customers, setCustomers] = useState(null);

    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [vehicle_data, setVehicleData] = useState([]);
    const [vehicle_list, setVehicleList] = useState([]);
    const [vehicle, setVehicle] = useState(null);

    const [selectedService, setSelectedService] = useState(null);
    const [service_data, setServiceData] = useState(null);
    const [service_list, setServiceList] = useState([]);

    const [selectedPart, setSelectedPart] = useState(null);
    const [part_list, setPartList] = useState([]);
    const [part_data, setPartData] = useState(null);

    const [customer_id, setCustomerId] = useState(0);
    const [vehicle_id, setVehicleId] = useState(0);
    const [milage, setMilage] = useState(0);
    const status = "active";
    const { branchid } = useParams();
    const branch_id = branchid;
    const [set1, setSet1] = useState("black");

    const [f_name, setFirstName] = useState("");
    const [l_name, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [mobile, setMobile] = useState("");
    const [city_name, setCityName] = useState("");

    const [model_year, setModelYear] = useState(0);
    const [make_data, setMakeData] = useState([]);
    const [vehicle_number, setVehicleNumber] = useState("");

    const [disable_job, setDisable] = useState(false);

    const [row_counter, setRowCounter] = useState(0);
    const [parts_and_services, setPartsAndServices] = useState([]);

    const clearPage = () => {
        setSelectedCustomer(null);
        setPartsAndServices([]);
        setSelectedVehicle(null);
        setVehicleNumber("");
        setModelYear(0);
        setMakeData([]);
        setSet1("black");
        setMilage(0);
        setCustomers(null);
        setVehicleData([]);
        fetchCustomers(branch_id, setCustomers);
        fetchVehicles(branch_id, setVehicleData);
        setDisable(true);
    };

    useEffect(function () {
        if (customers == null) {
            fetchCustomers(branch_id, setCustomers);
        }
        if (vehicle_data.length === 0) {
            fetchVehicles(branch_id, setVehicleData);
        }
    }, [vehicle_data, customers, branch_id]);

    useEffect(function () {
        if (customer !== null) {
            setFirstName(customer.f_name);
            setLastName(customer.l_name);
            setAddress(customer.address);
            setMobile(customer.mobile);
            fetchCityById(customer.city_id, "city_name", setCityName);
        }
    }, [customer]);

    useEffect(function () {
        if (vehicle !== null) {
            fetchYearById(vehicle.model_year_id, "model_year", setModelYear);
            setVehicleNumber(vehicle.vehicle_number);
            fetchMakeByModel(vehicle.model_id, setMakeData);
        }
    }, [vehicle]);

    useEffect(function () {
        if (service_data == null) {
            fetchActiveServiceList(branch_id, setServiceData);
        } else {
            const part_list = service_data.map(({ service_name, service_list_id }) => {
                return {
                    label: service_name,
                    value: service_list_id
                };
            });
            setServiceList(part_list);
        }
    }, [service_data, branch_id]);

    useEffect(function () {
        if (part_data == null) {
            fetchStockParts(branch_id, setPartData);
        } else {
            const part_list = part_data.map(({ part_name, part_code, part_id, count }) => {
                return {
                    label: part_name + " " + part_code,
                    value: part_id,
                    quantity: 0,
                    max: count
                };
            });
            setPartList(part_list);
        }
    }, [part_data, branch_id]);

    useEffect(function () {
        if (customers !== null) {
            const listItems = customers.map(({ f_name, l_name, mobile, customer_id }) => {
                return {
                    label: f_name + " " + l_name + " " + mobile,
                    value: customer_id
                };
            });
            setCustomersData(listItems);
        }
    }, [customers]);

    useEffect(function () {
        if (vehicle_data !== null) {
            const vehicle_list = vehicle_data.map(({ vehicle_number, vehicle_id }) => {
                return {
                    label: vehicle_number,
                    value: vehicle_id
                };
            });
            setVehicleList(vehicle_list);
        }
    }, [vehicle_data]);

    useEffect(function () {
        if (vehicle_id !== 0 && customer_id !== 0) {
            setSet1("");
        } else {
            setSet1("black");
        }
    }, [vehicle_id, customer_id]);

    useEffect(function () {
        if (selectedCustomer !== null) {
            setCustomerId(selectedCustomer.value);
            fetchCustomer(selectedCustomer.value, setCustomer);
        }
    }, [selectedCustomer]);

    useEffect(function () {
        if (selectedVehicle !== null) {
            setVehicleId(selectedVehicle.value);
            fetchVehicle(selectedVehicle.value, setVehicle);
        }
    }, [selectedVehicle]);

    const addSelectedPart = () => {
        if (!parts_and_services.some(item => selectedPart.value === item.value)) {
            if (selectedPart.max === 0) {
                Swal.fire(
                    "Opps...!",
                    selectedPart.label + " Stock value is 0",
                    "error"
                );
            } else {
                const new_data = {
                    label: selectedPart.label,
                    value: selectedPart.value,
                    quantity: selectedPart.quantity,
                    max: selectedPart.max,
                    row: row_counter + 1,
                    type: "part"
                };
                setRowCounter(row_counter + 1);
                setPartsAndServices([...parts_and_services, new_data]);
            }

        }
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Part Added",
            showConfirmButton: false,
            timer: 1000
        });
    };

    const handleRemoveItem = (e) => {
        var value = e.target.value;
        var array = [...parts_and_services];
        if (value !== -1) {
            array.splice(value, 1);
            setPartsAndServices(array);
        }
    };

    const setQty = (key, quantity) => {
        var array = [...parts_and_services];
        array[key].quantity = quantity;
        setPartsAndServices([...array]);
    };

    const addSelectedService = () => {
        if (!parts_and_services.some(item => selectedService.value === item.value)) {
            const new_data = {
                label: selectedService.label,
                value: selectedService.value,
                quantity: 0,
                max: 0,
                row: row_counter + 1,
                type: "service"
            };
            setRowCounter(row_counter + 1);
            setPartsAndServices([...parts_and_services, new_data]);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Service Added",
                showConfirmButton: false,
                timer: 1000
            });
        }
    };

    const saveReturn = (res) => {
        if (!isNaN(res)) {
            invoiceSave({ job_id: res, total: 0, payment_status: "pending" });
            parts_and_services.forEach(element => {
                if (element.type === "part") {
                    if (!isNaN(element.quantity)) {
                        jobPartSave({ job_id: res, job_part_price: 0, job_part_service_charge: 0, job_part_status: "active", part_id: element.value, quantity: element.quantity, row: element.row }, savePartsReturn);
                        //partDecrementStock(element.value, { value: element.quantity });
                    }
                } else if (element.type === "service") {
                    jobServiceSave({ job_id: res, job_service_price: 0, job_service_status: "active", service_list_id: element.value, row: element.row }, savePartsReturn);
                }
            });
            Swal.fire(
                "Good job!",
                "Job Saved..!",
                "success"
            );
            clearPage();
            // setTimeout(function () {
            //     window.location.replace("/branch/job/list");
            // }, 1000);
        }
        setLoadingStatus("hide");
    };
    
    const savePartsReturn = () => {

    };

    const validateStock = (ele) => {
        if (parseInt(ele.max) < parseInt(ele.value)) {
            Swal.fire(
                "Out Of Stock",
                "You have only " + ele.max + " in your stock..!",
                "error"
            );
            setDisable(true);
        } else {
            setDisable(false);
        }
    };

    const validateDataset = (dataset) => {
        if (dataset.customer_id !== 0 && dataset.vehicle_id !== 0 && dataset.milage !== "" && !isNaN(dataset.milage)) {
            setDisable(true);
            setLoadingStatus("show");
            jobSave(dataset, saveReturn);
        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ customer_id: customer_id, vehicle_id: vehicle_id, milage: milage, job_status: status, branch_id: branch_id });
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/job/list", "text": "Job List" });

    const Content = <div className="row">
        <form onSubmit={handleSubmit}>
            <div className="col-lg-12">

                <div className="card-style mb-30">
                    <div className="select-style-1">
                        <label>Customer</label>
                        <div className="select-position">
                            <Select
                                defaultValue={customer_id}
                                onChange={setSelectedCustomer}
                                options={customer_data}
                            />
                            <Link className="float-end" to={"/manager/branch/" + branch_id + "/customer/add"}>
                                <button className="btn btn-outline-secondary float-end" type="button">Add New Customer</button>
                            </Link>
                        </div>
                    </div>
                    <div className="select-style-1">
                        <label>Vehicle</label>
                        <div className="select-position">
                            <Select
                                defaultValue={vehicle_id}
                                onChange={setSelectedVehicle}
                                options={vehicle_list}
                            />
                            <Link className="float-end" to={"/manager/branch/" + branch_id + "/vehicle/add"}>
                                <button className="btn btn-outline-secondary float-end" type="button">Add New Vehicle</button>
                            </Link>
                        </div>
                    </div>
                    <div className="input-style-1">
                        <label>Milage</label>
                        <input value={milage} onChange={e => setMilage(e.target.value)} name="milage" type="text" placeholder="Milage" />
                    </div>
                    <div className="select-style-1">
                        <label>Parts</label>
                        <div className="select-position">
                            <Select
                                defaultValue={0}
                                onChange={setSelectedPart}
                                options={part_list}
                            />
                            <button className="btn btn-success" type="button" onClick={() => addSelectedPart()}>+ Add Part To Job</button>
                        </div>
                    </div>
                    <div className="select-style-1">
                        <label>Services</label>
                        <div className="select-position">
                            <Select
                                defaultValue={0}
                                onChange={setSelectedService}
                                options={service_list}
                            />
                            <button className="btn btn-success" type="button" onClick={() => addSelectedService()}>+ Add Service To Job</button>
                        </div>
                    </div>
                </div>

            </div>

            <div className="invoice-card card-style mb-30">
                <div className={set1}></div>
                <div className="invoice-header">
                    <div className="invoice-for">
                        <h2 className="mb-10">Job Details</h2>
                    </div>
                </div>
                <div className="invoice-address">
                    <div className="address-item">
                        <h5 className="text-bold">Customer:</h5>
                        <h6>{f_name} {l_name}</h6>
                        <p className="text-sm">
                            {address} {city_name}
                        </p>
                        <p className="text-sm">
                            {mobile}
                        </p>
                    </div>
                    <div className="address-item">
                        <h5 className="text-bold">Vehicle:</h5>
                        <h6>{vehicle_number}</h6>
                        <p className="text-sm">
                            {make_data.make_name} {make_data.model_name} {model_year}<br></br>Milage : {milage} KM
                        </p>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="invoice-table table">
                        <thead>
                            <tr>
                                <th className="service">
                                    <h6 className="text-sm text-medium">Service / Parts</h6>
                                </th>
                                <th className="service">
                                    <h6 className="text-sm text-medium">Qty</h6>
                                </th>
                                <th className="desc">
                                    <h6 className="text-sm text-medium">Actions</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {parts_and_services.map((object, key) => (
                                object.type === "part"
                                    ?
                                    <tr key={key}>
                                        <td className="min-width">
                                            <div className="lead">
                                                <div className="lead-text">
                                                    <p>{object.label}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="min-width">
                                            <div className="lead">
                                                <div className="lead-text">
                                                    <input className="qty" value={object.quantity} onChange={e => { setQty(key, e.target.value); validateStock(e.target); }} type="number" name="quantity" min="1" max={object.max}></input>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                <button type="button" className="btn btn-danger float-end" value={key} onClick={e => handleRemoveItem(e, "value")}>Remove</button>
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    <tr key={key}>
                                        <td className="min-width">
                                            <div className="lead">
                                                <div className="lead-text">
                                                    <p>{object.label}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="min-width">
                                            <div className="lead">
                                                <div className="lead-text">
                                                    <p></p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                <button type="button" className="btn btn-danger float-end" value={key} onClick={e => handleRemoveItem(e, "value")}>Remove</button>
                                            </div>
                                        </td>
                                    </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <input disabled={disable_job} className="btn btn-primary float-end" type="submit" value="Create Job" />
        </form >
    </div >;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default AddJob;