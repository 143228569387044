import { Config } from "../../configs/Config";
import { OneCallBack, PostDatasetCallback } from "../middleware/Middleware";

export const fetchMakeByModel = (id, _callback) => {
    const url = `${Config().API_URL}model/showmakebymodel/${id}`;
    OneCallBack(url, _callback);
};

export const fetchModelsByMake = (id, _callback) => {
    const url = `${Config().API_URL}model/showmodelbymake/${id}`;
    OneCallBack(url, _callback);
};

export const fetchMakes = (_callback) => {
    const url = `${Config().API_URL}make/getmakes`;
    OneCallBack(url, _callback);
};

export const modelSave = (dataset, _callback) => {
    const url = `${Config().API_URL}model/store`;
    PostDatasetCallback(url, dataset, _callback);
};