import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../assets/css/fullcalendar.css";
import "../assets/css/lineicons.css";
import "../assets/css/main.css";
import "../assets/css/custom.css";
import "../assets/scss/custom.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Dashboard from "../screens/manager/dashboard/Dashboard";
import Login from "../screens/account/Login";
import Signup from "../screens/account/Signup";
import Parts from "../screens/manager/parts/Parts";
import AddParts from "../screens/manager/parts/AddParts";
import EditParts from "../screens/manager/parts/EditParts";
import AddCustomer from "../screens/manager/customer/AddCustomer";
import EditCustomers from "../screens/manager/customer/EditCustomers";
import Customer from "../screens/manager/customer/Customer";
import Vehicle from "../screens/manager/vehicle/Vehicle";
import AddVehicle from "../screens/manager/vehicle/AddVehicle";
import EditVehicle from "../screens/manager/vehicle/EditVehicle";
import ModelAdd from "../screens/manager/model/ModelAdd";
import Service from "../screens/manager/service/Service";
import EditService from "../screens/manager/service/EditService";
import AddJob from "../screens/manager/job/AddJob";
import Jobs from "../screens/manager/job/Jobs";
import EditJob from "../screens/manager/job/EditJob";
import EditInvoive from "../screens/manager/invoice/EditInvoice";
import BranchDashboard from "../screens/manager/dashboard/BranchDashboard";
import InvoiceReportPage from "../screens/manager/reports/InvoiceReportPage";
import PartsReportPage from "../screens/manager/reports/PartsReportPage";
import ServicesReportPage from "../screens/manager/reports/ServicesReportPage";
import AddPartsSellInvoice from "../screens/manager/partssell/AddPartsSellInvoice";
import PartsSellInvoice from "../screens/manager/partssell/PartsSellInvoice";
import EditPartsSellInvoice from "../screens/manager/partssell/EditPartsSellInvoice";


function MainRoutes() {
    return (
        <Routes>
            <Route path="/manager" element={<Dashboard />} />
            <Route path="/manager/branch/:branchid" element={<BranchDashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />

            <Route path="/manager/branch/:branchid/parts/list" element={<Parts />} />
            <Route path="/manager/branch/:branchid/parts/add" element={<AddParts />} />
            <Route path="/manager/branch/:branchid/parts/edit/:id" element={<EditParts />} />

            <Route path="/manager/branch/:branchid/partsellinvoice/add" element={<AddPartsSellInvoice />} />
            <Route path="/manager/branch/:branchid/partsellinvoice/list" element={<PartsSellInvoice />} />
            <Route path="/manager/branch/:branchid/partsellinvoice/edit/:id" element={<EditPartsSellInvoice />} />

            <Route path="/manager/branch/:branchid/customer/list" element={<Customer />} />
            <Route path="/manager/branch/:branchid/customer/add" element={<AddCustomer />} />
            <Route path="/manager/branch/:branchid/customer/edit/:id" element={<EditCustomers />} />

            <Route path="/manager/branch/:branchid/vehicle/list" element={<Vehicle />} />
            <Route path="/manager/branch/:branchid/vehicle/add" element={<AddVehicle />} />
            <Route path="/manager/branch/:branchid/vehicle/edit/:id" element={<EditVehicle />} />

            <Route path="/manager/branch/:branchid/model/add/" element={<ModelAdd />} />

            <Route path="/manager/branch/:branchid/service/list" element={<Service />} />
            <Route path="/manager/branch/:branchid/service/edit/:id" element={<EditService />} />

            <Route path="/manager/branch/:branchid/job/list" element={<Jobs />} />
            <Route path="/manager/branch/:branchid/job/add" element={<AddJob />} />
            <Route path="/manager/branch/:branchid/job/edit/:id" element={<EditJob />} />
            <Route path="/manager/branch/:branchid/invoice/edit/:id" element={<EditInvoive />} />

            <Route path="/manager/branch/:branchid/reports/invoice" element={<InvoiceReportPage />} />
            <Route path="/manager/branch/:branchid/reports/parts" element={<PartsReportPage />} />
            <Route path="/manager/branch/:branchid/reports/services" element={<ServicesReportPage />} />
        </Routes>
    );
}

export default MainRoutes;