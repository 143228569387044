import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchCity } from "../../../api/city/City";
import { customerUpdate, fetchCustomer } from "../../../api/customer/Customer";
import Swal from "sweetalert2";
import Layout from "../Layout";

function EditCustomers() {
    const title = "Edit Customer";

    const [loading_status, setLoadingStatus] = useState("hide");
    const [f_name, setFirstName] = useState("");
    const [l_name, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [mobile, setMobile] = useState("");
    const [city_id, setCityId] = useState("");
    const [city, setCity] = useState([]);
    const [customer, setCustomer] = useState(null);
    const { branchid } = useParams();
    const branch_id = branchid;

    const { id } = useParams();

    useEffect(function () {
        setLoadingStatus("show");
        fetchCity(setCity);
        fetchCustomer(id, setCustomer);
    }, [id]);

    useEffect(function () {
        if (customer != null) {
            setLoadingStatus("hide");
            setFirstName(customer.f_name);
            setLastName(customer.l_name);
            setAddress(customer.address);
            setMobile(customer.mobile);
            setCityId(customer.city_id);
        }
    }, [customer]);

    const updateReturn = (res) => {
        if (res == 1) {
            Swal.fire(
                "Good job!",
                "Customer Updated..!",
                "success"
            );
        }
        setLoadingStatus("hide");

    };

    const validateDataset = (dataset) => {
        if (dataset.f_name !== "" && dataset.l_name !== "" && dataset.address !== "" && dataset.mobile !== "" && city_id !== 0 && !isNaN(dataset.mobile)) {
            if ((dataset.mobile).length === 10) {
                setLoadingStatus("show");
                customerUpdate(id, dataset, updateReturn);
            } else {
                Swal.fire(
                    "Error Mobile Number...!",
                    "Need to start with 0 and number count should be 10..!",
                    "error"
                );
            }
        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ f_name: f_name, l_name: l_name, address: address, mobile: mobile, city_id: city_id });
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/customer/list", "text": "Customer List" });

    const Content = <div className="row">
        <div className="col-lg-12">
            <form onSubmit={handleSubmit}>
                <div className="card-style mb-30">
                    <div className="input-style-1">
                        <label>First Name</label>
                        <input value={f_name} onChange={e => setFirstName(e.target.value)} name='f_name' type="text" placeholder="First Name" />
                    </div>
                    <div className="input-style-1">
                        <label>Last Name</label>
                        <input value={l_name} onChange={e => setLastName(e.target.value)} name='l_name' type="text" placeholder="Last Name" />
                    </div>
                    <div className="input-style-2">
                        <label>Address</label>
                        <input value={address} onChange={e => setAddress(e.target.value)} name='address' type="text" placeholder="Address" />
                    </div>
                    <div className="input-style-1">
                        <label>Mobile</label>
                        <input value={mobile} disabled={true} onChange={e => setMobile(e.target.value)} name='mobile' type="text" placeholder="Mobile" />
                    </div>
                    <div className="select-style-1">
                        <label>City</label>
                        <div className="select-position">
                            <select value={city_id} onChange={e => setCityId(e.target.value)} >
                                {city.map((object) => (
                                    <option key={object.city_id} value={object.city_id}>{object.city_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="alert-box secondary-alert pl-100">
                        <div className="left">
                            <h5 className="text-bold">Notice</h5>
                        </div>
                        <div className="alert">
                            <h4 className="alert-heading">Customer mobile</h4>
                            <p className="text-medium">
                                For security reasons Customer mobile number is not editable/ Please add new customer if mobile number is wrong
                            </p>
                        </div>
                    </div>
                </div>
                <input className="btn btn-primary float-end" type="submit" value="Update Customer" />
            </form>
        </div>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );

}

export default EditCustomers;