import Footer from "../common/Footer";
import Loading from "../common/Loading";
import MainHeader from "../common/MainHeader";
import Title from "../common/Title";
import PropTypes from "prop-types";

function MainLayout(props) {
    return (
        <div className="content">
            <Loading loading={props.loading} />
            <div className="overlay"></div>
            <main className="col-lg-12 col-md-12 col-12">
                <MainHeader quickButton={props.quickButton} />
                <section className="section">
                    <div className="container-fluid">
                        <Title title={props.title} />
                        {props.component}
                    </div>
                </section>
                <Footer />
            </main>
        </div>
    );
}

MainLayout.propTypes = {
    loading: PropTypes.any,
    quickButton: PropTypes.any,
    title: PropTypes.any,
    component: PropTypes.any,
};

export default MainLayout;