import PropTypes from "prop-types";
function Title(props) {
    return (
        <div className="title-wrapper pt-30">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="title mb-30">
                        <h2>{props.title}</h2>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="breadcrumb-wrapper mb-30">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active">
                                    {props.title}
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

Title.propTypes = {
    title: PropTypes.any,
};

export default Title;