import { Config } from "../../configs/Config";
import { OneCallBack, PostDatasetCallback } from "../middleware/Middleware";

export const fetchPartInvoiceAddedParts = (id, _callback) => {
    const url = `${Config().API_URL}partsellinvoiceparts/get/${id}`;
    OneCallBack(url, _callback);
};

export const deletePartInvoiceAddedParts = (id, _callback) => {
    const url = `${Config().API_URL}partsellinvoiceparts/destroylist/${id}`;
    OneCallBack(url, _callback);
};

export const partInvoicePartSave = (dataset, _callback) => {
    const url = `${Config().API_URL}partsellinvoiceparts/store`;
    PostDatasetCallback(url, dataset, _callback);
};

export const partInvoicePartUpdate = (id, id2, dataset, _callback) => {
    const url = `${Config().API_URL}partsellinvoiceparts/update/${id}/${id2}`;
    PostDatasetCallback(url, dataset, _callback);
};
