import Footer from "../common/Footer";
import Header from "../common/Header";
import Loading from "../common/Loading";
import Sidemenu from "./common/Sidemenu";
import Title from "../common/Title";
import PropTypes from "prop-types";

function Layout(props) {
    if (props.branch_id != null) {
        return (
            <div className="content">
                <Loading loading={props.loading} />
                <Sidemenu branch_id={props.branch_id} />
                <div className="overlay"></div>
                <main className="main-wrapper">
                    <Header quickButton={props.quickButton} />
                    <section className="section">
                        <div className="container-fluid">
                            <Title title={props.title} />
                            {props.component}
                        </div>
                    </section>
                    <Footer />
                </main>
            </div>
        );
    } else {
        return <div></div>;
    }

}

Layout.propTypes = {
    loading: PropTypes.any,
    branch_id: PropTypes.any,
    quickButton: PropTypes.any,
    title: PropTypes.any,
    component: PropTypes.any,
};

export default Layout;