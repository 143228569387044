import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getServiceTotals } from "../../../../../api/reporting/Reports";
import { FilterYears } from "../../../../../components/filter/FilterYears";
import { FilterMonths } from "../../../../../components/filter/FilterMonths";
import Loading from "../../../../common/Loading";
import PropTypes from "prop-types";

export const ServiceReport = (props) => {
    const [loading_status, setLoadingStatus] = useState("hide");

    const [filter_type, setFilterType] = useState("month");
    // const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    // const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month_numbers = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    // const days_numbers = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];

    const d = new Date();
    const [this_month_number, setThisMonth] = useState(month_numbers[d.getMonth()]);
    const [this_year, setThisYear] = useState(d.getFullYear());
    let this_day = d.getDay();

    const [labels_list, setLabelsList] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);

    const branch_id = props.branch_id;
    const [invoice_services, setInvoiceServices] = useState([]);
    const [invoice_services_fetched, setInvoiceServicesFetched] = useState(false);

    useEffect(function () {
        if (!invoice_services_fetched) {
            setLoadingStatus("show");
            getServiceTotals(branch_id, { filter: filter_type, year: this_year, month: this_month_number, day: this_day }, setInvoiceServices);
            setInvoiceServicesFetched(true);
        }
    }, [invoice_services]);

    useEffect(function () {
        setLoadingStatus("show");
        getServiceTotals(branch_id, { filter: filter_type, year: this_year, month: this_month_number, day: this_day }, setInvoiceServices);
        setInvoiceServicesFetched(true);

    }, [this_year, this_month_number, filter_type]);

    useEffect(function () {
        if (invoice_services.length !== 0) {
            const new_data = invoice_services.map(({ date, payment_status, job_service_price, service_list_id, service_name }) => {
                return {
                    date: (date.split(" ")[0]),
                    payment_status: payment_status,
                    job_service_price: job_service_price,
                    service_list_id: service_list_id,
                    service_name: service_name,
                    sales: (parseFloat(job_service_price))
                };
            });
            getReportData(new_data);
        } else {
            setData1([]);
            setData2([]);
        }
        setLoadingStatus("hide");
    }, [invoice_services]);

    const getReportData = (arr_st) => {
        var temp_lb = [];
        var temp_lb2 = [];
        arr_st.forEach(function callback(value) {
            if (value.payment_status === "payed") {
                if (temp_lb[value.service_name] === undefined) {
                    temp_lb[value.service_name] = 1;
                    temp_lb2[value.service_name] = value.sales;
                } else {
                    temp_lb[value.service_name] = temp_lb[value.service_name] + 1;
                    temp_lb2[value.service_name] = temp_lb2[value.service_name] + value.sales;
                }
            }
        });
        var lb = [];
        var dt1 = [];
        var dt2 = [];
        for (var key in temp_lb) {
            lb.push(key);
            dt1.push(temp_lb[key]);
            dt2.push(temp_lb2[key]);
        }
        setLabelsList(lb);
        setData1(dt1);
        setData2(dt2);

    };

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options1 = {
        indexAxis: "y",
        aspectRatio: 1 / 2,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: "right"
            },
            title: {
                display: true,
                text: "Services Sold Count",
            },
        },
    };
    const options2 = {
        indexAxis: "y",
        aspectRatio: 1 / 2,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: "right"
            },
            title: {
                display: true,
                text: "Services Sales",
            },
        },
    };

    const labels = labels_list;

    const data_set1 = {
        labels,
        datasets: [
            {
                label: "Count",
                data: data1,
                borderColor: "rgb(211 206 79)",
                backgroundColor: "rgb(211 206 79 / 50%)",
            }
        ],
    };
    const data_set2 = {
        labels,
        datasets: [
            {
                label: "Sales",
                data: data2,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    };

    return (
        <div className="report-section">
            <Loading loading={loading_status} />
            <div className="select-style-1">
                <label>Services Reports Filter By</label>
                <select onChange={e => setFilterType(e.target.value)}>
                    <option value="month" >Month</option>
                    <option value="year" >Year</option>
                    <option value="all" >All Time</option>
                </select>
            </div>
            <div className={`on-main-filter-${filter_type} select-style-1`}>
                <select value={this_year} onChange={e => setThisYear(e.target.value)}>
                    <FilterYears />
                </select>
                <select className={`on-filter-${filter_type}`} value={this_month_number} onChange={e => setThisMonth(e.target.value)}>
                    <FilterMonths />
                </select>
            </div>
            <div className="row">
                <div className="col">
                    <Bar options={options1} data={data_set1} />
                </div>
                <div className="col">
                    <Bar options={options2} data={data_set2} />
                </div>
            </div>
        </div>
    );
};

ServiceReport.propTypes = {
    branch_id: PropTypes.any,
};