import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { fetchInvoice, fetchOneInvoice, invoiceUpdate, invoiceUpdatePayment } from "../../../api/invoice/Invoice";
import { fetchAddedParts, jobPartUpdate } from "../../../api/jobpart/JobPart";
import { fetchAddedServices, jobServiceUpdate } from "../../../api/jobservice/JobService";
import { useReactToPrint } from "react-to-print";
import PrintInvoice from "./PrintInvoice";
import Swal from "sweetalert2";
import Layout from "../Layout";

function EditInvoive() {
    const title = "Edit Invoice";
    const [loading_status, setLoadingStatus] = useState("hide");
    const { branchid } = useParams();
    const branch_id = branchid;

    const [Logo] = useState("");//window.sessionStorage.getItem("shop_logo");
    const [payment_status, setPaymentStatus] = useState("");
    const [disable_price_update, setDisablePriceUpdate] = useState(false);

    const [fetched_added_parts, setFetchedAddedParts] = useState(null);
    const [fetched_added_services, setFetchedAddedServices] = useState(null);

    const [f_name, setFname] = useState("");
    const [l_name, setLname] = useState("");
    const [address, setAddress] = useState("");
    const [city_name, setCityName] = useState("");
    const [mobile, setMobile] = useState("");
    const [vehicle_number, setvehicleNumber] = useState("");
    const [make_name, setMakename] = useState("");
    const [model_name, setModelName] = useState("");
    const [model_year, setModelYear] = useState("");
    const [milage, setMilage] = useState("");
    const [invoice_note, setInvoiceNote] = useState("");

    const [part_total, setPartsTotal] = useState(0);
    const [service_total, setServiceTotal] = useState(0);

    const [print_enable, setPrintEnable] = useState(true);
    const [invoice, setInvoice] = useState("");
    const [invoice_date, setInvoiceDate] = useState("");
    const [branch_data] = useState([]);//JSON.parse(window.sessionStorage.getItem("branch_data"))[0];
    const [parts_services_updated, setPartServicesUpdated] = useState(false);
    const [parts_and_services, setPartsAndServices] = useState([]);

    const { id } = useParams();

    const [job, setJob] = useState([]);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const print = () => {
        if (!print_enable) {
            Swal.fire(
                "Opps...!",
                "Click Update Invoice Before Print",
                "error"
            );
        } else {
            handlePrint();
        }
    };
    useEffect(function () {
        if (invoice !== "") {
            var arr = invoice.date.split(" ");
            setInvoiceDate(arr[0]);
            setLoadingStatus("hide");
        }
    }, [invoice]);

    useEffect(function () {
        if (job.payment_status === payment_status) {
            if (!parts_services_updated) {
                setPrintEnable(true);
            }

        } else {
            setPrintEnable(false);
        }
    }, [payment_status]);

    useEffect(function () {
        if (job.invoice_note === invoice_note) {
            setPrintEnable(true);
        } else {
            setPrintEnable(false);
        }
    }, [invoice_note]);

    useEffect(function () {
        setLoadingStatus("show");
        fetchInvoice(id, setJob);
        fetchOneInvoice(id, setInvoice);
    }, [id]);


    useEffect(function () {
        if (job.length !== 0) {
            fetchAddedParts(job.job_id, setFetchedAddedParts);
            fetchAddedServices(job.job_id, setFetchedAddedServices);
            setFname(job.f_name);
            setLname(job.l_name);
            setAddress(job.address);
            setCityName(job.city_name);
            setMobile(job.mobile);
            setvehicleNumber(job.vehicle_number);
            setMakename(job.make_name);
            setModelName(job.model_name);
            setModelYear(job.model_year);
            setMilage(job.milage);
            setPaymentStatus(job.payment_status);
            setInvoiceNote(job.invoice_note);
            if (job.payment_status === "payed") {
                setPrintEnable(true);
            }

            if (job.payment_status === "payed") {
                setDisablePriceUpdate(true);
            } else {
                setDisablePriceUpdate(false);
            }
        }
    }, [job, id]);

    useEffect(function () {
        if (fetched_added_parts != null && fetched_added_services != null) {
            var arr = [];
            var counter = 0;
            fetched_added_parts.forEach(element => {
                if (element.row === 0) {
                    arr[counter] = {
                        label: element.part_name + " " + element.part_code,
                        value: element.part_id,
                        quantity: element.quantity,
                        job_part_price: element.job_part_price,
                        row: counter + 1,
                        type: "part"
                    };

                } else {
                    arr[element.row - 1] = {
                        label: element.part_name + " " + element.part_code,
                        value: element.part_id,
                        quantity: element.quantity,
                        job_part_price: element.job_part_price,
                        row: element.row,
                        type: "part"
                    };
                }
                counter++;
            });

            fetched_added_services.forEach(element => {
                if (element.row === 0) {
                    arr[counter] = {
                        label: element.service_name,
                        value: element.service_list_id,
                        job_service_price: element.job_service_price,
                        row: counter + 1,
                        type: "service",
                    };
                } else {
                    arr[element.row - 1] = {
                        label: element.service_name,
                        value: element.service_list_id,
                        job_service_price: element.job_service_price,
                        row: element.row,
                        type: "service",
                    };
                }
                counter++;
            });
            var filtered = arr.filter(function (el) {
                return el != null;
            });
            setPartsAndServices([...filtered]);

        }
    }, [fetched_added_parts, fetched_added_services]);

    useEffect(function () {
        if (parts_and_services.length !== 0) {
            var new_part_price = 0;
            parts_and_services.forEach(element => {
                if (element.type === "part") {
                    new_part_price = new_part_price + (parseFloat(element.quantity) * parseFloat(element.job_part_price));
                    setPartsTotal(new_part_price);
                }

            });
        }
        if (parts_and_services.length !== 0) {
            var new_service_price = 0;
            parts_and_services.forEach(element => {
                if (element.type === "service") {
                    new_service_price = new_service_price + parseFloat(element.job_service_price);
                    setServiceTotal(new_service_price);
                }
            });
        }

    }, [parts_and_services]);

    const setPartPrice = (key, price) => {
        if (isNaN(price)) {
            return false;
        }
        var array = [...parts_and_services];
        array[key].job_part_price = price;
        setPartsAndServices([...array]);
        setPrintEnable(false);
        setPartServicesUpdated(true);
    };
    const setServicePrice = (key, price) => {
        if (isNaN(price)) {
            return false;
        }
        var array = [...parts_and_services];
        array[key].job_service_price = price;
        setPartsAndServices([...array]);
        setPrintEnable(false);
        setPartServicesUpdated(true);
    };

    const updateReturn = (res) => {
        if (res === 1) {

            parts_and_services.forEach(element => {
                if (element.type === "part") {
                    jobPartUpdate(job.job_id, element.value, { job_part_price: element.job_part_price, job_part_service_charge: 0, job_part_status: "active", quantity: element.quantity, row: element.row });
                } else if (element.type === "service") {
                    jobServiceUpdate(job.job_id, element.value, { job_service_price: element.job_service_price, job_service_status: "active", row: element.row });
                }
            });
            fetchOneInvoice(id, setInvoice);
            Swal.fire(
                "Good job!",
                "Invoice Updated..!",
                "success"
            );
            job.payment_status = payment_status;
            if (payment_status === "payed") {
                setDisablePriceUpdate(true);
            } else {
                setDisablePriceUpdate(false);
            }
            setPrintEnable(true);
            setPartServicesUpdated(false);
        }
        setLoadingStatus("hide");
    };
    const validateDataset = (dataset) => {
        if (job.payment_status === "payed") {
            invoiceUpdatePayment(id, dataset, updateReturn);
        } else {
            invoiceUpdate(id, dataset, updateReturn);
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoadingStatus("show");
        validateDataset({ total: (part_total + service_total), payment_status: payment_status, invoice_note: invoice_note });
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/job/list", "text": "Job List" });

    const Content = <div className="row">
        <div id="invoice" className="col-lg-12">
            <form onSubmit={handleSubmit}>
                <div className="invoice-card card-style mb-30">
                    <div className="invoice-header">
                        <div className="invoice-for logo">
                            <img src={Logo} alt="logo" />
                        </div>
                    </div>
                    <div className="invoice-address">
                        <div className="address-item">
                            <h5 className="text-bold">Customer:</h5>
                            <h6>{f_name} {l_name}</h6>
                            <p className="text-sm">
                                {address} {city_name}
                            </p>
                            <p className="text-sm">
                                {mobile}
                            </p>
                        </div>
                        <div className="address-item">
                            <h5 className="text-bold">Vehicle:</h5>
                            <h6>{vehicle_number}</h6>
                            <p className="text-sm">
                                {make_name} {model_name} {model_year}<br></br>Milage : {milage} KM<br></br>
                                Date : {invoice_date}
                            </p>
                        </div>
                        <div className="address-item">
                            <h5 className="text-bold">Payment Status:</h5>
                            <div className="select-position">
                                <select value={payment_status} onChange={e => setPaymentStatus(e.target.value)} >
                                    <option value="pending">Pending</option>
                                    <option value="payed">Payed</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="invoice-table table">
                            <thead>
                                <tr>
                                    <th className="service">
                                        <h6 className="text-sm text-medium">Service / Parts</h6>
                                    </th>
                                    <th className="service">
                                        <h6 className="text-sm text-medium">Qty</h6>
                                    </th>
                                    <th className="desc">
                                        <h6 className="text-sm text-medium">Price</h6>
                                    </th>
                                    <th className="desc">
                                        <h6 className="text-sm text-medium dis-price">Total</h6>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {parts_and_services.length !== 0 ? parts_and_services.map((object, key) => (
                                    object.type === "part"
                                        ?
                                        <tr key={key}>
                                            <td className="min-width">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                        <p>{object.label}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                        <p>{object.quantity}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                        <input className="price" disabled={(disable_price_update ? true : false)} value={object.job_part_price} onChange={e => { setPartPrice(key, e.target.value); }} type="text" name="price"></input>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width price  ">
                                                <div className="lead">
                                                    <div className="lead-text dis-price">
                                                        {(parseFloat(object.job_part_price) * parseFloat(object.quantity))}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        <tr key={key}>
                                            <td className="min-width">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                        <p>{object.label}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                        <input className="price" disabled={(disable_price_update ? true : false)} value={object.job_service_price} onChange={e => { setServicePrice(key, e.target.value); }} type="text" name="price"></input>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width price">
                                                <div className="lead">
                                                    <div className="lead-text dis-price">
                                                        {parseFloat(object.job_service_price)}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                )) : null}

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <h4>Total</h4>
                                    </td>
                                    <td className="min-width price" >
                                        <div className="lead">
                                            <div className="lead-text dis-price tot">
                                                {(part_total + service_total)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="input-style-1">
                            <label>Invoice Note</label>
                            <textarea value={invoice_note} disabled={(disable_price_update ? true : false)} onChange={e => { setInvoiceNote(e.target.value); }} placeholder="Type Invoice Note" name="invoice_note" rows="5"></textarea>
                        </div>
                    </div>
                </div>
                <input className="btn btn-primary float-end" onClick={handleSubmit} type="submit" value="Update Invoice" />
            </form>
            <div>
                <div
                    style={{ display: "none" }}// This make ComponentToPrint show   only while printing
                >
                    <PrintInvoice invoice_note={invoice_note} branch_data={branch_data} logo={Logo} job={job} parts_and_services={parts_and_services} total={(part_total + service_total)} payment_status={payment_status} invoice={invoice} invoice_date={invoice_date} header={true} ref={componentRef} />
                </div>
                <button className="btn btn-info" onClick={print}>Print this out!</button>
            </div>
        </div>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default EditInvoive;