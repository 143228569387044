import { Config } from "../../configs/Config";
import { OneCallBack, PostDatasetCallback } from "../middleware/Middleware";

export const fetchPartSelInvoices = (branch_id, _callback) => {
    const url = `${Config().API_URL}partsellinvoice/get/${branch_id}`;
    OneCallBack(url, _callback);
};

export const fetchPartSelInvoice = (id, _callback) => {
    const url = `${Config().API_URL}partsellinvoice/show/${id}`;
    OneCallBack(url, _callback);
};

export const fetchPartInvoiceTotal = (id1, status, _callback) => {
    const url = `${Config().API_URL}partsellinvoice/showtotalincome/${id1}/${status}`;
    OneCallBack(url, _callback);
};

export const partSelInvoiceSave = (dataset, _callback) => {
    const url = `${Config().API_URL}partsellinvoice/store`;
    PostDatasetCallback(url, dataset, _callback);
};

export const partSelInvoiceUpdate = (id, dataset, _callback) => {
    const url = `${Config().API_URL}partsellinvoice/update/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};

export const invoiceUpdatePayment = (id, dataset, _callback) => {
    const url = `${Config().API_URL}partsellinvoice/updatepayment/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};