import PropTypes from "prop-types";
function CustomersPart(props) {
    return (
        <div className="col-xl-3 col-lg-4 col-sm-6">
            <div className="icon-card mb-30">
                <div className="icon orange">
                    <i className="lni lni-user"></i>
                </div>
                <div className="content">
                    <h6 className="mb-10">Part Customers</h6>
                    <h3 className="text-bold mb-10">{props.part_customers_count}</h3>
                    <p className="text-sm text-danger">
                    </p>
                </div>
            </div>
        </div>
    );
}

CustomersPart.propTypes = {
    part_customers_count: PropTypes.any,
};

export default CustomersPart;