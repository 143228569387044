import { InvoiceReport } from "./charts/invoice/InvoiceReport";
import Layout from "../Layout";
import { useParams } from "react-router-dom";

function InvoiceReportPage() {

    const title = "Invoice Report";
    const { branchid } = useParams();
    const branch_id = branchid;

    const Content = <div className="row">
        <InvoiceReport branch_id={branch_id} />
    </div>;
    return (
        <Layout branch_id={branch_id} title={title} component={Content} />
    );
}

export default InvoiceReportPage;