import { Config } from "../../configs/Config";
import { OneCallBack, PostDatasetCallback, OneCallBackPage } from "../middleware/Middleware";

export const fetchServiceList = (id, _callback) => {
    const url = `${Config().API_URL}servicelist/get/${id}`;
    OneCallBack(url, _callback);
};

export const fetchServiceListPaginate = (id, items, _callback) => {
    const url = `${Config().API_URL}servicelist/getpage/${id}/${items}`;
    OneCallBack(url, _callback);
};

export const fetchServiceListPaginatePage = (url, _callback) => {
    OneCallBackPage(url, _callback);
};

export const fetchActiveServiceList = (id, _callback) => {
    const url = `${Config().API_URL}servicelist/activelist/${id}`;
    OneCallBack(url, _callback);
};

export const fetchService = (id, _callback) => {
    const url = `${Config().API_URL}servicelist/show/${id}`;
    OneCallBack(url, _callback);
};

export const serviceSave = (dataset, _callback) => {
    const url = `${Config().API_URL}servicelist/store`;
    PostDatasetCallback(url, dataset, _callback);
};

export const serviceUpdate = (id, dataset, _callback) => {
    const url = `${Config().API_URL}servicelist/update/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};