import PropTypes from "prop-types";
function Newjobs(props) {
    return (
        <div className="col-xl-3 col-lg-4 col-sm-6">
            <div className="icon-card mb-30">
                <div className="icon purple">
                    <i className="lni lni-cart-full"></i>
                </div>
                <div className="content">
                    <h6 className="mb-10">New Jobs</h6>
                    <h3 className="text-bold mb-10">{props.job_count}</h3>
                    <p className="text-sm text-success">
                    </p>
                </div>
            </div>
        </div>
    );
}

Newjobs.propTypes = {
    job_count: PropTypes.any,
};

export default Newjobs;