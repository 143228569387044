import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchPartSelInvoices } from "../../../api/partsellinvoice/PartSellInvoice";
import Layout from "../Layout";

function PartsSellInvoice() {
    const [loading_status] = useState("hide");
    const [data, setData] = useState([]);

    const title = "Part Invoice List";
    const { branchid } = useParams();
    const branch_id = branchid;

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/partsellinvoice/add", "text": "Add Part Invoice" });

    useEffect(function () {
        fetchPartSelInvoices(branch_id, setData);
    }, []);

    const Content = <div className="row">
        <div className="col-lg-12">
            <div className="card-style mb-30">
                <div className="table-wrapper table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th><h6>Invoice ID</h6></th>
                                <th><h6>Customer Name</h6></th>
                                <th><h6>Date</h6></th>
                                <th><h6>Actions</h6></th>
                                <th><h6>Invoice Payment</h6></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((object) => (
                                <tr key={object.part_sell_invoice_id}>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <p>{object.part_sell_invoice_id}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <p>{object.f_name} {object.l_name}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead-text">
                                            <p>{object.date}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <Link to={`/manager/branch/${branch_id}/partsellinvoice/edit/${object.part_sell_invoice_id}`} >Edit Invoice</Link>
                                    </td>
                                    <td className={"min-width inv-" + object.payment_status}>
                                        <div className="lead-text">
                                            <p>{object.total} RS<br></br>{object.payment_status}</p>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default PartsSellInvoice;