import { Config } from "../../configs/Config";
import { OneCallBack, OneCallBackPage, PostDatasetCallback } from "../middleware/Middleware";

export const fetchJobs = (branch_id, _callback) => {
    const url = `${Config().API_URL}job/get/${branch_id}`;
    OneCallBack(url, _callback);
};

export const fetchJobByVehicleCustomerPaginate = (id, id2, id3, items, _callback) => {
    const url = `${Config().API_URL}job/getbyvehiclecustomerpage/${id}/${id2}/${id3}/${items}`;
    OneCallBack(url, _callback);
};

export const fetchJobPaginatePage = (url, _callback) => {
    OneCallBackPage(url, _callback);
};

export const fetchJobsCount = (branch_id, _callback) => {
    const url = `${Config().API_URL}job/count/${branch_id}`;
    OneCallBack(url, _callback);
};

export const fetchJob = (id, _callback) => {
    const url = `${Config().API_URL}job/show/${id}`;
    OneCallBack(url, _callback);
};

export const fetchJobInvoiceTotal = (id1, status, _callback) => {
    const url = `${Config().API_URL}job/showtotalincome/${id1}/${status}`;
    OneCallBack(url, _callback);
};

export const fetchJobInvoiceWeekTotal = (id1, status, _callback) => {
    const url = `${Config().API_URL}job/showtotalweekincome/${id1}/${status}`;
    OneCallBack(url, _callback);
};

export const jobSave = (dataset, _callback) => {
    const url = `${Config().API_URL}job/store`;
    PostDatasetCallback(url, dataset, _callback);
};

export const jobUpdate = (id, dataset, _callback) => {
    const url = `${Config().API_URL}job/update/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};