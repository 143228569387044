import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchService, serviceUpdate } from "../../../api/service/Service";
import Swal from "sweetalert2";
import Layout from "../Layout";

function EditService() {
    const title = "Edit Service";

    const [loading_status, setLoadingStatus] = useState("hide");
    const [service_name, setServiceName] = useState("");
    const [service_status, setServiceStatus] = useState("");
    const [service, setService] = useState(null);
    const { branchid } = useParams();
    const branch_id = branchid;
    const { id } = useParams();

    useEffect(function () {
        if (service == null) {
            setLoadingStatus("show");
            fetchService(id, setService);
        }
        if (service != null) {
            setLoadingStatus("hide");
            setServiceName(service.service_name);
            setServiceStatus(service.service_status);
        }
    }, [service, id]);

    const updateReturn = (res) => {
        if (res == 1) {
            fetchService(id, setService);
            Swal.fire(
                "Good job!",
                "Service Updated..!",
                "success"
            );
        }
        setLoadingStatus("hide");

    };

    const validateDataset = (dataset) => {
        if (dataset.service_name !== "") {
            setLoadingStatus("show");
            serviceUpdate(id, dataset, updateReturn);
        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ service_name: service_name, service_status: service_status, branch_id: branch_id });
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/service/list", "text": "Service List" });

    const Content =
        <div className="row">
            <div className="col-lg-12">
                <form onSubmit={handleSubmit}>
                    <div className="card-style mb-30">
                        <div className="input-style-1">
                            <label>Service Name</label>
                            <input value={service_name} onChange={e => setServiceName(e.target.value)} name="service_name" type="text" placeholder="Service Name" />
                        </div>
                        <div className="select-style-1">
                            <label>Status</label>
                            <div className="select-position">
                                <select value={service_status} onChange={e => setServiceStatus(e.target.value)} >
                                    <option value="active">Active</option>
                                    <option value="deactivated">Deactivated</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <input className="btn btn-primary float-end" type="submit" value="Update Service" />
                </form>
            </div>
        </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default EditService;