function Footer() {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 order-last order-md-first">
                        <div className="copyright text-center text-md-start">
                            {/* <p className="text-sm">
                                Designed and Developed by <a href="https://badukade.lk" rel="noreferrer" target="_blank">badukade.lk</a>
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;