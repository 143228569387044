import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchYearList } from "../../../api/year/Year";
import { fetchModelsByMake, fetchMakes } from "../../../api/modelmake/ModelMake";
import { vehicleSave, fetchVehicleByNumber, vehicleBranchSave } from "../../../api/vehicle/Vehicle";
import Swal from "sweetalert2";
import Layout from "../Layout";

function AddVehicle() {
    const title = "Add Vehicle";

    const [loading_status, setLoadingStatus] = useState("hide");
    const [vehicle_number, setVehicleNumber] = useState("");

    const [model_year, setModelYear] = useState([]);
    const [model_year_id, setModelYearId] = useState(0);

    const [make_id, setMakeId] = useState(0);
    const [make, setMake] = useState([]);

    const [model_id, setModelId] = useState(0);
    const [model, setModel] = useState([]);

    const [res, setres] = useState([]);
    const [vehicle_ext, setVehicleExt] = useState(false);
    const { branchid } = useParams();
    const branch_id = branchid;

    const checkVehicleExt = (res) => {
        setres(res);
        if (res.length !== 0) {
            setVehicleExt(true);
        } else {
            setVehicleExt(false);
        }
    };

    useEffect(function () {
        if (vehicle_ext) {
            Swal.fire(
                "Oops...!",
                "Vehicle " + res.vehicle_number + " Already In Database..! Search By Vehicle Number",
                "error"
            );
            vehicleBranchSave(branch_id, { vehicle_id: res.vehicle_id });
            setVehicleNumber("");
        }
    }, [vehicle_ext]);

    useEffect(function () {
        fetchMakes(setMake);
        fetchYearList(setModelYear);
    }, []);

    useEffect(function () {
        if (make_id !== 0) {
            fetchModelsByMake(make_id, setModel);
        }
    }, [make_id]);

    const saveReturn = (res) => {
        if (!isNaN(res)) {
            setVehicleNumber("");
            setMakeId(0);
            setModelId(0);
            setModelYearId(0);
            Swal.fire(
                "Good job!",
                "Vehicle Saved..!",
                "success"
            );
        }
        setLoadingStatus("hide");

    };

    const validateDataset = (dataset) => {
        if (dataset.vehicle_number !== "" && dataset.model_id !== 0 && dataset.model_year_id !== 0) {
            if (!(dataset.vehicle_number).includes("-") || (dataset.vehicle_number).indexOf(" ") >= 0) {
                Swal.fire(
                    "Vehicle Number Error...!",
                    "Remove Spaces and Add Dash..! Example Numbers CAL-1234,65-1234",
                    "error"
                );
            } else {
                setLoadingStatus("show");
                vehicleSave(branch_id, dataset, saveReturn);
            }

        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ vehicle_number: vehicle_number, model_id: model_id, model_year_id: model_year_id, status: "active" });
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/vehicle/list", "text": "Vehicle List" });

    const Content = <div className="row">
        <div className="col-lg-12">
            <form onSubmit={handleSubmit}>
                <div className="card-style mb-30">
                    <div className="input-style-1">
                        <label>Vehicle Number</label>
                        <input value={vehicle_number} onChange={e => { setVehicleNumber(e.target.value); fetchVehicleByNumber(e.target.value, checkVehicleExt); }} name="vehicle_number" type="text" placeholder="Vehicle Number" />
                    </div>
                    <div className="select-style-1">
                        <label>Vehicle Make</label>
                        <div className="select-position">
                            <select value={make_id} onChange={e => { setModelId(0); setMakeId(e.target.value); }} >
                                <option value={0} disabled="disabled">Select Make</option>
                                {make.map((object) => (
                                    <option key={object.make_id} value={object.make_id}>{object.make_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="select-style-1">
                        <label>Vehicle Model</label>
                        <div className="select-position">
                            <select value={model_id} onChange={e => setModelId(e.target.value)} >
                                <option value={0} disabled="disabled">Select Model</option>
                                {model.map((object) => (
                                    <option key={object.model_id} value={object.model_id}>{object.model_name}</option>
                                ))}
                            </select>
                        </div>
                        <Link className="float-end" to={"/manager/branch/" + branch_id + "/model/add"}>
                            <button className="btn btn-outline-secondary float-end" type="button">Add New Model</button>
                        </Link>
                    </div>
                    <div className="select-style-1">
                        <label>Model Year</label>
                        <div className="select-position">
                            <select value={model_year_id} onChange={e => { setModelYearId(e.target.value); }} >
                                <option value={0} disabled="disabled">Select Model Year</option>
                                {model_year.map((object) => (
                                    <option key={object.model_year_id} value={object.model_year_id}>{object.model_year}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <input className="btn btn-primary float-end" type="submit" value="Save Vehicle" />
            </form>
        </div>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default AddVehicle;