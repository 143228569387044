import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchModelsByMake, fetchMakes, fetchMakeByModel } from "../../../api/modelmake/ModelMake";
import { fetchVehicle } from "../../../api/vehicle/Vehicle";
import { fetchYearList } from "../../../api/year/Year";
import { vehicleUpdate } from "../../../api/vehicle/Vehicle";
import Swal from "sweetalert2";
import Layout from "../Layout";

function EditVehicle() {
    const title = "Edit Vehicle";

    const [loading_status, setLoadingStatus] = useState("hide");
    const [vehicle_number, setVehicleNumber] = useState("");
    const [vehicle, setVehicle] = useState(null);

    const [model_year, setModelYear] = useState([]);
    const [model_year_id, setModelYearId] = useState(0);

    const [make_id, setMakeId] = useState(0);
    const [makes, setMakes] = useState([]);
    const [make, setMake] = useState([]);
    const { branchid } = useParams();
    const branch_id = branchid;

    const [model_id, setModelId] = useState(0);
    const [model, setModel] = useState([]);


    const { id } = useParams();

    useEffect(function () {
        setLoadingStatus("show");
        fetchMakes(setMakes);
        fetchYearList(setModelYear);
        fetchVehicle(id, setVehicle);
    }, [id]);

    useEffect(function () {
        if (make !== 0) {
            setMakeId(make.make_id);
        }
    }, [make]);

    useEffect(function () {
        if (model_id !== 0) {
            fetchMakeByModel(model_id, setMake);
        }
    }, [model_id]);

    useEffect(function () {
        if (vehicle !== null) {
            setLoadingStatus("hide");
            setModelYearId(vehicle.model_year_id);
            setModelId(vehicle.model_id);
            setVehicleNumber(vehicle.vehicle_number);
        }
    }, [vehicle]);

    useEffect(function () {
        if (make_id !== 0) {
            fetchModelsByMake(make_id, setModel);
        }
    }, [make_id]);

    const updateReturn = (res) => {
        if (res == 1) {
            Swal.fire(
                "Good job!",
                "Vehicle Updated..!",
                "success"
            );
        }
        setLoadingStatus("hide");
    };

    const validateDataset = (dataset) => {
        if (dataset.vehicle_number !== "" && dataset.model_id !== 0 && dataset.model_year_id !== 0) {
            setLoadingStatus("show");
            vehicleUpdate(id, dataset, updateReturn);
        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ vehicle_number: vehicle_number, model_id: model_id, model_year_id: model_year_id, status: "active" });
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/vehicle/list", "text": "Vehicle List" });

    const Content = <div className="row">
        <div className="col-lg-12">
            <form onSubmit={handleSubmit}>
                <div className="card-style mb-30">
                    <div className="input-style-1">
                        <label>Vehicle Number</label>
                        <input value={vehicle_number} disabled={true} onChange={e => setVehicleNumber(e.target.value)} name="vehicle_number" type="text" placeholder="Vehicle Number" />
                    </div>
                    <div className="select-style-1">
                        <label>Vehicle Make</label>
                        <div className="select-position">
                            <select value={make_id} onChange={e => { setModelId(0); setMakeId(e.target.value); }} >
                                <option value={0} disabled="disabled">Select Make</option>
                                {makes.map((object) => (
                                    <option key={object.make_id} value={object.make_id}>{object.make_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="select-style-1">
                        <label>Vehicle Model</label>
                        <div className="select-position">
                            <select value={model_id} onChange={e => setModelId(e.target.value)} >
                                <option value={0} disabled="disabled">Select Model</option>
                                {model.map((object) => (
                                    <option key={object.model_id} value={object.model_id}>{object.model_name}</option>
                                ))}
                            </select>
                        </div>
                        <Link className="float-end" to={"/manager/branch/" + branch_id + "/model/add"}>
                            <button className="btn btn-outline-secondary float-end" type="button">Add New Model</button>
                        </Link>
                    </div>
                    <div className="select-style-1">
                        <label>Model Year</label>
                        <div className="select-position">
                            <select value={model_year_id} onChange={e => { setModelYearId(e.target.value); }} >
                                <option value={0} disabled="disabled">Select Model Year</option>
                                {model_year.map((object) => (
                                    <option key={object.model_year_id} value={object.model_year_id}>{object.model_year}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="alert-box secondary-alert pl-100">
                        <div className="left">
                            <h5 className="text-bold">Notice</h5>
                        </div>
                        <div className="alert">
                            <h4 className="alert-heading">Vehicle Number</h4>
                            <p className="text-medium">
                                For security reasons Vehicle number is not editable/ Please add new vehicle if vehicle number is wrong
                            </p>
                        </div>
                    </div>
                </div>
                <input className="btn btn-primary float-end" type="submit" value="Update Vehicle" />
            </form>
        </div>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default EditVehicle;