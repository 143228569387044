import { ServiceReport } from "./charts/service/ServiceReport";
import Layout from "../Layout";
import { useParams } from "react-router-dom";

function ServicesReportPage() {

    const title = "Services Report";
    const { branchid } = useParams();
    const branch_id = branchid;

    const Content = <div className="row">
        <ServiceReport branch_id={branch_id} />
    </div>;
    return (
        <Layout branch_id={branch_id} title={title} component={Content} />
    );
}

export default ServicesReportPage;