import React, { useEffect, useState } from "react";
import { fetchMakes, fetchModelsByMake, modelSave } from "../../../api/modelmake/ModelMake";
import Swal from "sweetalert2";
import Layout from "../Layout";
import { useParams } from "react-router-dom";

function ModelAdd() {
    const title = "Model Add";

    const [make_id, setMakeId] = useState(0);
    const [make, setMake] = useState([]);

    const [model_name, setModelName] = useState("");
    const [model, setModel] = useState([]);
    const { branchid } = useParams();
    const branch_id = branchid;

    const [data_fetched, setDataFetched] = useState(false);

    useEffect(function () {
        if (!data_fetched) {
            fetchMakes(setMake);
            setDataFetched(true);
        }
    }, [data_fetched]);

    useEffect(function () {
        if (make_id !== 0) {
            fetchModelsByMake(make_id, setModel);
        }
    }, [make_id]);


    const saveReturn = (res) => {
        if (!isNaN(res)) {
            setModelName("");
            fetchMakes(setMake);
            fetchModelsByMake(make_id, setModel);
            Swal.fire(
                "Good job!",
                "Model Saved..!",
                "success"
            );
        }

    };

    const validateDataset = (dataset) => {
        if (dataset.model_name !== "") {
            modelSave(dataset, saveReturn);
        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ make_id: make_id, model_name: model_name, status: "active", Model_ID_API: 0, Make_ID_API: 0 });
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/vehicle/list", "text": "Vehicle List" });

    const Content = <div className="row">
        <div className="col-lg-12">
            <form onSubmit={handleSubmit}>
                <div className="card-style mb-30">
                    <div className="select-style-1">
                        <label>Vehicle Make</label>
                        <div className="select-position">
                            <select defaultValue={make_id} onChange={e => { setMakeId(e.target.value); }} >
                                <option value={0} disabled="disabled">Select Make</option>
                                {make.map((object) => (
                                    <option key={object.make_id} value={object.make_id}>{object.make_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div>
                        {model.map((object) => (
                            <span className="model_name_set" key={object.model_id}>{object.model_name} </span>
                        ))}
                    </div>
                    <br></br>
                    <div className="input-style-2">
                        <label>New Name</label>
                        <input value={model_name} onChange={e => setModelName(e.target.value)} name="model_name" type="text" placeholder="Model Name" />
                    </div>
                </div>
                <input className="btn btn-primary float-end" type="submit" value="Save Model" />
            </form>
        </div>
    </div>;
    return (
        <Layout branch_id={branch_id} title={title} component={Content} quickButton={quickButton} />
    );
}

export default ModelAdd;