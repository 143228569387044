import { Config } from "../../configs/Config";
import { OneCallBack, PostDatasetCallback } from "../middleware/Middleware";

export const fetchInvoices = (branch_id, _callback) => {
    const url = `${Config().API_URL}invoice/get/${branch_id}`;
    OneCallBack(url, _callback);
};

export const fetchInvoice = (id, _callback) => {
    const url = `${Config().API_URL}invoice/show/${id}`;
    OneCallBack(url, _callback);
};

export const fetchOneInvoice = (id, _callback) => {
    const url = `${Config().API_URL}invoice/showone/${id}`;
    OneCallBack(url, _callback);
};

export const invoiceSave = (dataset, _callback) => {
    const url = `${Config().API_URL}invoice/store`;
    PostDatasetCallback(url, dataset, _callback);
};

export const invoiceUpdate = (id, dataset, _callback) => {
    const url = `${Config().API_URL}invoice/update/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};

export const invoiceUpdatePayment = (id, dataset, _callback) => {
    const url = `${Config().API_URL}invoice/updatepayment/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};