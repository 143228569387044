import PropTypes from "prop-types";
function PartInvoicePending(props) {
    return (
        <div className="col-xl-3 col-lg-4 col-sm-6">
            <div className="icon-card mb-30">
                <div className="icon primary">
                    <i className="lni lni-credit-cards"></i>
                </div>
                <div className="content">
                    <h6 className="mb-10">Part Invoice Pending</h6>
                    <h3 className="text-bold mb-10">{props.part_pending} RS</h3>
                    <p className="text-sm text-danger">
                    </p>
                </div>
            </div>
        </div>
    );
}

PartInvoicePending.propTypes = {
    part_pending: PropTypes.any,
};

export default PartInvoicePending;