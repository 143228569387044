import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchServiceListPaginate, fetchServiceListPaginatePage, serviceSave } from "../../../api/service/Service";
import Swal from "sweetalert2";
import Layout from "../Layout";

function Service() {
    const title = "Service List";
    const add_title = "Add Service";

    const [loading_status, setLoadingStatus] = useState("hide");
    const [service_name, setServiceName] = useState("");
    const [data, setData] = useState([]);
    const [data_fetched, setDataFetched] = useState(false);
    const { branchid } = useParams();
    const branch_id = branchid;

    const [items_per_page] = useState(20);
    const [links, setLinks] = useState([]);
    const [page_data, setPageData] = useState([]);

    useEffect(function () {
        if (!data_fetched) {
            setLoadingStatus("show");
            fetchServiceListPaginate(branch_id, items_per_page, setData);
            setDataFetched(true);
        }
    }, [data_fetched]);

    useEffect(function () {
        if (data_fetched) {
            setLoadingStatus("hide");
            setLinks(data.links);
            setPageData(data.data);
        }
    }, [data]);

    const loadPage = (url) => {
        if (url !== null) {
            setLoadingStatus("show");
            fetchServiceListPaginatePage(url, setData);
        }

    };

    const saveReturn = (res) => {
        if (!isNaN(res)) {
            setServiceName("");
            fetchServiceListPaginate(branch_id, items_per_page, setData);
            Swal.fire(
                "Good job!",
                "Service Saved..!",
                "success"
            );
        }
        setLoadingStatus("hide");

    };

    const validateDataset = (dataset) => {
        if (dataset.service_name !== "") {
            setLoadingStatus("show");
            serviceSave(dataset, saveReturn);
        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ service_name: service_name, branch_id: branch_id });
    };
    const Content =
        <div className="row">
            <div className="col-lg-12">
                <div className="card-style mb-30">
                    <div className="table-wrapper table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th><h6>Service Name</h6></th>
                                    <th><h6>Service Status</h6></th>
                                    <th><h6>Actions</h6></th>
                                </tr>
                            </thead>
                            <tbody>
                                {page_data.map((object) => (
                                    <tr key={object.service_list_id} className={"service-" + object.service_status}>
                                        <td className="min-width">
                                            <div className="lead">
                                                <div className="lead-text">
                                                    <p>{object.service_name}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="min-width">
                                            <div className="lead-text">
                                                <p>{object.service_status}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                {/* <button className="text-danger">
                                            <i className="lni lni-trash-can"></i>
                                        </button> */}
                                                <Link to={`/manager/branch/${branch_id}/service/edit/${object.service_list_id}`} >Edit</Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
                <nav aria-label="...">
                    <ul className="pagination">
                        {links.map((object) => (
                            <li key={object.label} className={`page-item ${(object.active ? "active" : "")}`}>
                                <div className="page-link" onClick={() => loadPage(object.url)}>{object.label}</div>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div className="col-lg-12">
                <h3>{add_title}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="card-style mb-30">
                        <div className="input-style-1">
                            <label>Service Name</label>
                            <input value={service_name} onChange={e => setServiceName(e.target.value)} name="service_name" type="text" placeholder="Service Name" />
                        </div>
                    </div>
                    <input className="btn btn-primary float-end" type="submit" value="Save Service" />
                </form>
            </div>
        </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} />
    );
}

export default Service;