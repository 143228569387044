import { Config } from "../../configs/Config";
import { OneCallBack, PostDatasetCallback } from "../middleware/Middleware";

export const fetchAddedParts = (id, _callback) => {
    const url = `${Config().API_URL}jobpartlist/get/${id}`;
    OneCallBack(url, _callback);
};

export const deleteAddedParts = (id, _callback) => {
    const url = `${Config().API_URL}jobpartlist/destroylist/${id}`;
    OneCallBack(url, _callback);
};

export const jobPartSave = (dataset, _callback) => {
    const url = `${Config().API_URL}jobpartlist/store`;
    PostDatasetCallback(url, dataset, _callback);
};

export const jobPartUpdate = (id, id2, dataset, _callback) => {
    const url = `${Config().API_URL}jobpartlist/update/${id}/${id2}`;
    PostDatasetCallback(url, dataset, _callback);
};
