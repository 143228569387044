import { Config } from "../../configs/Config";
const debug = Config().DEBUG;

function LogoutHelper(value) {
    if (value === "logout") {
        if (!debug) {
            window.location.replace("/login");
        }
    }
}

export default LogoutHelper;