import ReactLoading from "react-loading";
import PropTypes from "prop-types";
function Loading(props) {
    return (
        <div className={`loading-content ${props.loading}`}>
            <ReactLoading type={"balls"} className={"loading"} color={"#000"} height={100} width={100} />
        </div>

    );
}

Loading.propTypes = {
    loading: PropTypes.any,
};

export default Loading;