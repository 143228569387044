import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getPartsSellPartTotals, getPartTotals } from "../../../../../api/reporting/Reports";
import { FilterYears } from "../../../../../components/filter/FilterYears";
import { FilterMonths } from "../../../../../components/filter/FilterMonths";
import Loading from "../../../../common/Loading";
import PropTypes from "prop-types";

export const PartReport = (props) => {
    const [loading_status, setLoadingStatus] = useState("hide");

    const [filter_type, setFilterType] = useState("month");
    // const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    // const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month_numbers = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    // const days_numbers = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];

    const d = new Date();
    const [this_month_number, setThisMonth] = useState(month_numbers[d.getMonth()]);
    const [this_year, setThisYear] = useState(d.getFullYear());
    let this_day = d.getDay();

    const [labels_list, setLabelsList] = useState([]);
    const [labels_list2, setLabelsList2] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);

    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);

    const branch_id = props.branch_id;
    const [invoice_parts, setInvoiceParts] = useState([]);
    const [part_sell_invoice_parts, setPartSellInvoiceParts] = useState([]);
    const [invoice_parts_fetched, setInvoicePartsFetched] = useState(false);

    useEffect(function () {
        if (!invoice_parts_fetched) {
            setLoadingStatus("show");
            getPartTotals(branch_id, { filter: filter_type, year: this_year, month: this_month_number, day: this_day }, setInvoiceParts);
            getPartsSellPartTotals(branch_id, { filter: filter_type, year: this_year, month: this_month_number, day: this_day }, setPartSellInvoiceParts);
            setInvoicePartsFetched(true);
        }

    }, [invoice_parts]);

    useEffect(function () {
        setLoadingStatus("show");
        getPartTotals(branch_id, { filter: filter_type, year: this_year, month: this_month_number, day: this_day }, setInvoiceParts);
        getPartsSellPartTotals(branch_id, { filter: filter_type, year: this_year, month: this_month_number, day: this_day }, setPartSellInvoiceParts);
        setInvoicePartsFetched(true);

    }, [this_year, this_month_number, filter_type]);

    useEffect(function () {
        if (invoice_parts.length !== 0) {
            const new_data = invoice_parts.map(({ date, payment_status, job_part_price, part_id, part_name, quantity, purchased_price }) => {
                return {
                    date: (date.split(" ")[0]),
                    payment_status: payment_status,
                    job_part_price: job_part_price,
                    part_id: part_id,
                    part_name: part_name,
                    quantity: quantity,
                    purchased_price: purchased_price,
                    sales: (parseFloat(job_part_price) - parseFloat(purchased_price)) * quantity
                };
            });
            getReportData(new_data);
        } else {
            setData1([]);
            setData2([]);
        }
        setLoadingStatus("hide");
    }, [invoice_parts]);

    useEffect(function () {
        if (part_sell_invoice_parts.length !== 0) {
            const new_data = part_sell_invoice_parts.map(({ date, payment_status, invoice_part_price, part_id, part_name, quantity, purchased_price }) => {
                return {
                    date: (date.split(" ")[0]),
                    payment_status: payment_status,
                    invoice_part_price: invoice_part_price,
                    part_id: part_id,
                    part_name: part_name,
                    quantity: quantity,
                    purchased_price: purchased_price,
                    sales: (parseFloat(invoice_part_price) - parseFloat(purchased_price)) * quantity
                };
            });
            getSellReportData(new_data);
        } else {
            setData3([]);
            setData4([]);
        }
        setLoadingStatus("hide");
    }, [part_sell_invoice_parts]);

    const getReportData = (arr_st) => {
        var temp_lb = [];
        var temp_lb2 = [];
        arr_st.forEach(function callback(value) {
            if (value.payment_status === "payed") {
                if (temp_lb[value.part_name] === undefined) {
                    temp_lb[value.part_name] = value.quantity;
                    temp_lb2[value.part_name] = value.sales;
                } else {
                    temp_lb[value.part_name] = temp_lb[value.part_name] + value.quantity;
                    temp_lb2[value.part_name] = temp_lb2[value.part_name] + value.sales;
                }
            }
        });
        var lb = [];
        var dt1 = [];
        var dt2 = [];
        for (var key in temp_lb) {
            lb.push(key);
            dt1.push(temp_lb[key]);
            dt2.push(temp_lb2[key]);
        }
        setLabelsList(lb);
        setData1(dt1);
        setData2(dt2);

    };

    const getSellReportData = (arr_st) => {
        var temp_lb = [];
        var temp_lb2 = [];
        arr_st.forEach(function callback(value) {
            if (value.payment_status === "payed") {
                if (temp_lb[value.part_name] === undefined) {
                    temp_lb[value.part_name] = value.quantity;
                    temp_lb2[value.part_name] = value.sales;
                } else {
                    temp_lb[value.part_name] = temp_lb[value.part_name] + value.quantity;
                    temp_lb2[value.part_name] = temp_lb2[value.part_name] + value.sales;
                }
            }
        });
        var lb = [];
        var dt1 = [];
        var dt2 = [];
        for (var key in temp_lb) {
            lb.push(key);
            dt1.push(temp_lb[key]);
            dt2.push(temp_lb2[key]);
        }
        setLabelsList2(lb);
        setData3(dt1);
        setData4(dt2);

    };

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options1 = {
        indexAxis: "y",
        aspectRatio: 1,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: "right"
            },
            title: {
                display: true,
                text: "Parts Job Sold Count",
            },
        },
    };
    const options2 = {
        indexAxis: "y",
        aspectRatio: 1,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: "right"
            },
            title: {
                display: true,
                text: "Job Parts Sales",
            },
        },
    };

    const options3 = {
        indexAxis: "y",
        aspectRatio: 1,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: "right"
            },
            title: {
                display: true,
                text: "Parts Direct Sell Count",
            },
        },
    };
    const options4 = {
        indexAxis: "y",
        aspectRatio: 1,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: "right"
            },
            title: {
                display: true,
                text: "Direct Parts Sales",
            },
        },
    };

    const labels1 = labels_list;

    const data_set1 = {
        labels: labels1,
        datasets: [
            {
                label: "Sold Count",
                data: data1,
                borderColor: "rgb(43 114 38)",
                backgroundColor: "rgb(109 255 99 / 50%)",
            }
        ],
    };
    const data_set2 = {
        labels: labels1,
        datasets: [
            {
                label: "Sales",
                data: data2,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    };

    const labels2 = labels_list2;

    const data_set3 = {
        labels: labels2,
        datasets: [
            {
                label: "Sold Count",
                data: data3,
                borderColor: "rgb(255 177 70)",
                backgroundColor: "rgb(255 177 70/50%)",
            }
        ],
    };
    const data_set4 = {
        labels: labels2,
        datasets: [
            {
                label: "Sales",
                data: data4,
                borderColor: "rgb(87 230 255)",
                backgroundColor: "rgb(87 230 255/50%)",
            },
        ],
    };

    return (
        <div className="report-section gray">
            <Loading loading={loading_status} />
            <div className="select-style-1">
                <label>Parts Reports Filter By</label>
                <select onChange={e => setFilterType(e.target.value)}>
                    <option value="month" >Month</option>
                    <option value="year" >Year</option>
                    <option value="all" >All Time</option>
                </select>
            </div>
            <div className={`on-main-filter-${filter_type} select-style-1`}>
                <select value={this_year} onChange={e => setThisYear(e.target.value)}>
                    <FilterYears />
                </select>
                <select className={`on-filter-${filter_type}`} value={this_month_number} onChange={e => setThisMonth(e.target.value)}>
                    <FilterMonths />
                </select>
            </div>
            <div className="row">
                <div className="col">
                    <Bar options={options1} data={data_set1} />
                </div>
                <div className="col">
                    <Bar options={options2} data={data_set2} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Bar options={options3} data={data_set3} />
                </div>
                <div className="col">
                    <Bar options={options4} data={data_set4} />
                </div>
            </div>
        </div>
    );
};

PartReport.propTypes = {
    branch_id: PropTypes.any,
};