import React, { useState } from "react";
import { partSave } from "../../../api/parts/Parts";
import Swal from "sweetalert2";
import Layout from "../Layout";
import { useParams } from "react-router-dom";

function AddParts() {
    const title = "Add Part";

    const [loading_status, setLoadingStatus] = useState("hide");

    const [part_name, setPartName] = useState("");
    const [price, setPrice] = useState(0);
    const [stock_count, setStockCount] = useState("");
    const [part_code, setPartCode] = useState("");
    const [part_status, setPartStatus] = useState("active");
    const { branchid } = useParams();
    const branch_id = branchid;


    const saveReturn = (res) => {
        if (!isNaN(res)) {
            setPartName("");
            setPrice(0);
            setStockCount("");
            setPartCode("");
            Swal.fire(
                "Good job!",
                "Part Saved..!",
                "success"
            );
        }
        setLoadingStatus("hide");
    };

    const validateDataset = (dataset) => {
        if (dataset.part_name !== "" && dataset.price !== "" && dataset.stock_count !== "" && dataset.part_code !== "" && !isNaN(dataset.price) && !isNaN(dataset.stock_count)) {
            setLoadingStatus("show");
            partSave(dataset, saveReturn);
        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ part_name: part_name, price: price, stock_count: stock_count, branch_id: branch_id, part_code: part_code, part_status: part_status });
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/parts/list", "text": "Parts List" });

    const Content =
        <div className="row">
            <div className="col-lg-12">
                <form onSubmit={handleSubmit}>
                    <div className="card-style mb-30">
                        <div className="input-style-1">
                            <label>Part Name</label>
                            <input value={part_name} onChange={e => setPartName(e.target.value)} name="part_name" type="text" placeholder="Part Name" />
                        </div>
                        <div className="input-style-2">
                            <label>Part Code</label>
                            <input value={part_code} onChange={e => setPartCode(e.target.value)} name="part_code" type="text" placeholder="Part Code" />
                        </div>
                        <div className="input-style-2">
                            <label>Price</label>
                            <input value={price} onChange={e => setPrice(e.target.value)} name="price" type="text" placeholder="Price" />
                        </div>
                        <div className="input-style-1">
                            <label>Stock Count</label>
                            <input value={stock_count} onChange={e => setStockCount(e.target.value)} name="stock_count" type="text" placeholder="Stock Count" />
                        </div>
                        <div className="select-style-1">
                            <label>Part Status</label>
                            <div className="select-position">
                                <select value={part_status} onChange={e => { setPartStatus(e.target.value); }} >
                                    <option value="active">Active</option>
                                    <option value="deactivated">Deactivated</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <input className="btn btn-primary float-end" type="submit" value="Save Part" />
                </form>
            </div>
        </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default AddParts;