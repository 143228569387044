import { Config } from "../../configs/Config";
import { OneCallBack, PostDatasetCallback, OneCallBackPage } from "../middleware/Middleware";

export const fetchParts = (id, _callback) => {
    const url = `${Config().API_URL}parts/get/${id}`;
    OneCallBack(url, _callback);
};

export const fetchPartsPaginate = (id, items, _callback) => {
    const url = `${Config().API_URL}parts/getpage/${id}/${items}`;
    OneCallBack(url, _callback);
};

export const fetchPartsSearchPaginate = (id, txt, items, _callback) => {
    const url = `${Config().API_URL}parts/searchpage/${id}/${txt}/${items}`;
    OneCallBack(url, _callback);
};

export const fetchPartsPaginatePage = (url, _callback) => {
    OneCallBackPage(url, _callback);
};

export const fetchStockParts = (id, _callback) => {
    const url = `${Config().API_URL}parts/getstock/${id}`;
    OneCallBack(url, _callback);
};

export const fetchPart = (id, _callback) => {
    const url = `${Config().API_URL}parts/show/${id}`;
    OneCallBack(url, _callback);
};

export const partSave = (dataset, _callback) => {
    const url = `${Config().API_URL}parts/store`;
    PostDatasetCallback(url, dataset, _callback);
};

export const partUpdate = (id, dataset, _callback) => {
    const url = `${Config().API_URL}parts/update/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};

export const partIncrementStock = (id, dataset, _callback) => {
    const url = `${Config().API_URL}parts/increment/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};

export const partDecrementStock = (id, dataset, _callback) => {
    const url = `${Config().API_URL}parts/decrement/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};