import { useEffect, useState } from "react";
import { fetchJobs } from "../../../api/job/Job";
import { fetchJobInvoiceTotal } from "../../../api/job/Job";
import { InvoiceReport } from "../reports/charts/invoice/InvoiceReport";
import Layout from "../Layout";
import { fetchPartInvoiceTotal, fetchPartSelInvoices } from "../../../api/partsellinvoice/PartSellInvoice";
import { useParams } from "react-router-dom";
import Newjobs from "../../../components/dashboard/Newjobs";
import JobInvoicePending from "../../../components/dashboard/JobInvoicePending";
import Customers from "../../../components/dashboard/Customers";
import PartInvoiceTotal from "../../../components/dashboard/PartInvoiceTotal";
import PartInvoicePending from "../../../components/dashboard/PartInvoicePending";
import CustomersPart from "../../../components/dashboard/CustomersPart";
import NewParts from "../../../components/dashboard/NewParts";

function BranchDashboard() {

    const [loading_status, setLoadingStatus] = useState("hide");
    const { branchid } = useParams();
    const branch_id = branchid;
    const [job_count, setJobCount] = useState(0);
    const [jobs, setJobs] = useState(0);
    const [customers_count, setCustomersCount] = useState(0);

    const [part_count, setPartCount] = useState(0);
    const [parts, setParts] = useState(0);
    const [part_customers_count, setPartCustomersCount] = useState(0);

    const [income, setIncome] = useState(null);
    const [pending, setPending] = useState(null);

    const [part_income, setPartIncome] = useState(null);
    const [part_pending, setPartPending] = useState(null);

    useEffect(function () {
        if (income === null) {
            fetchJobInvoiceTotal(branch_id, "payed", numberWithCommas);
        }
        if (pending === null) {
            fetchJobInvoiceTotal(branch_id, "pending", numberWithCommas2);
        }
    }, [income, pending, branch_id]);

    useEffect(function () {
        if (part_income === null) {
            fetchPartInvoiceTotal(branch_id, "payed", numberWithCommas3);
        }
        if (part_pending === null) {
            fetchPartInvoiceTotal(branch_id, "pending", numberWithCommas4);
        }
    }, [part_income, part_pending, branch_id]);

    useEffect(function () {
        if (jobs === 0) {
            setLoadingStatus("show");
            fetchJobs(branch_id, setJobs);
        } else if (jobs !== 0) {
            setJobCount(jobs.length);
            var u_customer_list = [];
            jobs.forEach(element => {
                u_customer_list[element.customer_id] = "1";
            });
            var filtered = u_customer_list.filter(function (el) {
                return el != null;
            });
            setCustomersCount(filtered.length);
            setLoadingStatus("hide");
        }
    }, [jobs, branch_id]);

    useEffect(function () {
        if (parts === 0) {
            setLoadingStatus("show");
            fetchPartSelInvoices(branch_id, setParts);
        } else if (parts !== 0) {
            setPartCount(parts.length);
            var u_customer_list = [];
            parts.forEach(element => {
                u_customer_list[element.customer_id] = "1";
            });
            var filtered = u_customer_list.filter(function (el) {
                return el != null;
            });
            setPartCustomersCount(filtered.length);
            setLoadingStatus("hide");
        }
    }, [parts, branch_id]);

    function numberWithCommas(x) {
        setIncome(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }

    function numberWithCommas2(x) {
        setPending(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }

    function numberWithCommas3(x) {
        setPartIncome(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }

    function numberWithCommas4(x) {
        setPartPending(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }

    const title = "Branch Dashboard";

    const Content =
        <div className="row">
            <Newjobs job_count={job_count} />
            <JobInvoicePending pending={pending} />
            <Customers customers_count={customers_count} />
            <NewParts part_count={part_count} />
            <PartInvoiceTotal part_income={part_income} />
            <PartInvoicePending part_pending={part_pending} />
            <CustomersPart part_customers_count={part_customers_count} />
            <InvoiceReport branch_id={branch_id} />
        </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} />
    );
}

export default BranchDashboard;