import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function UserShopsBranches(props) {
    return (
        <div className="col-lg-12">
            <h6 className="mb-10">Shop/Branch List</h6>
            <p className="text-sm mb-20">
                This is the list of branches/shops you can manage
            </p>
            <div className="table-wrapper table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th><h6>Logo</h6></th>
                            <th><h6>Shop name</h6></th>
                            <th><h6>Branch name</h6></th>
                            <th><h6>Admin Level</h6></th>
                            <th><h6>Action</h6></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.user_data.map((object) => (
                            <tr key={object.branch_id}>
                                <td className="min-width">
                                    <img src={object.logo_image} alt="" className="user_dash_shop_logo" />
                                </td>
                                <td className="min-width">
                                    <p>{object.shop_name}</p>
                                </td>
                                <td className="min-width">
                                    <p>{object.branch_name}</p>
                                </td>
                                <td className="min-width">
                                    <p>{object.user_role_name}</p>
                                </td>
                                <td>
                                    <Link to={"/manager/branch/" + object.branch_id}>Visit Branch</Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

UserShopsBranches.propTypes = {
    user_data: PropTypes.any,
};

export default UserShopsBranches;