import { signIn } from "../../api/user/User";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { fetchCityNoKey } from "../../api/city/City";
import { Link } from "react-router-dom";
import Config from "../../configs/Config";

function Signup() {

    const [f_name, setFirstName] = useState("");
    const [l_name, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [re_password, setRePassword] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [city_id, setCityId] = useState(0);
    const [city, setCity] = useState([]);
    const [data_fetched, setDataFetched] = useState(false);

    const [signup_bt_status] = useState(false);
    const [red_class, setRedClass] = useState("");


    useEffect(function () {
        if (!data_fetched) {
            fetchCityNoKey(setCity);
            setDataFetched(true);
        }
    }, [data_fetched]);

    useEffect(function () {
        if (password !== re_password) {
            setRedClass("error-red-input");
        } else {
            setRedClass("");
        }
    }, [password, re_password]);

    const validateData = () => {
        if (f_name === "" || l_name === "" || username === "" || email === "" || password === "" || re_password === "" || mobile === "" || address === "" || city_id === 0) {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        } else if (password === re_password) {
            signIn({ f_name: f_name, l_name: l_name, username: username, email: email, password: password, mobile: mobile, address: address, city_id: city_id }, saveReturn);
        }
    };

    const saveReturn = (res) => {
        if (!isNaN(res)) {
            Swal.fire(
                "Well done!",
                "User Details Sent and We are creating your Account, We Will Get Back To You Soon..!!",
                "success"
            );
            clearFields();
        }

    };

    const clearFields = () => {
        setFirstName("");
        setLastName("");
        setUsername("");
        setEmail("");
        setPassword("");
        setRePassword("");
        setMobile("");
        setAddress("");
        setCityId(0);
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateData();
    };

    return (
        <section className="signin-section">
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="title mb-30">
                                <h2>Request New Account</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-0 auth-row">
                    <div className="col-lg-6">
                        <div className="auth-cover-wrapper bg-primary-100">
                            <div className="auth-cover">
                                <div className="title text-center">
                                    <h1 className="text-primary mb-10">Get Started</h1>
                                    <p className="text-medium">
                                        Start creating the best possible user experience
                                        <br className="d-sm-block" />
                                        for your customers.
                                    </p>
                                </div>
                                <div className="cover-image">
                                    <img src={`${Config().IMAGE_SERVER}admin-images/signin-image.jpg`} alt="" />
                                </div>
                                <div className="shape-image">
                                    <img src={`${Config().IMAGE_SERVER}admin-images/shape.svg`} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="signup-wrapper">
                            <div className="form-wrapper">
                                <h6 className="mb-15">Sign Up Form</h6>
                                <p className="text-sm mb-25">
                                    Start creating the best possible user experience for you
                                    customers.
                                </p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="input-style-1">
                                                <label>First Name</label>
                                                <input required value={f_name} type="text" onChange={e => setFirstName(e.target.value)} placeholder="First Name" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-style-1">
                                                <label>Last Name</label>
                                                <input required value={l_name} type="text" onChange={e => setLastName(e.target.value)} placeholder="Last Name" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-style-1">
                                                <label>User Name</label>
                                                <input required value={username} type="text" onChange={e => setUsername(e.target.value)} placeholder="User Name" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-style-1">
                                                <label>Email</label>
                                                <input required value={email} type="email" onChange={e => setEmail(e.target.value)} placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-style-1">
                                                <label>Password</label>
                                                <input className={red_class} required value={password} type="password" onChange={e => setPassword(e.target.value)} placeholder="Password" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-style-1">
                                                <label>Re-type Password</label>
                                                <input className={red_class} required value={re_password} type="password" onChange={e => setRePassword(e.target.value)} placeholder="Re-type Password" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-style-1">
                                                <label>Mobile</label>
                                                <input required value={mobile} type="text" onChange={e => setMobile(e.target.value)} placeholder="Mobile" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-style-1">
                                                <label>Address</label>
                                                <input required value={address} type="text" onChange={e => setAddress(e.target.value)} placeholder="Address" />
                                            </div>
                                        </div>
                                        <div className="select-style-1">
                                            <label>City</label>
                                            <div className="select-position">
                                                <select value={city_id} onChange={e => setCityId(e.target.value)} >
                                                    <option value="0" disabled="disabled">Select City</option>
                                                    {city.map((object) => (
                                                        <option key={object.city_id} value={object.city_id}>{object.city_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="  button-group d-flex justify-content-center flex-wrap">
                                                <button disabled={signup_bt_status} className="main-btn  primary-btn  btn-hover  w-100  text-center">
                                                    Sign Up
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="singup-option pt-40">
                                    <p className="text-sm text-medium text-dark text-center">
                                        Already have an account? <Link to="/login">Sign In</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Signup;