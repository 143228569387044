import { useEffect, useState } from "react";
import { loginTo } from "../../api/user/User";
import Swal from "sweetalert2";
import Loading from "../common/Loading";
import { Link } from "react-router-dom";
import Config from "../../configs/Config";

function Login() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [login, setLogin] = useState([]);
    const [loading_status, setLoadingStatus] = useState("hide");

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoadingStatus("show");
        loginTo({ username: username, password: password }, setLogin);
    };

    useEffect(function () {
        if (typeof login === "string") {
            const login_obj = JSON.parse(login);
            if (login_obj.status === "success") {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Welcome " + login_obj.data.f_name,
                    showConfirmButton: false,
                    timer: 1500
                });

                window.sessionStorage.setItem("token", login_obj.data.token);
                window.sessionStorage.setItem("f_name", login_obj.data.f_name);
                window.sessionStorage.setItem("l_name", login_obj.data.l_name);
                window.sessionStorage.setItem("user_id", login_obj.data.user_id);
                setTimeout(function () {
                    setLoadingStatus("hide");
                    window.location.replace("/manager");
                }, 2000);
            } else if (login_obj.status === "error") {
                setLoadingStatus("hide");
                Swal.fire(
                    "Oops...!",
                    "Login Fail",
                    "error"
                );
                window.sessionStorage.setItem("token", null);
                setLoadingStatus("hide");
            }
        }
    }, [login]);

    return (
        <section className="signin-section">
            <Loading loading={loading_status} />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="title mb-30">
                                <h2>Sign in</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-0 auth-row">
                    <div className="col-lg-6">
                        <div className="auth-cover-wrapper bg-primary-100">
                            <div className="auth-cover">
                                <div className="title text-center">
                                    <h1 className="text-primary mb-10">Welcome Back</h1>
                                    <p className="text-medium">
                                        Sign in to your Existing account to continue
                                    </p>
                                </div>
                                <div className="cover-image">
                                    <img src={`${Config().IMAGE_SERVER}admin-images/signin-image.jpg`} alt="" />
                                </div>
                                <div className="shape-image">
                                    <img src={`${Config().IMAGE_SERVER}admin-images/shape.svg`} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="signin-wrapper">
                            <div className="form-wrapper">
                                <h6 className="mb-15">Sign In Form</h6>
                                <p className="text-sm mb-25">
                                    Start creating the best possible user experience for your
                                    customers.
                                </p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="input-style-1">
                                                <label>User Name</label>
                                                <input onChange={e => setUsername(e.target.value)} type="username" placeholder="username" value={username} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-style-1">
                                                <label>Password</label>
                                                <input type="password" onChange={e => setPassword(e.target.value)} placeholder="Password" value={password} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div
                                                className="
                            button-group
                            d-flex
                            justify-content-center
                            flex-wrap
                          "
                                            >
                                                <button type="submit"
                                                    className="
                              main-btn
                              primary-btn
                              btn-hover
                              w-100
                              text-center
                            "
                                                >
                                                    Sign In
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="singin-option pt-40">                           
                                    <p className="text-sm text-medium text-dark text-center">
                                        Don’t have any account yet?
                                        <Link to="/signup">Create an account</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;