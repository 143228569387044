import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchVehiclePaginate, fetchVehiclePaginatePage } from "../../../api/vehicle/Vehicle";
import Layout from "../Layout";

function Vehicle() {
    const title = "Vehicle List";

    const [loading_status, setLoadingStatus] = useState("hide");
    const [data, setData] = useState([]);
    const [data_fetched, setDataFetched] = useState(false);
    const { branchid } = useParams();
    const branch_id = branchid;

    const [items_per_page] = useState(10);
    const [links, setLinks] = useState([]);
    const [page_data, setPageData] = useState([]);

    useEffect(function () {
        if (!data_fetched) {
            setLoadingStatus("show");
            fetchVehiclePaginate(branch_id, items_per_page, setData);
            setDataFetched(true);
        }
    }, [data_fetched]);

    useEffect(function () {
        if (data_fetched) {
            setLoadingStatus("hide");
            if (data.length !== 0) {
                setLinks(data.links);
                setPageData(data.data);
            }
        }
    }, [data]);

    const loadPage = (url) => {
        if (url !== null) {
            setLoadingStatus("show");
            fetchVehiclePaginatePage(url, setData);
        }

    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/vehicle/add", "text": "Add Vehicle" });

    const Content = <div className="row">
        <div className="col-lg-12">
            <div className="card-style mb-30">
                <div className="table-wrapper table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th><h6>Vehicle Number</h6></th>
                                <th><h6>Make Name</h6></th>
                                <th><h6>Model Name</h6></th>
                                <th><h6>Model Year</h6></th>
                                <th><h6>Actions</h6></th>
                            </tr>
                        </thead>
                        <tbody>
                            {page_data.map((object) => (
                                <tr key={object.vehicle_id}>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <p>{object.vehicle_number}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead-text">
                                            <p>{object.make_name}</p>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead-text">
                                            <p>{object.model_name}</p>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead-text">
                                            <p>{object.model_year}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="action">
                                            <Link to={`/manager/branch/${branch_id}/vehicle/edit/${object.vehicle_id}`} >Edit</Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
            <nav aria-label="...">
                <ul className="pagination">
                    {links.map((object) => (
                        <li key={object.label} className={`page-item ${(object.active ? "active" : "")}`}>
                            <div className="page-link" onClick={() => loadPage(object.url)}>{object.label}</div>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default Vehicle;