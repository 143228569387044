import React from "react";
import PropTypes from "prop-types";

export class PrintInvoice extends React.PureComponent {
    render() {
        return (
            <div id="invoice" className="col-lg-12 print">
                <div className="card-style mb-30">
                    <div className="table-responsive">
                        {/* <div className="invoice_header"></div> */}
                        <table className="invoice-table table">
                            <tbody>
                                <tr>
                                    <th className="service">
                                        <div className="address-item">
                                            <div className="invoice-for logo">
                                                <img src={this.props.logo} alt="logo" />
                                            </div>
                                        </div>
                                    </th>
                                    <th className="service">
                                        <div className="address-item">
                                            <p>{this.props.branch_data.branch_name},<br></br>{this.props.branch_data.address} ,<br></br>{this.props.branch_data.city_name} ,<br></br>{this.props.branch_data.mobile1} </p>
                                        </div>
                                    </th>
                                    <th className="service">

                                    </th>
                                </tr>
                            </tbody>
                        </table>
                        <table className="invoice-table table top">
                            <thead>
                                <tr>
                                    <th className="service">
                                        <h6 className="text-bold">Customer:</h6>
                                    </th>
                                    <th className="service">
                                        <h6 className="text-bold">Vehicle:</h6>
                                    </th>
                                    <th className="desc">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="service">
                                        <div className="address-item">
                                            <p className="text-sm">
                                                Name: {this.props.job.f_name} {this.props.job.l_name}<br></br>
                                                Address: {this.props.job.address} {this.props.job.city_name}<br></br>
                                                Mobile: {this.props.job.mobile}<br></br>
                                            </p>
                                        </div>
                                    </th>
                                    <th className="service">
                                        <div className="address-item">
                                            <p className="text-sm">
                                                Vehicle Number: {this.props.job.vehicle_number}<br></br>
                                                Model: {this.props.job.make_name} {this.props.job.model_name} {this.props.job.model_year}<br></br>
                                                Milage : {this.props.job.milage} KM<br></br>
                                            </p>
                                        </div>
                                    </th>
                                    <th className="desc">
                                        <div className="address-item">
                                            <p className="text-sm">
                                                Invoice ID : {this.props.invoice.invoice_id}<br></br>
                                                Payment Status: {this.props.payment_status}<br></br>
                                                Date: {this.props.invoice_date}
                                            </p>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                        <table className="invoice-table table">
                            <thead>
                                <tr className="headings">
                                    <th className="service">
                                        <h6 className="text-sm text-medium">Service / Parts</h6>
                                    </th>
                                    <th className="service ">
                                        <h6 className="text-sm text-medium">Qty</h6>
                                    </th>
                                    <th className="price">
                                        <h6 className="text-sm text-medium">Price</h6>
                                    </th>
                                    <th className="total">
                                        <h6 className="text-sm text-medium">Total</h6>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.props.parts_and_services.map((object, key) => (
                                    object.type === "part"
                                        ?
                                        <tr key={key}>
                                            <td className="min-width">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                        <p>{object.label}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                        <p>{object.quantity}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width price">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                        <p>{parseFloat(object.job_part_price)}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width price">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                        <p>{(object.job_part_price * object.quantity)}</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        <tr key={key}>
                                            <td className="min-width">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                        <p>{object.label}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="min-width price">
                                                <div className="lead">
                                                    <div className="lead-text">
                                                        <p>{object.job_service_price}</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <h6>Total</h6>
                                    </td>
                                    <td className="min-width float-end price">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <p>Rs {this.props.total} </p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="invoice-table note">
                            <tbody>
                                <tr>
                                    <th className="service">
                                        <p>{this.props.invoice_note}</p>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                </div>
            </div>
        );
    }
}

PrintInvoice.propTypes = {
    invoice_note: PropTypes.any,
    logo: PropTypes.any,
    branch_data: PropTypes.any,
    job: PropTypes.any,
    total: PropTypes.any,
    parts_and_services: PropTypes.any,
    invoice: PropTypes.any,
    payment_status: PropTypes.any,
    invoice_date: PropTypes.any,
};

export default PrintInvoice;