import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchBranchData } from "../../../api/branch/Branch";
import Config from "../../../configs/Config";
import PropTypes from "prop-types";

function Sidemenu(props) {
    const [loaded_js, setLoadedJs] = useState(false);
    const [logo, setLogo] = useState(`${Config().IMAGE_SERVER}admin-images/no-image.png`);
    const [branch_data, setBranchData] = useState(null);
    useEffect(() => {
        if (!loaded_js) {
            (function () {
                /* ========= sidebar toggle ======== */
                const sidebarNavWrapper = document.querySelector(".sidebar-nav-wrapper");
                const mainWrapper = document.querySelector(".main-wrapper");
                const menuToggleButton = document.querySelector("#menu-toggle");
                const menuToggleButtonIcon = document.querySelector("#menu-toggle i");
                const overlay = document.querySelector(".overlay");

                menuToggleButton.addEventListener("click", () => {
                    sidebarNavWrapper.classList.toggle("active");
                    overlay.classList.add("active");
                    mainWrapper.classList.toggle("active");

                    if (document.body.clientWidth > 1200) {
                        if (menuToggleButtonIcon.classList.contains("lni-chevron-left")) {
                            menuToggleButtonIcon.classList.remove("lni-chevron-left");
                            menuToggleButtonIcon.classList.add("lni-menu");
                        } else {
                            menuToggleButtonIcon.classList.remove("lni-menu");
                            menuToggleButtonIcon.classList.add("lni-chevron-left");
                        }
                    } else {
                        if (menuToggleButtonIcon.classList.contains("lni-chevron-left")) {
                            menuToggleButtonIcon.classList.remove("lni-chevron-left");
                            menuToggleButtonIcon.classList.add("lni-menu");
                        }
                    }
                });
                overlay.addEventListener("click", () => {
                    sidebarNavWrapper.classList.remove("active");
                    overlay.classList.remove("active");
                    mainWrapper.classList.remove("active");
                });
            })();

            setLoadedJs(true);
        }
    }, [loaded_js]);

    useEffect(function () {
        if (!branch_data) {
            fetchBranchData(props.branch_id, setBranchData);
        }
    }, [props.branch_id, branch_data]);

    useEffect(function () {
        if (branch_data) {
            setLogo(branch_data.logo_image);
        }
    }, [branch_data]);

    return (
        <aside className="sidebar-nav-wrapper">
            <div className="navbar-logo">
                <Link to={`/manager/branch/${props.branch_id}`}>
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <nav className="sidebar-nav">
                <ul>
                    <li className="nav-item">
                        <Link to={"/manager/branch/" + props.branch_id}>
                            <span className="icon">
                                <svg width="22" height="22" viewBox="0 0 22 22">
                                    <path
                                        d="M17.4167 4.58333V6.41667H13.75V4.58333H17.4167ZM8.25 4.58333V10.0833H4.58333V4.58333H8.25ZM17.4167 11.9167V17.4167H13.75V11.9167H17.4167ZM8.25 15.5833V17.4167H4.58333V15.5833H8.25ZM19.25 2.75H11.9167V8.25H19.25V2.75ZM10.0833 2.75H2.75V11.9167H10.0833V2.75ZM19.25 10.0833H11.9167V19.25H19.25V10.0833ZM10.0833 13.75H2.75V19.25H10.0833V13.75Z"
                                    />
                                </svg>
                            </span>
                            <span className="text">Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item nav-item-has-children">
                        <a
                            href="#0"
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#ddmenu_1"
                            aria-controls="ddmenu_1"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="icon">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="22" height="22" viewBox="0 0 1280.000000 1100.000000"
                                    preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,1100.000000) scale(0.100000,-0.100000)"
                                        fill="#000000" stroke="none">
                                        <path d="M3165 10985 c-247 -44 -306 -193 -276 -688 12 -185 11 -200 -6 -234
-27 -55 -57 -71 -183 -97 -96 -20 -151 -39 -315 -114 -110 -50 -227 -100 -260
-111 l-60 -21 -66 73 c-36 39 -104 115 -151 167 -175 197 -297 281 -420 288
-59 4 -75 1 -130 -26 -35 -16 -89 -52 -120 -78 -96 -81 -342 -358 -379 -427
-31 -56 -34 -70 -34 -142 0 -68 5 -88 29 -135 44 -85 111 -163 289 -337 l165
-161 -33 -49 c-106 -158 -215 -392 -286 -612 -23 -73 -52 -145 -65 -160 l-22
-26 -289 0 c-329 -1 -357 -6 -437 -76 -92 -81 -116 -176 -116 -451 1 -280 20
-383 89 -474 45 -61 131 -106 230 -123 40 -6 181 -11 328 -11 l259 0 23 -72
c66 -214 196 -491 282 -603 60 -79 71 -101 64 -128 -4 -12 -109 -123 -234
-247 -253 -251 -289 -300 -298 -398 -3 -33 -3 -76 1 -96 22 -117 375 -491 551
-584 72 -38 168 -37 247 2 79 40 167 117 291 254 59 64 151 164 205 222 l99
104 99 -46 c198 -93 344 -146 548 -199 137 -35 129 -16 116 -269 -13 -281 -13
-516 1 -582 21 -100 70 -173 141 -209 84 -43 161 -53 383 -52 400 1 497 35
572 200 55 120 63 251 37 626 -20 281 -26 263 105 302 165 49 317 107 474 183
86 41 158 73 160 71 23 -32 363 -401 413 -450 130 -123 219 -169 329 -169 118
0 178 39 419 271 250 240 301 363 218 525 -46 90 -133 184 -335 362 -102 90
-188 170 -191 179 -12 31 -4 70 25 123 144 255 186 344 246 515 60 173 75 181
323 176 394 -9 462 -7 515 12 103 35 164 117 185 247 18 112 24 385 11 499
-24 204 -67 284 -179 334 -96 43 -213 53 -464 39 -199 -11 -235 -10 -290 13
-30 12 -55 67 -82 178 -22 91 -42 140 -125 300 -107 209 -133 285 -111 336 6
16 69 82 138 146 162 148 262 261 304 344 29 58 33 75 33 147 0 72 -4 87 -37
156 -31 61 -67 107 -193 242 -223 238 -292 285 -416 286 -57 0 -77 -5 -139
-37 -77 -38 -184 -137 -415 -385 -141 -151 -131 -150 -375 -15 -36 19 -135 57
-220 82 -179 54 -264 91 -302 129 -33 34 -35 45 -27 171 12 184 9 416 -5 487
-31 142 -103 237 -213 278 -94 35 -507 51 -648 25z m461 -2480 c425 -72 715
-337 793 -722 72 -354 -44 -702 -305 -912 -399 -321 -1062 -299 -1427 47 -177
169 -267 390 -268 656 0 138 13 226 51 334 93 271 325 481 627 567 49 14 115
29 148 34 98 14 286 12 381 -4z"/>
                                        <path d="M8765 7710 c-126 -6 -217 -25 -278 -57 -49 -25 -108 -85 -134 -136
-50 -99 -57 -165 -58 -496 l0 -304 -104 -24 c-220 -50 -443 -135 -660 -250
-145 -76 -172 -86 -209 -72 -15 6 -115 99 -222 209 -215 219 -281 269 -381
290 -55 12 -71 12 -127 -3 -97 -26 -184 -90 -357 -265 -175 -177 -224 -239
-269 -332 -27 -56 -31 -76 -31 -146 0 -74 4 -88 38 -156 46 -91 139 -197 348
-397 l155 -149 -71 -144 c-112 -226 -195 -473 -220 -656 -12 -85 -17 -100 -42
-121 l-28 -24 -295 6 c-306 7 -424 0 -514 -29 -186 -61 -232 -182 -230 -609 0
-206 3 -254 22 -339 33 -153 87 -219 223 -268 63 -23 70 -23 359 -19 162 3
355 5 429 5 l134 1 28 -83 c65 -194 151 -379 273 -591 37 -64 66 -127 66 -142
0 -21 -13 -38 -52 -70 -81 -66 -393 -361 -479 -451 -92 -97 -133 -162 -154
-246 -27 -106 3 -196 113 -333 102 -128 395 -394 482 -439 124 -63 255 -43
410 63 77 53 196 175 382 390 149 172 185 207 207 207 14 0 121 -47 236 -104
193 -96 382 -171 528 -212 50 -13 56 -18 53 -37 -11 -52 -29 -605 -23 -708 9
-171 45 -273 123 -353 81 -83 161 -101 489 -112 392 -12 547 35 618 189 56
121 64 234 42 596 -19 314 -18 326 40 354 19 9 72 26 117 37 183 46 382 119
558 205 183 88 236 64 485 -225 208 -241 296 -308 421 -318 85 -6 141 15 249
95 129 96 368 339 437 443 75 115 92 163 86 244 -11 134 -113 266 -395 514
-81 71 -149 139 -162 163 -34 60 -25 110 37 212 80 132 99 176 152 355 54 185
87 268 126 319 46 61 58 63 285 54 388 -16 550 17 656 132 89 97 102 148 115
479 10 239 10 276 -6 354 -35 180 -102 251 -277 296 -80 20 -97 20 -339 13
-374 -12 -400 -12 -437 8 -43 23 -64 67 -102 212 -26 100 -45 145 -117 278
-108 197 -143 285 -144 355 0 62 -2 60 204 243 173 154 264 259 319 368 40 81
42 89 42 175 0 81 -3 97 -30 150 -34 67 -141 192 -320 372 -177 178 -259 226
-384 226 -141 -1 -287 -110 -551 -412 -135 -155 -167 -180 -229 -180 -48 0
-70 10 -189 88 -84 55 -144 81 -432 187 -69 25 -152 60 -185 78 l-60 32 0 55
c0 30 6 145 13 254 23 334 -8 507 -106 609 -99 103 -231 121 -697 97z m359
-2704 c55 -14 148 -50 210 -79 540 -262 839 -851 686 -1352 -81 -267 -302
-521 -563 -649 -152 -74 -233 -91 -427 -90 -150 1 -173 3 -255 28 -192 58
-351 144 -487 265 -283 251 -438 566 -438 891 0 259 104 498 306 705 151 155
344 261 549 301 107 20 297 12 419 -20z"/>
                                    </g>
                                </svg>

                            </span>
                            <span className="text"> Parts </span>
                        </a>
                        <ul id="ddmenu_1" className="collapse dropdown-nav">
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/parts/list"}>Parts List</Link>
                            </li>
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/parts/add"}>Add Parts </Link>
                            </li>
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/partsellinvoice/add"}>Add Part Invoice</Link>
                            </li>
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/partsellinvoice/list"}>Part Invoice List</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item nav-item-has-children">
                        <a
                            href="#0"
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#ddmenu_2"
                            aria-controls="ddmenu_2"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="icon">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="22" height="22" viewBox="0 0 1280.000000 1280.000000"
                                    preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                                        fill="#000000" stroke="none">
                                        <path d="M8235 9529 c-1705 -1644 -3102 -2988 -3105 -2987 -3 2 -107 108 -230
236 -244 253 -231 245 -325 221 -24 -6 -53 -21 -65 -34 -23 -25 -24 -29 -49
-305 -20 -211 -81 -363 -196 -488 -114 -123 -240 -167 -413 -143 -124 17 -209
40 -518 141 -396 130 -570 171 -800 192 -508 45 -966 -156 -1371 -602 -354
-389 -497 -730 -480 -1145 11 -254 54 -435 227 -959 l119 -359 -48 -61 c-150
-191 -249 -399 -298 -626 -25 -120 -25 -410 0 -530 148 -693 798 -1329 1477
-1446 141 -24 390 -15 525 19 189 48 376 134 516 239 44 32 83 58 88 58 5 0
145 -51 312 -114 606 -226 814 -279 1104 -280 227 0 391 38 600 139 308 149
661 457 852 745 193 289 278 632 244 990 -18 186 -61 383 -155 713 -47 164
-94 345 -106 403 -35 181 -24 320 34 418 38 64 146 158 236 205 119 62 203 80
444 95 207 12 209 13 232 39 13 15 28 44 33 65 24 85 29 78 -215 331 l-226
234 64 62 c34 34 380 368 768 741 l705 679 7 245 c5 135 11 313 14 395 l7 150
462 444 c254 245 470 449 481 453 10 5 205 60 431 122 313 87 420 120 441 138
16 14 187 176 379 362 l350 337 -4 52 c-2 29 -10 179 -18 334 l-15 282 153
148 c83 81 215 210 292 285 l141 137 214 -70 c118 -38 289 -93 380 -122 l166
-53 182 175 182 176 29 740 c17 407 32 777 35 822 l5 82 -226 236 -226 235
-371 -1 -371 -1 -3100 -2989z m-5937 -6600 c422 -96 734 -529 682 -946 -39
-314 -246 -553 -545 -629 -92 -23 -274 -24 -368 -1 -269 66 -499 256 -616 509
-52 112 -71 192 -78 323 -10 221 61 409 213 560 184 183 434 248 712 184z"/>
                                    </g>
                                </svg>


                            </span>
                            <span className="text"> Services </span>
                        </a>
                        <ul id="ddmenu_2" className="collapse dropdown-nav">
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/service/list"}>Service List</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item nav-item-has-children">
                        <a
                            href="#0"
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#ddmenu_3"
                            aria-controls="ddmenu_3"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="icon">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="22" height="22" viewBox="0 0 1280.000000 640.000000"
                                    preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
                                        fill="#000000" stroke="none">
                                        <path d="M6240 5979 c-453 -44 -885 -287 -1161 -654 -170 -226 -272 -474 -315
-769 -21 -142 -14 -395 15 -538 63 -311 212 -595 430 -821 326 -338 726 -509
1191 -509 465 0 865 171 1191 509 176 183 299 387 379 631 60 187 75 286 75
512 -1 206 -11 289 -59 455 -168 581 -652 1031 -1243 1154 -180 38 -330 47
-503 30z"/>
                                        <path d="M2295 4779 c-102 -10 -231 -41 -328 -79 -365 -140 -651 -443 -771
-815 -80 -250 -80 -542 1 -790 92 -281 270 -517 515 -683 130 -89 332 -168
499 -197 118 -21 320 -21 438 0 536 93 960 514 1056 1051 21 115 21 332 1 446
-104 580 -567 1008 -1155 1067 -114 12 -140 12 -256 0z"/>
                                        <path d="M10235 4779 c-146 -15 -322 -67 -453 -133 -101 -51 -240 -153 -327
-241 -366 -365 -477 -908 -287 -1394 163 -414 540 -719 983 -796 118 -21 320
-21 438 0 166 29 370 109 501 198 440 298 656 832 546 1352 -18 86 -69 231
-106 306 -54 107 -155 245 -245 334 -215 216 -486 343 -794 374 -114 12 -140
12 -256 0z"/>
                                        <path d="M5675 2573 c-22 -2 -92 -13 -155 -24 -796 -139 -1574 -758 -2000
-1594 -84 -164 -210 -483 -210 -532 0 -11 520 -13 3090 -13 2570 0 3090 2
3090 13 0 49 -126 368 -210 532 -352 689 -947 1240 -1610 1488 -123 46 -247
80 -390 108 -112 23 -131 23 -840 25 -399 0 -743 -1 -765 -3z"/>
                                        <path d="M1820 2103 c-279 -47 -520 -135 -740 -270 -411 -253 -762 -655 -960
-1101 -39 -87 -120 -302 -120 -318 0 -2 702 -4 1560 -4 1240 0 1561 3 1564 12
2 7 20 67 41 133 121 388 351 806 616 1122 44 51 79 95 79 98 0 12 -151 101
-263 155 -146 70 -286 120 -428 151 -102 23 -114 24 -714 25 -335 1 -621 0
-635 -3z"/>
                                        <path d="M9760 2104 c-223 -38 -366 -82 -546 -169 -122 -58 -274 -147 -274
-160 0 -4 26 -36 58 -73 298 -348 557 -836 678 -1279 3 -10 324 -13 1564 -13
858 0 1560 2 1560 4 0 2 -16 51 -36 108 -132 379 -353 723 -641 998 -297 284
-645 477 -1013 562 -92 21 -116 22 -710 24 -338 1 -626 0 -640 -2z"/>
                                    </g>
                                </svg>

                            </span>
                            <span className="text"> Customer </span>
                        </a>
                        <ul id="ddmenu_3" className="collapse dropdown-nav">
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/customer/list"}>Customer List</Link>
                            </li>
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/customer/add"}>Add Customer </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item nav-item-has-children">
                        <a
                            href="#0"
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#ddmenu_4"
                            aria-controls="ddmenu_4"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="icon">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="22" height="22" viewBox="0 0 1280.000000 640.000000"
                                    preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
                                        fill="#000000" stroke="none">
                                        <path d="M3565 5336 c-106 -30 -101 -26 -108 -111 -4 -42 -9 -80 -12 -85 -6
-10 -246 -105 -590 -234 -448 -167 -1052 -415 -1173 -483 -78 -43 -193 -91
-250 -104 -23 -5 -98 -14 -165 -19 -67 -6 -167 -19 -222 -30 -154 -31 -340
-49 -563 -57 l-203 -6 -43 -66 c-59 -91 -60 -95 -26 -130 37 -37 38 -65 3
-150 -25 -62 -27 -78 -31 -256 l-4 -190 -38 -32 c-91 -78 -133 -209 -134 -418
0 -194 11 -396 26 -482 13 -71 14 -74 72 -122 69 -58 130 -129 158 -184 64
-126 534 -211 1384 -250 l92 -4 -6 119 c-6 142 8 256 49 383 112 352 394 622
756 722 90 26 112 28 278 28 165 0 188 -2 278 -27 201 -56 361 -152 504 -302
140 -145 222 -293 274 -492 21 -79 24 -109 23 -279 -1 -127 -6 -214 -16 -263
l-15 -73 3006 7 c1653 4 3007 8 3009 9 1 1 -8 37 -20 81 -19 67 -22 105 -22
259 -1 166 1 187 27 279 117 421 467 736 885 797 119 17 325 7 432 -21 239
-63 453 -205 601 -399 70 -92 154 -267 185 -386 24 -88 27 -119 27 -260 1
-116 -4 -181 -16 -234 -10 -41 -16 -75 -15 -76 2 -1 62 2 133 6 266 16 458 45
525 79 48 24 97 81 127 146 l24 52 -16 157 c-15 152 -15 163 4 284 63 388 50
680 -35 802 -134 193 -526 336 -1429 519 -737 149 -1322 209 -2033 210 -228 0
-226 0 -347 85 -187 131 -1045 607 -1471 815 -383 187 -788 281 -1439 332
-208 17 -1106 16 -1400 0 -121 -7 -314 -19 -430 -27 -302 -22 -286 -22 -341
10 -140 81 -187 94 -269 71z m1885 -333 c6 -37 38 -238 71 -446 32 -209 66
-422 75 -474 9 -52 15 -96 13 -97 -11 -9 -1699 29 -1951 44 -206 13 -417 36
-485 54 -98 26 -198 119 -249 231 -35 75 -36 172 -5 255 17 45 30 61 68 86 83
54 135 80 253 127 341 136 858 230 1460 267 269 16 270 16 511 18 l227 2 12
-67z m630 47 c264 -18 777 -110 1029 -186 186 -56 445 -188 756 -387 211 -134
274 -181 250 -185 -75 -12 -133 -50 -162 -106 -19 -35 -21 -136 -4 -179 l11
-27 -907 2 -906 3 -59 160 c-110 302 -298 878 -298 916 0 6 95 2 290 -11z"/>
                                        <path d="M2633 3125 c-223 -40 -410 -141 -568 -306 -132 -138 -213 -283 -262
-467 -22 -83 -26 -119 -26 -247 -1 -169 10 -236 65 -382 87 -230 271 -436 493
-551 85 -44 178 -78 271 -98 107 -23 312 -23 419 1 392 84 699 375 802 761 23
86 26 120 27 254 1 158 -5 199 -46 330 -98 310 -355 567 -668 669 -150 50
-354 64 -507 36z m350 -301 c249 -56 457 -247 543 -499 25 -72 28 -95 28 -220
1 -153 -15 -228 -74 -345 -94 -186 -283 -337 -485 -386 -96 -24 -268 -24 -360
0 -320 84 -544 355 -562 681 -20 359 209 673 558 765 94 24 253 26 352 4z"/>
                                        <path d="M2600 2697 c-36 -13 -85 -36 -109 -51 l-44 -28 116 -115 c81 -82 120
-114 131 -110 14 6 16 29 16 167 0 186 6 178 -110 137z"/>
                                        <path d="M2920 2561 c0 -139 2 -162 16 -168 11 -4 50 28 130 108 l115 114 -28
22 c-34 28 -138 70 -193 79 l-40 7 0 -162z"/>
                                        <path d="M2282 2448 c-28 -36 -92 -191 -92 -225 0 -10 34 -13 165 -13 151 0
165 1 165 18 0 15 -206 232 -221 232 -4 0 -11 -6 -17 -12z"/>
                                        <path d="M3222 2351 c-62 -59 -112 -115 -112 -124 0 -15 17 -17 165 -17 131 0
165 3 165 13 0 40 -69 205 -95 227 -7 6 -48 -27 -123 -99z"/>
                                        <path d="M2781 2332 c-12 -22 11 -62 34 -62 8 0 21 10 29 22 20 28 4 58 -29
58 -13 0 -29 -8 -34 -18z"/>
                                        <path d="M2749 2161 c-32 -33 -37 -67 -14 -110 29 -57 104 -64 151 -14 53 57
9 153 -71 153 -27 0 -44 -8 -66 -29z"/>
                                        <path d="M2570 2125 c-26 -32 13 -81 48 -59 24 16 27 45 6 61 -23 17 -39 16
-54 -2z"/>
                                        <path d="M3006 2124 c-20 -19 -20 -38 -2 -54 23 -19 61 -8 64 18 7 44 -32 67
-62 36z"/>
                                        <path d="M2190 1975 c0 -29 41 -140 72 -194 l31 -53 117 117 c71 71 116 123
113 131 -4 11 -40 14 -169 14 -141 0 -164 -2 -164 -15z"/>
                                        <path d="M3110 1972 c0 -9 51 -68 114 -131 l114 -114 31 54 c30 51 71 165 71
195 0 11 -31 14 -165 14 -151 0 -165 -1 -165 -18z"/>
                                        <path d="M2780 1901 c-7 -15 -5 -24 8 -41 32 -40 85 -4 62 41 -14 25 -56 25
-70 0z"/>
                                        <path d="M2562 1697 c-61 -62 -112 -115 -112 -119 0 -18 208 -108 249 -108 7
0 11 54 11 164 0 140 -2 165 -16 170 -9 3 -16 6 -17 6 -1 0 -53 -51 -115 -113z"/>
                                        <path d="M2933 1803 c-15 -6 -19 -333 -4 -333 46 0 251 88 251 108 0 9 -223
232 -230 231 -3 0 -11 -3 -17 -6z"/>
                                        <path d="M10700 3119 c-390 -84 -696 -376 -797 -759 -31 -117 -41 -292 -24
-411 33 -227 150 -453 318 -609 267 -250 643 -344 993 -249 117 32 283 118
380 196 487 396 518 1128 67 1560 -97 93 -166 140 -290 198 -137 64 -235 86
-407 91 -120 3 -162 0 -240 -17z m445 -313 c238 -81 409 -258 486 -506 30 -96
33 -289 5 -388 -110 -400 -513 -637 -911 -536 -149 38 -313 147 -402 267 -176
238 -203 533 -71 797 34 69 60 103 138 180 77 78 111 104 181 139 129 65 207
81 364 77 109 -3 143 -7 210 -30z"/>
                                        <path d="M10703 2700 c-54 -19 -153 -71 -153 -80 0 -3 51 -57 114 -119 80 -80
119 -112 130 -108 14 5 16 29 16 167 l0 160 -27 -1 c-16 0 -52 -9 -80 -19z"/>
                                        <path d="M11020 2561 c0 -139 2 -162 16 -168 22 -8 247 216 234 232 -17 20
-163 84 -207 91 l-43 7 0 -162z"/>
                                        <path d="M10366 2424 c-29 -44 -76 -165 -76 -194 0 -19 7 -20 165 -20 126 0
165 3 165 13 0 7 -51 63 -114 126 l-114 114 -26 -39z"/>
                                        <path d="M11313 2348 c-61 -62 -109 -119 -106 -125 6 -15 333 -19 333 -4 0 45
-88 241 -108 241 -4 0 -57 -51 -119 -112z"/>
                                        <path d="M10882 2338 c-17 -17 -15 -32 7 -52 16 -14 23 -15 41 -6 31 17 24 64
-10 68 -14 2 -31 -3 -38 -10z"/>
                                        <path d="M10846 2159 c-68 -81 17 -194 110 -144 89 48 56 175 -46 175 -30 0
-44 -6 -64 -31z"/>
                                        <path d="M10670 2126 c-19 -23 -8 -61 18 -64 44 -7 67 32 36 62 -19 20 -38 20
-54 2z"/>
                                        <path d="M11106 2127 c-21 -16 -18 -45 7 -61 37 -23 77 35 41 61 -10 7 -21 13
-24 13 -3 0 -14 -6 -24 -13z"/>
                                        <path d="M10290 1970 c0 -29 43 -141 74 -195 l28 -48 116 116 c81 81 113 120
109 131 -6 14 -29 16 -167 16 -152 0 -160 -1 -160 -20z"/>
                                        <path d="M11207 1978 c-3 -7 47 -66 111 -130 l116 -118 27 43 c27 44 79 177
79 203 0 12 -28 14 -164 14 -122 0 -166 -3 -169 -12z"/>
                                        <path d="M10881 1901 c-14 -25 -5 -48 20 -56 27 -9 51 13 47 44 -4 34 -51 43
-67 12z"/>
                                        <path d="M10662 1697 c-61 -62 -112 -115 -112 -119 0 -20 201 -108 247 -108
10 0 13 34 13 164 0 140 -2 165 -16 170 -9 3 -16 6 -17 6 -1 0 -53 -51 -115
-113z"/>
                                        <path d="M11033 1803 c-10 -3 -13 -47 -13 -169 0 -90 4 -164 8 -164 36 0 186
61 239 98 16 10 -216 242 -234 235z"/>
                                    </g>
                                </svg>
                            </span>
                            <span className="text"> Vehicle </span>
                        </a>
                        <ul id="ddmenu_4" className="collapse dropdown-nav">
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/vehicle/list"}>Vehicle List</Link>
                            </li>
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/vehicle/add"}>Add Vehicle </Link>
                            </li>
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/model/add"}>Add Model </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item nav-item-has-children">
                        <a
                            href="#0"
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#ddmenu_5"
                            aria-controls="ddmenu_5"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="icon">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="22" height="22" viewBox="0 0 1280.000000 603.000000"
                                    preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,603.000000) scale(0.100000,-0.100000)"
                                        fill="#000000" stroke="none">
                                        <path d="M6113 5641 c-15 -9 -48 -23 -73 -30 -25 -7 -65 -26 -89 -42 -25 -16
-49 -29 -55 -29 -37 0 -138 -56 -195 -107 -93 -84 -102 -95 -140 -159 -19 -33
-48 -84 -66 -114 -75 -127 -95 -214 -95 -417 l0 -142 -42 -23 c-77 -43 -97
-126 -58 -250 11 -34 20 -74 20 -88 1 -14 15 -55 33 -92 25 -52 44 -76 87
-107 30 -23 70 -44 88 -47 42 -8 67 -46 82 -124 6 -30 18 -75 28 -100 11 -30
17 -80 17 -150 l2 -105 -39 -42 c-25 -28 -40 -56 -44 -83 -5 -33 -15 -46 -50
-71 -23 -17 -56 -53 -72 -79 -36 -57 -198 -195 -262 -222 -25 -11 -87 -40
-139 -65 -52 -25 -124 -56 -160 -70 -36 -13 -109 -41 -161 -62 -53 -21 -111
-42 -128 -46 -18 -4 -81 -27 -140 -51 -59 -25 -136 -51 -171 -59 -35 -9 -84
-24 -108 -35 -24 -11 -61 -22 -81 -26 -20 -3 -53 -15 -72 -26 -43 -23 -97 -99
-106 -148 -3 -19 -23 -87 -43 -150 -38 -118 -61 -211 -82 -335 -6 -38 -21
-110 -34 -160 -12 -49 -30 -126 -40 -170 -9 -44 -21 -93 -25 -110 -27 -96 -32
-179 -19 -288 13 -108 13 -110 -10 -151 -13 -23 -34 -62 -47 -89 -13 -26 -31
-47 -39 -47 -8 0 -29 30 -48 67 -51 102 -120 195 -225 302 -119 121 -189 207
-237 294 -41 74 -75 105 -148 131 -54 20 -66 37 -65 92 0 23 -7 55 -16 73 -11
21 -15 43 -11 65 5 26 1 39 -20 65 -44 51 -79 64 -174 63 -91 -1 -107 4 -163
54 -52 46 -91 51 -217 25 -58 -11 -135 -26 -169 -31 -35 -6 -76 -16 -90 -21
-49 -19 -246 -57 -359 -69 -83 -9 -127 -10 -175 -1 -36 6 -80 12 -99 12 -38 1
-28 5 43 14 39 5 49 10 54 30 3 13 15 25 25 28 17 5 20 14 19 54 0 26 2 86 6
133 5 69 2 105 -17 195 -22 102 -24 131 -23 382 1 149 5 324 9 390 l8 118 13
-55 c32 -134 58 -441 56 -645 -3 -235 -5 -486 -4 -534 1 -25 10 -42 37 -67 20
-18 46 -34 58 -34 61 -5 107 2 128 19 26 20 23 31 -21 98 l-22 32 33 13 32 13
3 365 c2 236 8 418 18 515 9 83 16 185 16 228 0 62 3 79 16 84 13 5 15 19 13
77 -3 63 -1 76 25 118 55 93 61 137 60 428 0 278 -15 469 -38 505 -9 14 -44
30 -107 49 -120 35 -165 59 -189 99 -24 38 -25 60 -6 90 16 24 18 58 10 178
-5 70 -8 79 -38 108 -83 80 -263 29 -281 -80 -11 -62 13 -188 42 -226 32 -42
26 -80 -21 -138 -26 -31 -51 -48 -108 -70 -122 -47 -145 -61 -157 -97 -17 -55
-51 -302 -51 -375 0 -76 26 -403 36 -443 3 -14 19 -47 35 -73 29 -47 29 -50
19 -110 -10 -51 -9 -63 3 -72 9 -6 22 -50 32 -100 9 -50 27 -133 40 -185 29
-116 40 -223 60 -590 17 -302 19 -310 67 -310 23 0 23 -2 -3 -52 -12 -23 -19
-53 -17 -69 3 -15 1 -26 -3 -23 -20 12 -220 32 -271 27 -31 -3 -85 0 -118 6
-53 11 -65 10 -86 -3 -27 -18 -39 -54 -29 -86 8 -26 70 -55 135 -64 42 -6 48
-10 65 -49 25 -53 101 -106 210 -146 44 -16 125 -49 180 -74 55 -24 118 -48
140 -52 22 -4 67 -18 100 -32 65 -28 210 -47 430 -57 162 -7 164 -8 158 -57
-5 -32 -1 -44 17 -64 12 -13 33 -26 46 -29 28 -7 31 -29 9 -83 -29 -75 -75
-257 -75 -297 0 -23 5 -59 11 -81 14 -51 112 -253 136 -281 10 -12 56 -47 103
-79 120 -80 186 -147 194 -196 5 -29 24 -58 77 -118 39 -44 76 -82 84 -84 7
-3 34 -42 61 -86 l48 -81 769 0 c721 0 771 1 783 18 13 16 13 16 14 0 0 -17
93 -18 1810 -18 1717 0 1810 1 1810 18 1 16 1 16 14 0 12 -17 61 -18 780 -18
l766 0 45 70 c25 39 82 107 126 153 78 78 109 125 109 164 1 26 94 117 175
170 109 71 133 99 185 208 93 195 96 234 34 435 -52 167 -53 175 -16 188 43
15 66 53 58 96 -6 31 -4 34 21 41 15 3 68 8 118 10 244 11 367 27 457 62 48
18 97 33 109 33 11 0 60 18 108 41 47 22 121 54 164 69 90 33 194 89 220 118
10 11 22 33 26 48 7 26 14 30 70 41 107 22 141 51 127 109 -4 13 -17 31 -30
40 -21 13 -33 14 -86 3 -33 -6 -86 -9 -118 -6 -58 6 -154 -3 -277 -28 -157
-30 -257 -37 -360 -25 -120 14 -278 44 -360 69 -33 10 -82 21 -110 25 -27 4
-94 16 -148 28 -122 25 -172 20 -213 -23 -47 -49 -73 -58 -167 -57 -75 1 -90
-2 -131 -26 -53 -31 -70 -57 -63 -99 2 -17 -3 -47 -11 -67 -8 -19 -16 -57 -18
-83 -3 -54 -17 -70 -74 -87 -51 -14 -107 -65 -136 -124 -39 -76 -120 -178
-231 -292 -110 -112 -198 -230 -237 -317 -15 -34 -31 -57 -40 -57 -9 0 -27 23
-42 53 -15 28 -35 64 -46 79 -23 34 -24 56 -8 173 14 102 6 163 -50 395 -54
226 -70 299 -80 373 -6 39 -15 86 -19 102 -5 17 -16 53 -24 80 -8 28 -28 95
-45 150 -17 55 -34 113 -37 128 -8 38 -93 124 -130 132 -16 3 -57 15 -90 27
-33 11 -96 30 -140 43 -44 12 -118 38 -165 57 -47 19 -130 50 -185 68 -55 18
-112 39 -127 46 -15 8 -60 26 -100 40 -40 15 -118 48 -173 74 -55 27 -120 57
-145 68 -58 24 -187 127 -231 183 -19 24 -59 69 -90 99 -42 42 -58 66 -63 95
-5 28 -20 52 -47 77 l-40 38 4 107 c4 104 9 132 61 297 l21 69 47 12 c74 18
150 89 182 170 14 36 26 77 26 90 0 14 7 42 15 62 9 21 18 63 22 94 8 76 -20
136 -77 168 l-40 22 0 137 c0 203 -15 268 -102 432 -66 123 -80 143 -170 230
-91 90 -102 98 -179 124 -45 15 -96 38 -113 51 -17 13 -54 30 -83 38 -28 9
-62 22 -75 30 -35 22 -287 20 -325 -2z"/>
                                        <path d="M10855 5124 c-55 -20 -102 -69 -110 -113 -10 -60 12 -175 41 -218 32
-48 28 -88 -15 -141 -24 -29 -55 -47 -139 -82 -131 -56 -132 -57 -163 -264
-23 -159 -23 -226 1 -506 11 -123 15 -141 45 -197 33 -60 34 -64 24 -124 -8
-50 -7 -63 5 -73 8 -6 22 -49 31 -96 9 -47 28 -130 41 -185 13 -55 29 -149 34
-210 9 -95 40 -608 40 -657 0 -10 14 -21 35 -28 19 -6 35 -14 35 -16 0 -3 -9
-22 -20 -44 -37 -72 -22 -101 50 -99 68 2 102 12 114 34 6 11 19 26 29 33 12
10 18 26 17 55 -1 23 2 83 6 133 6 76 3 106 -17 195 -23 100 -24 117 -21 424
1 176 6 352 10 390 l8 70 12 -55 c6 -30 22 -140 33 -245 24 -207 28 -441 15
-707 -5 -86 -5 -165 -1 -175 4 -10 7 -32 6 -49 -2 -25 6 -39 36 -68 34 -32 44
-36 90 -36 58 0 99 15 108 38 3 9 -8 37 -24 64 l-31 49 28 11 c15 7 29 13 31
15 2 1 5 165 7 365 2 234 8 416 18 513 9 83 16 187 16 233 0 68 3 82 16 82 13
0 15 13 13 78 -3 69 0 81 24 117 14 22 34 67 43 100 39 141 21 802 -23 846 -5
5 -49 21 -98 35 -110 32 -155 54 -189 94 -30 36 -32 59 -11 99 17 35 18 165 0
248 -13 58 -124 95 -200 67z"/>
                                    </g>
                                </svg>

                            </span>
                            <span className="text"> Jobs </span>
                        </a>
                        <ul id="ddmenu_5" className="collapse dropdown-nav">
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/job/add"}>Add Job</Link>
                            </li>
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/job/list"}>Job List</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item nav-item-has-children">
                        <a
                            href="#0"
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#ddmenu_6"
                            aria-controls="ddmenu_6"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="icon">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="22" height="22" viewBox="0 0 1280.000000 872.000000"
                                    preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,872.000000) scale(0.100000,-0.100000)"
                                        fill="#000000" stroke="none">
                                        <path d="M10330 8644 c-275 -139 -570 -259 -1250 -511 -418 -154 -729 -276
-923 -362 -163 -72 -417 -199 -417 -208 0 -3 42 -49 93 -102 50 -53 183 -192
295 -308 l202 -211 -132 -94 c-1591 -1135 -3704 -2078 -5429 -2423 -1141 -228
-2025 -203 -2598 72 -62 30 -114 53 -116 51 -10 -13 -37 -157 -46 -244 -18
-170 10 -338 82 -492 171 -368 585 -621 1179 -721 227 -39 329 -46 645 -45
392 0 659 28 1076 110 1223 240 2401 785 3639 1684 625 454 1331 1064 1855
1602 l167 171 349 -349 c191 -191 350 -346 352 -344 2 3 252 624 556 1380 305
756 558 1383 563 1393 5 10 8 20 6 22 -2 1 -68 -30 -148 -71z"/>
                                        <path d="M11995 6921 c-40 -17 -59 -50 -63 -111 -5 -71 2 -104 28 -130 29 -29
74 -25 112 9 32 29 33 32 33 110 0 67 -3 84 -20 100 -24 24 -63 33 -90 22z
m44 -55 c7 -9 11 -41 9 -83 -3 -65 -4 -68 -28 -68 -21 0 -26 6 -32 40 -6 37
-2 99 9 118 7 12 30 8 42 -7z"/>
                                        <path d="M12185 6916 c-41 -18 -55 -53 -55 -137 0 -61 3 -73 25 -94 74 -75
169 22 149 152 -11 75 -58 106 -119 79z m55 -55 c11 -21 14 -110 4 -135 -10
-25 -43 -19 -54 10 -22 57 -7 144 25 144 8 0 19 -9 25 -19z"/>
                                        <path d="M12445 6808 c-49 -68 -92 -129 -98 -135 -7 -10 -4 -13 11 -13 15 0
45 33 106 119 95 132 106 151 83 151 -8 0 -54 -55 -102 -122z"/>
                                        <path d="M11783 6899 c-37 -23 -49 -68 -14 -49 11 5 22 10 25 10 3 0 6 -33 6
-74 0 -70 -1 -75 -22 -78 -13 -2 -24 -11 -26 -21 -3 -16 4 -18 75 -15 65 3 78
6 81 21 3 12 -3 17 -22 17 l-26 0 0 105 c0 102 -1 105 -22 105 -13 0 -38 -10
-55 -21z"/>
                                        <path d="M12336 6898 c-18 -26 -20 -61 -6 -89 15 -28 74 -26 94 3 19 27 21 79
4 96 -21 21 -75 15 -92 -10z m60 -32 c6 -26 -1 -46 -17 -46 -5 0 -9 16 -9 35
0 40 17 47 26 11z"/>
                                        <path d="M12490 6780 c-28 -28 -27 -84 2 -104 31 -22 45 -20 73 9 45 44 25
115 -31 115 -13 0 -33 -9 -44 -20z m58 -59 c-5 -25 -27 -23 -34 3 -9 36 5 54
23 30 7 -10 12 -25 11 -33z"/>
                                        <path d="M11842 6357 l-102 -102 0 -3128 0 -3127 308 0 307 0 108 108 107 107
0 3123 0 3122 -313 0 -312 0 -103 -103z"/>
                                        <path d="M10671 6294 c-25 -21 -31 -34 -31 -64 0 -53 22 -73 77 -68 48 4 56
-9 24 -41 -15 -15 -28 -19 -56 -14 -34 6 -37 4 -33 -18 2 -20 10 -25 40 -27
75 -6 118 46 118 144 0 47 -5 68 -21 88 -28 35 -76 35 -118 0z m67 -22 c21
-14 20 -66 -1 -70 -37 -8 -62 39 -35 66 14 14 19 14 36 4z"/>
                                        <path d="M10877 6299 c-51 -40 -60 -168 -14 -216 28 -31 78 -30 114 1 25 22
28 31 31 103 3 68 1 81 -18 106 -27 33 -75 36 -113 6z m80 -82 c3 -20 2 -52
-1 -71 -6 -29 -11 -36 -30 -36 -30 0 -36 16 -36 90 0 46 4 61 18 69 23 13 41
-5 49 -52z"/>
                                        <path d="M11050 6300 c-29 -29 -27 -85 4 -105 28 -19 69 -7 85 24 37 69 -35
135 -89 81z m50 -51 c0 -20 -4 -29 -12 -27 -15 5 -22 33 -14 54 9 24 26 6 26
-27z"/>
                                        <path d="M11150 6203 c-108 -149 -105 -143 -82 -143 12 0 47 40 103 118 107
146 104 142 82 142 -12 0 -48 -41 -103 -117z"/>
                                        <path d="M11215 6188 c-29 -16 -35 -28 -35 -70 0 -39 19 -58 58 -58 49 0 78
97 37 126 -23 17 -34 17 -60 2z m35 -64 c0 -45 -24 -42 -28 4 -2 24 1 32 12
32 12 0 16 -10 16 -36z"/>
                                        <path d="M10674 5748 l-104 -102 0 -2823 0 -2823 308 0 307 0 108 108 107 107
-2 2815 -3 2815 -309 3 -309 2 -103 -102z"/>
                                        <path d="M10082 5712 c-20 -17 -182 -237 -182 -247 0 -3 8 -5 18 -5 16 0 36
25 166 207 37 51 36 78 -2 45z"/>
                                        <path d="M9512 5700 c-26 -25 -29 -72 -6 -95 15 -15 15 -18 -5 -39 -48 -51
-13 -106 67 -106 77 0 120 72 71 121 -19 19 -19 22 -5 38 23 25 21 78 -3 91
-33 17 -95 12 -119 -10z m83 -30 c7 -12 -3 -50 -14 -50 -19 0 -44 28 -38 44 7
18 42 22 52 6z m-11 -113 c21 -16 18 -50 -5 -55 -27 -5 -51 26 -39 49 13 22
20 24 44 6z"/>
                                        <path d="M9713 5696 c-25 -22 -28 -31 -31 -103 -3 -68 -1 -81 18 -105 41 -53
124 -32 150 38 24 63 4 173 -35 188 -34 13 -75 5 -102 -18z m71 -32 c21 -20
22 -136 3 -153 -36 -30 -73 78 -48 142 12 31 23 34 45 11z"/>
                                        <path d="M9895 5700 c-21 -24 -25 -84 -7 -102 18 -18 73 -14 88 7 17 23 18 87
2 103 -19 19 -63 14 -83 -8z m53 -49 c3 -24 0 -32 -10 -29 -7 3 -14 19 -16 37
-3 24 0 32 10 29 7 -3 14 -19 16 -37z"/>
                                        <path d="M10053 5585 c-51 -36 -32 -125 26 -125 39 0 61 28 61 78 0 58 -41 80
-87 47z m42 -58 c0 -39 -19 -38 -23 2 -3 24 0 32 10 29 7 -3 13 -17 13 -31z"/>
                                        <path d="M9492 5157 l-102 -102 0 -2528 0 -2527 308 0 308 0 107 107 107 107
0 2523 0 2523 -313 0 -312 0 -103 -103z"/>
                                        <path d="M8520 5092 c-48 -48 -53 -169 -9 -213 12 -12 33 -19 58 -19 32 0 44
6 65 31 23 27 26 39 26 105 0 66 -3 78 -25 99 -34 35 -78 33 -115 -3z m80 -48
c30 -78 -15 -190 -50 -125 -11 22 -14 134 -3 144 15 16 44 6 53 -19z"/>
                                        <path d="M8706 5099 c-48 -38 -28 -119 29 -119 28 0 65 43 65 76 0 26 -32 64
-54 64 -7 0 -25 -9 -40 -21z m46 -36 c2 -10 2 -26 0 -36 -6 -25 -32 -2 -32 29
0 28 27 33 32 7z"/>
                                        <path d="M8796 4993 c-89 -121 -106 -155 -68 -134 16 9 192 242 192 255 0 3
-7 6 -15 6 -8 0 -58 -57 -109 -127z"/>
                                        <path d="M8292 5088 c3 -20 10 -23 55 -26 l52 -3 -35 -77 c-59 -127 -58 -122
-25 -122 27 0 31 7 75 108 25 59 46 115 46 125 0 15 -11 17 -86 17 -83 0 -85
-1 -82 -22z"/>
                                        <path d="M8847 4972 c-20 -22 -22 -83 -5 -100 17 -17 64 -15 82 4 23 22 30 62
17 90 -13 30 -69 34 -94 6z m53 -48 c0 -45 -24 -42 -28 4 -2 24 1 32 12 32 12
0 16 -10 16 -36z"/>
                                        <path d="M8322 4537 l-102 -102 0 -2218 0 -2217 312 0 313 0 102 102 103 103
0 2217 0 2218 -313 0 -312 0 -103 -103z"/>
                                        <path d="M7739 4503 c-24 -32 -67 -91 -97 -131 -52 -72 -59 -91 -28 -79 15 6
182 224 193 252 3 9 -1 15 -11 15 -8 0 -34 -26 -57 -57z"/>
                                        <path d="M7218 4521 c-32 -29 -33 -32 -33 -110 0 -95 13 -116 75 -116 56 0 90
35 90 92 0 54 -31 79 -85 69 -39 -8 -43 -1 -19 32 13 19 24 23 53 20 31 -3 36
0 39 20 3 20 -1 22 -42 22 -36 0 -52 -6 -78 -29z m77 -146 c0 -25 -4 -30 -24
-30 -18 0 -26 7 -32 27 -8 33 -2 40 31 36 20 -2 25 -8 25 -33z"/>
                                        <path d="M7405 4526 c-28 -28 -39 -79 -32 -144 11 -98 112 -124 160 -42 25 42
23 150 -4 184 -28 35 -89 37 -124 2z m76 -32 c8 -9 13 -41 14 -75 0 -65 -15
-90 -43 -73 -12 8 -18 28 -20 74 -2 35 -1 69 2 76 7 19 31 18 47 -2z"/>
                                        <path d="M7587 4532 c-20 -22 -22 -83 -5 -100 15 -15 53 -16 78 -2 24 13 36
71 21 99 -15 27 -71 29 -94 3z m53 -48 c0 -45 -24 -42 -28 4 -2 24 1 32 12 32
12 0 16 -10 16 -36z"/>
                                        <path d="M7742 4414 c-16 -11 -22 -25 -22 -52 0 -49 12 -65 49 -65 38 0 58 16
66 54 14 62 -42 99 -93 63z m48 -55 c0 -20 -4 -29 -12 -27 -15 5 -22 33 -14
54 9 24 26 6 26 -27z"/>
                                        <path d="M7142 3977 l-102 -102 0 -1938 0 -1937 312 0 313 0 102 102 103 103
0 1937 0 1938 -313 0 -312 0 -103 -103z"/>
                                        <path d="M6649 3939 c-15 -8 -189 -242 -189 -253 0 -4 8 -6 18 -6 10 0 42 35
79 88 34 48 76 106 93 129 30 41 30 60 -1 42z"/>
                                        <path d="M6067 3933 c-4 -3 -7 -35 -7 -70 l0 -63 34 0 c31 0 66 -20 66 -38 -1
-30 -33 -41 -83 -28 -27 6 -28 5 -25 -21 3 -24 8 -28 37 -31 74 -7 121 27 121
88 0 45 -22 67 -70 72 -27 2 -35 7 -35 23 0 16 8 21 45 25 37 4 46 9 48 28 3
21 0 22 -61 22 -35 0 -67 -3 -70 -7z"/>
                                        <path d="M6273 3916 c-25 -22 -28 -31 -31 -103 -3 -68 -1 -81 18 -105 45 -58
138 -26 154 53 15 75 -1 151 -34 169 -32 17 -78 11 -107 -14z m71 -32 c11 -11
16 -34 16 -78 0 -72 -14 -95 -46 -72 -25 18 -28 141 -4 156 19 12 16 13 34 -6z"/>
                                        <path d="M6450 3913 c-39 -50 -6 -121 50 -108 14 3 31 13 38 22 15 21 16 85 0
101 -22 22 -65 14 -88 -15z m58 -41 c2 -24 -1 -32 -12 -32 -12 0 -16 10 -16
36 0 45 24 42 28 -4z"/>
                                        <path d="M6605 3795 c-44 -43 -22 -115 35 -115 56 0 84 92 38 124 -31 22 -45
20 -73 -9z m50 -48 c0 -39 -19 -38 -23 2 -3 24 0 32 10 29 7 -3 13 -17 13 -31z"/>
                                        <path d="M5972 3357 l-102 -102 0 -1628 0 -1627 313 0 312 0 103 103 102 102
0 1628 0 1627 -313 0 -312 0 -103 -103z"/>
                                        <path d="M4873 3333 c-21 -14 -93 -152 -93 -178 0 -23 3 -25 50 -25 47 0 50
-2 50 -26 0 -22 4 -25 27 -22 18 2 29 10 31 22 3 11 10 26 18 34 18 17 18 29
-1 39 -10 6 -15 31 -17 83 -3 72 -4 75 -28 78 -14 1 -30 -1 -37 -5z m7 -108
c0 -41 -2 -45 -25 -45 -14 0 -25 3 -25 6 0 8 43 84 47 84 2 0 3 -20 3 -45z"/>
                                        <path d="M5015 3316 c-13 -13 -27 -40 -31 -60 -10 -55 1 -133 21 -156 20 -22
74 -27 107 -9 62 33 64 219 3 243 -35 13 -76 6 -100 -18z m79 -42 c3 -9 6 -45
6 -80 0 -50 -4 -66 -16 -71 -34 -13 -48 27 -45 130 1 32 4 37 25 37 13 0 27
-7 30 -16z"/>
                                        <path d="M5202 3324 c-17 -12 -22 -25 -22 -60 0 -38 4 -46 26 -56 21 -10 31
-9 52 3 22 12 28 23 30 58 2 23 -2 49 -9 57 -15 18 -49 18 -77 -2z m48 -55 c0
-19 -5 -29 -15 -29 -15 0 -22 50 -9 63 12 12 24 -4 24 -34z"/>
                                        <path d="M5296 3220 c-89 -123 -105 -150 -89 -150 22 0 48 32 192 238 21 31
22 32 3 32 -14 0 -47 -37 -106 -120z"/>
                                        <path d="M5342 3197 c-21 -25 -25 -66 -8 -92 20 -30 65 -34 89 -7 20 22 22 83
5 100 -16 16 -73 15 -86 -1z m56 -56 c3 -24 0 -32 -10 -29 -7 3 -14 19 -16 37
-3 24 0 32 10 29 7 -3 14 -19 16 -37z"/>
                                        <path d="M4797 2772 l-107 -107 0 -1333 0 -1332 313 0 312 0 103 103 102 102
0 1338 0 1337 -308 0 -307 0 -108 -108z"/>
                                        <path d="M3633 2725 c-21 -9 -33 -21 -33 -34 0 -17 3 -18 29 -8 38 14 63 3 59
-25 -2 -17 -11 -24 -33 -26 -29 -4 -48 -19 -39 -33 2 -4 19 -10 38 -13 23 -4
37 -14 41 -27 11 -33 -9 -45 -55 -32 -43 11 -50 9 -50 -18 0 -42 118 -41 153
2 23 28 22 74 -2 91 -17 12 -17 16 -5 33 47 63 -25 126 -103 90z"/>
                                        <path d="M3833 2721 c-38 -24 -48 -54 -47 -133 2 -78 22 -108 73 -108 53 0 69
8 85 39 30 60 27 164 -6 197 -24 24 -70 26 -105 5z m67 -50 c5 -11 10 -41 10
-66 0 -52 -14 -85 -35 -85 -8 0 -19 9 -25 19 -11 21 -14 110 -4 135 8 22 41
20 54 -3z"/>
                                        <path d="M4101 2615 c-86 -117 -102 -145 -83 -145 15 0 30 17 98 110 123 168
118 160 96 160 -14 0 -48 -38 -111 -125z"/>
                                        <path d="M3996 2708 c-49 -69 26 -146 84 -88 22 22 27 79 8 98 -21 21 -75 15
-92 -10z m60 -34 c9 -34 -10 -53 -25 -26 -11 21 -5 52 10 52 5 0 12 -12 15
-26z"/>
                                        <path d="M4150 2590 c-22 -22 -27 -79 -8 -98 7 -7 27 -12 44 -12 52 0 81 60
54 111 -14 25 -64 25 -90 -1z m56 -34 c6 -26 -1 -46 -16 -46 -12 0 -24 46 -15
59 11 18 24 12 31 -13z"/>
                                        <path d="M3622 2157 l-102 -102 0 -1028 0 -1027 312 0 313 0 102 102 103 103
0 1027 0 1028 -313 0 -312 0 -103 -103z"/>
                                        <path d="M3023 2093 c-33 -42 -146 -201 -157 -220 -5 -9 -1 -13 11 -13 12 0
50 45 111 129 50 71 92 131 92 135 0 19 -32 1 -57 -31z"/>
                                        <path d="M2472 2104 c-36 -25 -29 -46 13 -38 75 14 78 -35 8 -115 -64 -73 -58
-86 42 -86 75 0 80 1 83 23 3 20 0 22 -47 22 l-51 0 45 55 c50 62 59 109 27
138 -24 21 -90 22 -120 1z"/>
                                        <path d="M2687 2109 c-58 -34 -63 -209 -7 -239 25 -13 66 -13 92 1 29 16 48
70 48 137 0 77 -24 112 -78 112 -20 0 -45 -5 -55 -11z m61 -41 c15 -20 23 -89
14 -123 -9 -32 -39 -46 -53 -23 -10 15 -12 142 -2 151 10 11 30 8 41 -5z"/>
                                        <path d="M2857 2102 c-20 -21 -23 -82 -5 -100 7 -7 27 -12 45 -12 28 0 36 5
50 36 16 32 16 38 2 62 -19 34 -67 42 -92 14z m51 -51 c3 -24 0 -32 -10 -29
-7 3 -14 19 -16 37 -3 24 0 32 10 29 7 -3 14 -19 16 -37z"/>
                                        <path d="M3010 1982 c-16 -13 -22 -29 -22 -57 0 -59 43 -83 90 -49 30 21 32
98 3 114 -28 14 -46 13 -71 -8z m48 -54 c-2 -18 -7 -33 -13 -33 -5 0 -11 15
-13 33 -3 24 1 32 13 32 12 0 16 -8 13 -32z"/>
                                        <path d="M2452 1557 l-102 -102 0 -728 0 -727 313 0 312 0 103 103 102 102 0
728 0 727 -313 0 -312 0 -103 -103z"/>
                                        <path d="M1844 1508 c-28 -32 -174 -234 -174 -242 0 -3 8 -6 18 -6 10 1 51 49
105 125 49 68 88 128 88 132 1 17 -18 12 -37 -9z"/>
                                        <path d="M1303 1497 c-18 -12 -33 -30 -33 -39 0 -20 15 -24 25 -8 16 26 25 3
25 -64 0 -70 -1 -75 -22 -78 -15 -2 -24 -11 -26 -26 -3 -22 -2 -23 70 -20 67
3 73 5 76 26 3 17 -2 22 -17 22 -20 0 -21 5 -21 105 0 102 -1 105 -22 104 -13
0 -38 -10 -55 -22z"/>
                                        <path d="M1483 1496 c-25 -22 -28 -31 -31 -103 -3 -68 -1 -81 18 -105 44 -57
138 -24 154 53 15 75 -1 151 -34 169 -32 17 -78 11 -107 -14z m71 -32 c21 -20
22 -136 3 -153 -38 -31 -74 76 -48 142 12 31 23 34 45 11z"/>
                                        <path d="M1661 1494 c-24 -30 -27 -63 -8 -86 37 -48 107 -15 107 51 0 20 -5
42 -12 49 -21 21 -65 14 -87 -14z m57 -42 c2 -24 -1 -32 -12 -32 -12 0 -16 10
-16 36 0 45 24 42 28 -4z"/>
                                        <path d="M1815 1375 c-45 -44 -25 -115 31 -115 58 0 89 91 42 124 -31 22 -45
20 -73 -9z m50 -48 c0 -39 -19 -38 -23 2 -3 24 0 32 10 29 7 -3 13 -17 13 -31z"/>
                                        <path d="M1287 942 l-107 -107 0 -418 0 -417 312 0 313 0 102 102 103 103 0
422 0 423 -308 0 -307 0 -108 -108z"/>
                                    </g>
                                </svg>


                            </span>
                            <span className="text"> Reports </span>
                        </a>
                        <ul id="ddmenu_6" className="collapse dropdown-nav">
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/reports/invoice"}>Invoice Reports</Link>
                            </li>
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/reports/parts"}>Parts Reports</Link>
                            </li>
                            <li>
                                <Link to={"/manager/branch/" + props.branch_id + "/reports/services"}>Services Reports</Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </aside>
    );
}

Sidemenu.propTypes = {
    branch_id: PropTypes.any,
};

export default Sidemenu;