export const FilterMonths = () => {
    const month_list = [
        { name: "January", key: "01" },
        { name: "February", key: "02" },
        { name: "March", key: "03" },
        { name: "April", key: "04" },
        { name: "May", key: "05" },
        { name: "June", key: "06" },
        { name: "July", key: "07" },
        { name: "August", key: "08" },
        { name: "September", key: "09" },
        { name: "October", key: "10" },
        { name: "November", key: "11" },
        { name: "December", key: "12" }
    ];

    return month_list.map(month => {
        return <option key={month.key} value={month.key} >{month.name}</option>;
    });
};