import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import Layout from "../Layout";
import { fetchCustomers, fetchCustomer } from "../../../api/customer/Customer";
import { fetchCityById } from "../../../api/city/City";
import { partDecrementStock, fetchStockParts, partIncrementStock } from "../../../api/parts/Parts";
import { useReactToPrint } from "react-to-print";
import { fetchPartSelInvoice, partSelInvoiceUpdate } from "../../../api/partsellinvoice/PartSellInvoice";
import { fetchPartInvoiceAddedParts, deletePartInvoiceAddedParts, partInvoicePartSave } from "../../../api/partsellinvoiceparts/PartSellInvoicePart";
import { useParams } from "react-router-dom";
import PrintPartSellInvoice from "./PrintPartSellInvoice";

function EditPartsSellInvoice() {
    const [loading_status, setLoadingStatus] = useState("hide");

    const { id } = useParams();

    const [disable_price_update, setDisablePriceUpdate] = useState(false);
    const [payment_status, setPaymentStatus] = useState("");

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customer_data, setCustomersData] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [customers, setCustomers] = useState(null);

    const [selectedPart, setSelectedPart] = useState(null);
    const [part_list, setPartList] = useState([]);
    const [part_data, setPartData] = useState(null);

    const [customer_id, setCustomerId] = useState(0);
    const { branchid } = useParams();
    const branch_id = branchid;

    const [f_name, setFirstName] = useState("");
    const [l_name, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [mobile, setMobile] = useState("");
    const [city_name, setCityName] = useState("");

    const [added_parts, setAddedParts] = useState([]);

    const [disable_part_invoice, setDisable] = useState(false);
    const [add_parts_status] = useState(true);

    const [invoice_note, setInvoiceNote] = useState("");
    const [part_total, setPartsTotal] = useState(0);
    const [print_enable, setPrintEnable] = useState(true);

    const [parts_updated, setPartUpdated] = useState(false);

    const [part_invoice, setPartInvoice] = useState([]);
    const [fetched_added_parts, setFetchedAddedParts] = useState(null);
    const [branch_data] = useState([]);//JSON.parse(window.sessionStorage.getItem("branch_data"))[0];
    const Logo = window.sessionStorage.getItem("shop_logo");
    const [invoice_date, setInvoiceDate] = useState("");

    useEffect(function () {
        setLoadingStatus("show");
        fetchCustomers(branch_id, setCustomers);
        fetchPartSelInvoice(id, setPartInvoice);
    }, [id]);

    useEffect(function () {
        if (part_invoice.length !== 0) {
            setSelectedCustomer({ "value": part_invoice.customer_id, "label": part_invoice.f_name + " " + part_invoice.l_name });
            setInvoiceNote(part_invoice.invoice_note);
            fetchPartInvoiceAddedParts(id, setFetchedAddedParts);
            setPaymentStatus(part_invoice.payment_status);
            setLoadingStatus("hide");
            if (part_invoice !== "") {
                var arr = part_invoice.date.split(" ");
                setInvoiceDate(arr[0]);
                setLoadingStatus("hide");
            }
            if (part_invoice.payment_status === "payed") {
                setDisablePriceUpdate(true);
            } else {
                setDisablePriceUpdate(false);
            }
        }
    }, [part_invoice, id]);

    useEffect(function () {
        if (part_invoice.payment_status === payment_status) {
            if (parts_updated) {
                setPrintEnable(false);
            } else {
                setPrintEnable(true);
            }

        } else {
            setPrintEnable(false);
        }
    }, [payment_status]);


    useEffect(function () {
        if (customer !== null) {
            setFirstName(customer.f_name);
            setLastName(customer.l_name);
            setAddress(customer.address);
            setMobile(customer.mobile);
            fetchCityById(customer.city_id, "city_name", setCityName);
        }
    }, [customer]);

    useEffect(function () {
        if (part_data == null) {
            fetchStockParts(branch_id, setPartData);
        } else {
            const part_list = part_data.map(({ part_name, part_code, part_id, count }) => {
                return {
                    label: part_name + " " + part_code,
                    value: part_id,
                    quantity: 0,
                    max: count,
                    invoice_part_price: 0
                };
            });
            setPartList(part_list);
        }
    }, [part_data]);

    useEffect(function () {
        if (fetched_added_parts != null && fetched_added_parts.length !== 0) {
            if (added_parts.length === 0) {
                const new_data = fetched_added_parts.map(({ part_name, part_code, part_id, quantity, count }) => {
                    return {
                        label: part_name + " " + part_code,
                        value: part_id,
                        quantity: quantity,
                        max: quantity + count
                    };
                });
                setAddedParts([...new_data]);
            }

        }
    }, [fetched_added_parts]);

    useEffect(function () {
        if (customers !== null) {
            const listItems = customers.map(({ f_name, l_name, mobile, customer_id }) => {
                return {
                    label: f_name + " " + l_name + " " + mobile,
                    value: customer_id
                };
            });
            setCustomersData(listItems);
        }
    }, [customers]);

    useEffect(function () {
        if (selectedCustomer !== null) {
            setCustomerId(selectedCustomer.value);
            fetchCustomer(selectedCustomer.value, setCustomer);
        }
    }, [selectedCustomer]);

    const setPartPrice = (key, price) => {
        if (isNaN(price)) {
            return false;
        }
        var array = [...added_parts];
        array[key].invoice_part_price = price;
        setAddedParts([...array]);
        setPrintEnable(false);
        setPartUpdated(true);
    };

    const addSelectedPart = () => {
        if (!added_parts.some(item => selectedPart.value === item.value)) {
            if (selectedPart.max === 0) {
                Swal.fire(
                    "Opps...!",
                    selectedPart.label + " Stock value is 0",
                    "error"
                );
            } else {
                const new_data = {
                    label: selectedPart.label,
                    value: selectedPart.value,
                    quantity: selectedPart.quantity,
                    max: selectedPart.max,
                    invoice_part_price: selectedPart.invoice_part_price
                };
                setAddedParts([...added_parts, new_data]);
            }

        }
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Part Added",
            showConfirmButton: false,
            timer: 1000
        });
    };

    useEffect(function () {
        if (fetched_added_parts != null && fetched_added_parts.length !== 0) {
            if (added_parts.length === 0) {
                const new_data = fetched_added_parts.map(({ part_name, part_code, part_id, quantity, invoice_part_price, count }) => {
                    return {
                        label: part_name + " " + part_code,
                        value: part_id,
                        quantity: quantity,
                        max: quantity + count,
                        invoice_part_price: invoice_part_price
                    };
                });
                setAddedParts([...new_data]);
            }

        }
    }, [fetched_added_parts]);

    useEffect(function () {
        if (added_parts.length !== 0) {
            var new_part_price = 0;
            added_parts.forEach(element => {
                new_part_price = new_part_price + (parseFloat(element.quantity) * parseFloat(element.invoice_part_price));
                setPartsTotal(new_part_price);
            });
        }

    }, [added_parts]);

    const handleRemoveItem = (e) => {
        var value = e.target.value;
        var array = [...added_parts];
        if (value !== -1) {
            array.splice(value, 1);
            setAddedParts(array);
            setPartUpdated(true);
            setPrintEnable(false);
        }
    };

    const setQty = (key, quantity) => {
        var array = [...added_parts];
        array[key].quantity = quantity;
        setAddedParts([...array]);
        setPrintEnable(false);
        setPartUpdated(true);
    };

    const saveReturn = () => {
        if (add_parts_status) {
            deletePartInvoiceAddedParts(id);
            fetched_added_parts.forEach(element => {
                partIncrementStock(element.part_id, { value: element.quantity });
            });
            added_parts.forEach(element => {
                if (!isNaN(element.quantity)) {
                    partInvoicePartSave({ part_sell_invoice_id: id, invoice_part_price: element.invoice_part_price, part_id: element.value, quantity: element.quantity }, savePartsReturn);
                    partDecrementStock(element.value, { value: element.quantity });
                }
            });
        }
        Swal.fire(
            "Good job!",
            "Invice Updated..!",
            "success"
        );
        part_invoice.payment_status = payment_status;
        if (payment_status === "payed") {
            setDisablePriceUpdate(true);
        } else {
            setDisablePriceUpdate(false);
        }
        setDisable(false);
        setPrintEnable(true);
        setPartUpdated(false);
        setLoadingStatus("hide");
    };

    const savePartsReturn = () => {

    };

    const validateStock = (ele) => {
        if (parseInt(ele.max) < parseInt(ele.value)) {
            Swal.fire(
                "Out Of Stock",
                "You have only " + ele.max + " in your stock..!",
                "error"
            );
            setDisable(true);
        } else {
            setDisable(false);
        }
    };

    const validateDataset = (dataset) => {
        if (dataset.customer_id !== 0) {
            setDisable(true);
            setLoadingStatus("show");
            partSelInvoiceUpdate(id, dataset, saveReturn);
        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ customer_id: selectedCustomer.value, branch_id: branch_id, total: part_total, payment_status: payment_status, invoice_note: invoice_note });
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const print = () => {
        if (!print_enable) {
            Swal.fire(
                "Opps...!",
                "Click Update Invoice Before Print",
                "error"
            );
        } else {
            handlePrint();
        }
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/partsellinvoice/list", "text": "Part Invoice List" });
    const title = "Part Sell Invoice";

    const Content = <div className="row">
        <form onSubmit={handleSubmit}>

            <div className="invoice-card card-style mb-30">
                <div className="invoice-header">
                    <div className="invoice-for">
                        <h2 className="mb-10">Invoice Details</h2>
                    </div>
                </div>
                <div className="invoice-address">
                    <div className="address-item">
                        <h5 className="text-bold">Customer:</h5>
                        <h6>{f_name} {l_name}</h6>
                        <p className="text-sm">
                            {address} {city_name}
                        </p>
                        <p className="text-sm">
                            {mobile}
                        </p>
                    </div>
                    <div className="address-item">
                        <h5 className="text-bold">Payment Status:</h5>
                        <div className="select-position">
                            <select value={payment_status} onChange={e => setPaymentStatus(e.target.value)} >
                                <option value="pending">Pending</option>
                                <option value="payed">Payed</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="invoice-table table">
                        <thead>
                            <tr>
                                <th className="service">
                                    <h6 className="text-sm text-medium">Parts</h6>
                                </th>
                                <th className="service">
                                    <h6 className="text-sm text-medium">Qty</h6>
                                </th>
                                <th className="desc">
                                    <h6 className="text-sm text-medium">Price</h6>
                                </th>
                                <th className="desc">
                                    <h6 className="text-sm text-medium dis-price">Total</h6>
                                </th>
                                <th className="desc">
                                    <h6 className="text-sm text-medium">Actions</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {added_parts.map((object, key) => (
                                <tr key={key}>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <p>{object.label}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <input className="qty" disabled={(disable_price_update ? true : false)} value={object.quantity} onChange={e => { setQty(key, e.target.value); validateStock(e.target); }} type="number" name="quantity" min="1" max={object.max}></input>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width">
                                        <div className="lead">
                                            <div className="lead-text">
                                                <input className="price" disabled={(disable_price_update ? true : false)} value={object.invoice_part_price} onChange={e => { setPartPrice(key, e.target.value); }} type="text" name="price"></input>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="min-width price  ">
                                        <div className="lead">
                                            <div className="lead-text dis-price">
                                                {(parseFloat(object.invoice_part_price) * parseFloat(object.quantity)).toFixed(2)}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="action">
                                            <button type="button" disabled={(disable_price_update ? true : false)} className="btn btn-danger float-end" value={key} onClick={e => handleRemoveItem(e, "value")}>Remove</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td></td>
                                <td></td>
                                <td>
                                    <h4>Total</h4>
                                </td>
                                <td className="min-width price" >
                                    <div className="lead">
                                        <div className="lead-text dis-price tot">
                                            {(part_total).toFixed(2)}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="input-style-1">
                        <label>Invoice Note</label>
                        <textarea value={invoice_note} disabled={(disable_price_update ? true : false)} onChange={e => { setInvoiceNote(e.target.value); }} placeholder="Type Invoice Note" name="invoice_note" rows="5"></textarea>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="card-style mb-30">
                    <div className="select-style-1">
                        <label>Customer</label>
                        <div className="select-position">
                            <Select
                                isDisabled={disable_price_update}
                                defaultValue={customer_id}
                                onChange={setSelectedCustomer}
                                options={customer_data}
                            />
                        </div>
                    </div>
                    <div className="select-style-1">
                        <label>Parts</label>
                        <div className="select-position">
                            <Select
                                isDisabled={disable_price_update}
                                defaultValue={0}
                                onChange={setSelectedPart}
                                options={part_list}
                            />
                            <button className="btn btn-success" disabled={(disable_price_update ? true : false)} type="button" onClick={() => addSelectedPart()}>+ Add Part To Invoice</button>
                        </div>
                    </div>
                </div>
            </div>
            <input disabled={disable_part_invoice} className="btn btn-primary float-end" type="submit" value="Update Invoice" />
        </form>
        <div>
            <div
                style={{ display: "none" }}// This make ComponentToPrint show   only while printing
            >
                <PrintPartSellInvoice payment_status={payment_status} invoice_note={invoice_note} branch_data={branch_data} logo={Logo} part_invoice={part_invoice} added_parts={added_parts} total={(part_total).toFixed(2)} invoice_date={invoice_date} header={true} ref={componentRef} />
            </div>
            <button className="btn btn-info" onClick={print}>Print this out!</button>
        </div>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

export default EditPartsSellInvoice;