import { Config } from "../../configs/Config";
import { OneCallBack, PostDatasetCallback, OneCallBackPage } from "../middleware/Middleware";

export const fetchVehicle = (id, _callback) => {
    const url = `${Config().API_URL}vehicle/show/${id}`;
    OneCallBack(url, _callback);
};

export const fetchVehiclePaginate = (id, items, _callback) => {
    const url = `${Config().API_URL}vehicle/getpage/${id}/${items}`;
    OneCallBack(url, _callback);
};

export const fetchVehiclesSearch = (id, txt, _callback) => {
    const url = `${Config().API_URL}vehicle/search/${id}/${txt}`;
    OneCallBack(url, _callback);
};

export const fetchVehiclePaginatePage = (url, _callback) => {
    OneCallBackPage(url, _callback);
};

export const fetchVehicleByNumber = (id, _callback) => {
    const url = `${Config().API_URL}vehicle/showbynumber/${id}`;
    OneCallBack(url, _callback);
};

export const fetchVehicles = (id, _callback) => {
    const url = `${Config().API_URL}vehicle/get/${id}`;
    OneCallBack(url, _callback);
};

export const vehicleSave = (id, dataset, _callback) => {
    const url = `${Config().API_URL}vehicle/store/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};

export const vehicleBranchSave = (id, dataset, _callback) => {
    const url = `${Config().API_URL}vehicle/storebranch/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};

export const vehicleUpdate = (id, dataset, _callback) => {
    const url = `${Config().API_URL}vehicle/update/${id}`;
    PostDatasetCallback(url, dataset, _callback);
};