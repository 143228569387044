import React, { useEffect, useState } from "react";
import Select from "react-select";
import { fetchYearById } from "../../../api/year/Year";
import { fetchVehicle, fetchVehicles } from "../../../api/vehicle/Vehicle";
import { fetchCustomer, fetchCustomers } from "../../../api/customer/Customer";
import { fetchMakeByModel } from "../../../api/modelmake/ModelMake";
import { fetchCityById } from "../../../api/city/City";
import { fetchJob, jobUpdate } from "../../../api/job/Job";
import { jobPartSave, fetchAddedParts, deleteAddedParts } from "../../../api/jobpart/JobPart";
import { jobServiceSave, fetchAddedServices, deleteAddedServices } from "../../../api/jobservice/JobService";
import { fetchStockParts, partIncrementStock } from "../../../api/parts/Parts";
import { invoiceUpdate } from "../../../api/invoice/Invoice";
import { fetchActiveServiceList } from "../../../api/service/Service";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Layout from "../Layout";
import PropTypes from "prop-types";

function EditJob() {
    const title = "Edit Job";

    const { id } = useParams();

    const [loading_status, setLoadingStatus] = useState("hide");
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customer_data, setCustomersData] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [customers, setCustomers] = useState(null);

    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [vehicle_data, setVehicleData] = useState([]);
    const [vehicle_list, setVehicleList] = useState([]);
    const [vehicle, setVehicle] = useState(null);

    const [selectedService, setSelectedService] = useState(null);
    const [service_data, setServiceData] = useState(null);
    const [service_list, setServiceList] = useState([]);

    const [selectedPart, setSelectedPart] = useState(null);
    const [part_list, setPartList] = useState([]);
    const [part_data, setPartData] = useState(null);

    const [customer_id, setCustomerId] = useState(0);
    const [vehicle_id, setVehicleId] = useState(0);
    const [milage, setMilage] = useState(0);
    const [status, setStatus] = useState("");
    const { branchid } = useParams();
    const branch_id = branchid;

    const [f_name, setFirstName] = useState("");
    const [l_name, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [mobile, setMobile] = useState("");
    const [city_name, setCityName] = useState("");

    const [model_year, setModelYear] = useState(0);
    const [make_data, setMakeData] = useState([]);
    const [vehicle_number, setVehicleNumber] = useState("");

    const [fetched_added_parts, setFetchedAddedParts] = useState(null);
    const [fetched_added_services, setFetchedAddedServices] = useState(null);

    const [disable_job, setDisable] = useState(false);
    const [add_parts_services_status, setAddPartServicesStatus] = useState(true);

    const [job, setJob] = useState([]);
    const [show_invoice, setShowInvoice] = useState(false);

    const [row_counter, setRowCounter] = useState(0);
    const [parts_and_services, setPartsAndServices] = useState([]);

    function EditInvoice(props) {

        const show = props.show;
        if (show) {
            return <Link to={`/manager/branch/${branch_id}/invoice/edit/${props.invoice_id}`}>
                <button className="btn btn-info" type="button">Invoice</button>
            </Link>;
        }
        return false;
    }

    useEffect(function () {
        setLoadingStatus("show");
        fetchCustomers(branch_id, setCustomers);
        fetchVehicles(branch_id, setVehicleData);
        fetchJob(id, setJob);
    }, [id]);

    useEffect(function () {
        if (job.length !== 0) {
            setSelectedCustomer({ "value": job.customer_id, "label": job.f_name + " " + job.l_name });
            setSelectedVehicle({ "value": job.vehicle_id, "label": job.vehicle_number });
            setMilage(job.milage);
            fetchAddedParts(id, setFetchedAddedParts);
            fetchAddedServices(id, setFetchedAddedServices);
            setStatus(job.job_status);
            if (job.payment_status === "payed") {
                setAddPartServicesStatus(false);
            }
            if (job.job_status === "done") {
                setShowInvoice(true);
            }
            setLoadingStatus("hide");
        }
    }, [job, id]);

    useEffect(function () {
        if (fetched_added_parts != null && fetched_added_services != null) {
            var arr = [];
            var counter = 0;
            fetched_added_parts.forEach(element => {
                if (element.row == 0) {
                    arr[counter] = {
                        label: element.part_name + " " + element.part_code,
                        value: element.part_id,
                        quantity: element.quantity,
                        max: element.quantity + element.count,
                        row: counter + 1,
                        type: "part"
                    };
                    counter++;
                } else {
                    arr[element.row - 1] = {
                        label: element.part_name + " " + element.part_code,
                        value: element.part_id,
                        quantity: element.quantity,
                        max: element.quantity + element.count,
                        row: element.row,
                        type: "part"
                    };
                    if (counter < element.row) {
                        counter = element.row;
                    }
                }

            });

            fetched_added_services.forEach(element => {
                if (element.row == 0) {
                    arr[counter] = {
                        label: element.service_name,
                        value: element.service_list_id,
                        quantity: 0,
                        max: 0,
                        row: counter + 1,
                        type: "service"
                    };
                    counter++;
                } else {
                    arr[element.row - 1] = {
                        label: element.service_name,
                        value: element.service_list_id,
                        quantity: 0,
                        max: 0,
                        row: element.row,
                        type: "service"
                    };
                    if (counter < element.row) {
                        counter = element.row;
                    }
                }

            });
            var filtered = arr.filter(function (el) {
                return el != null;
            });
            setRowCounter(counter);
            setPartsAndServices([...filtered]);

        }
    }, [fetched_added_parts, fetched_added_services]);

    useEffect(function () {
        if (customer !== null) {
            setFirstName(customer.f_name);
            setLastName(customer.l_name);
            setAddress(customer.address);
            setMobile(customer.mobile);
            fetchCityById(customer.city_id, "city_name", setCityName);
        }
    }, [customer]);

    useEffect(function () {
        if (vehicle !== null) {
            fetchYearById(vehicle.model_year_id, "model_year", setModelYear);
            setVehicleNumber(vehicle.vehicle_number);
            fetchMakeByModel(vehicle.model_id, setMakeData);
        }
    }, [vehicle]);

    useEffect(function () {
        if (service_data == null) {
            fetchActiveServiceList(branch_id, setServiceData);
        } else {
            const service_list = service_data.map(({ service_name, service_list_id }) => {
                return {
                    label: service_name,
                    value: service_list_id
                };
            });
            setServiceList(service_list);
        }
    }, [service_data]);

    useEffect(function () {
        if (part_data == null) {
            fetchStockParts(branch_id, setPartData);
        } else {
            const part_list = part_data.map(({ part_name, part_code, part_id, count }) => {
                return {
                    label: part_name + " " + part_code,
                    value: part_id,
                    quantity: 0,
                    max: count
                };
            });
            setPartList(part_list);
        }
    }, [part_data]);

    useEffect(function () {
        if (customers !== null) {
            const listItems = customers.map(({ f_name, l_name, mobile, customer_id }) => {
                return {
                    label: f_name + " " + l_name + " " + mobile,
                    value: customer_id
                };
            });
            setCustomersData(listItems);
        }
    }, [customers]);

    useEffect(function () {
        if (vehicle_data !== null) {
            const vehicle_list = vehicle_data.map(({ vehicle_number, vehicle_id }) => {
                return {
                    label: vehicle_number,
                    value: vehicle_id
                };
            });
            setVehicleList(vehicle_list);
        }
    }, [vehicle_data]);

    useEffect(function () {
        if (selectedCustomer !== null) {
            setCustomerId(selectedCustomer.value);
            fetchCustomer(selectedCustomer.value, setCustomer);
        }
    }, [selectedCustomer]);

    useEffect(function () {
        if (selectedVehicle !== null) {
            setVehicleId(selectedVehicle.value);
            fetchVehicle(selectedVehicle.value, setVehicle);
        }
    }, [selectedVehicle]);

    const addSelectedPart = () => {
        if (!parts_and_services.some(item => selectedPart.value === item.value)) {
            if (selectedPart.max === 0) {
                Swal.fire(
                    "Opps...!",
                    selectedPart.label + " Stock value is 0",
                    "error"
                );
            } else {
                const new_data = {
                    label: selectedPart.label,
                    value: selectedPart.value,
                    quantity: selectedPart.quantity,
                    max: selectedPart.max,
                    row: row_counter + 1,
                    type: "part"
                };
                setRowCounter(row_counter + 1);
                setPartsAndServices([...parts_and_services, new_data]);
            }

        }
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Part Added",
            showConfirmButton: false,
            timer: 1000
        });
    };

    const handleRemoveItem = (e) => {
        var value = e.target.value;
        var array = [...parts_and_services];
        if (value !== -1) {
            array.splice(value, 1);
            setPartsAndServices(array);
            setRowCounter(row_counter - 1);
        }
    };

    const setQty = (key, quantity) => {
        var array = [...parts_and_services];
        array[key].quantity = quantity;
        setPartsAndServices([...array]);
    };

    const addSelectedService = () => {
        if (!parts_and_services.some(item => selectedService.value === item.value)) {
            const new_data = {
                label: selectedService.label,
                value: selectedService.value,
                quantity: 0,
                max: 0,
                row: row_counter + 1,
                type: "service"
            };
            setRowCounter(row_counter + 1);
            setPartsAndServices([...parts_and_services, new_data]);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Service Added",
                showConfirmButton: false,
                timer: 1000
            });
        }
    };

    const updateReturn = () => {
        setLoadingStatus("hide");
        console.log("fetched_added_parts", fetched_added_parts);
        console.log("parts_and_services", parts_and_services);
        var counter = 0;
        if (add_parts_services_status) {

            deleteAddedServices(id);
            deleteAddedParts(id);
            invoiceUpdate(job.invoice_id, { total: 0, payment_status: "pending" });
            counter = 0;

            fetched_added_parts.forEach(element => {
                partIncrementStock(element.part_id, { value: element.quantity });
                counter++;
            });

        }
        if (add_parts_services_status) {
            deleteAddedServices(id);
            deleteAddedParts(id);
            invoiceUpdate(job.invoice_id, { total: 0, payment_status: "pending" });

            counter = 0;

            fetched_added_parts.forEach(element => {
                partIncrementStock(element.part_id, { value: element.quantity });
                counter++;
                if (counter === fetched_added_parts.length) {
                    parts_and_services.forEach(element => {
                        if (element.type === "part") {
                            if (!isNaN(element.quantity)) {
                                jobPartSave({ job_id: id, job_part_price: 0, job_part_service_charge: 0, job_part_status: "active", part_id: element.value, quantity: element.quantity, row: element.row }, savePartsReturn);
                                //partDecrementStock(element.value, { value: element.quantity });
                            }
                        } else if (element.type === "service") {
                            jobServiceSave({ job_id: id, job_service_price: 0, job_service_status: "active", service_list_id: element.value, row: element.row }, savePartsReturn);
                        }
                    });
                }
            });
            if (fetched_added_parts.length === 0) {
                parts_and_services.forEach(element => {
                    if (element.type === "part") {
                        if (!isNaN(element.quantity)) {
                            jobPartSave({ job_id: id, job_part_price: 0, job_part_service_charge: 0, job_part_status: "active", part_id: element.value, quantity: element.quantity, row: element.row }, savePartsReturn);
                            //partDecrementStock(element.value, { value: element.quantity });
                        }
                    } else if (element.type === "service") {
                        jobServiceSave({ job_id: id, job_service_price: 0, job_service_status: "active", service_list_id: element.value, row: element.row }, savePartsReturn);
                    }
                });
            }
        }
        if (status === "done") {
            setShowInvoice(true);
        } else {
            setShowInvoice(false);
        }
        Swal.fire(
            "Good job!",
            "Job Updated..!",
            "success"
        );
        setLoadingStatus("hide");
    };

    const savePartsReturn = () => {
    };

    const validateStock = (ele) => {
        if (parseInt(ele.max) < parseInt(ele.value)) {
            Swal.fire(
                "Out Of Stock",
                "You have only " + ele.max + " in your stock..!",
                "error"
            );
            setDisable(true);
        } else {
            setDisable(false);
        }
    };

    const validateDataset = (dataset) => {
        if (dataset.customer_id !== 0 && dataset.vehicle_id !== 0 && dataset.milage !== "" && !isNaN(dataset.milage)) {
            setLoadingStatus("show");
            jobUpdate(id, dataset, updateReturn);
        } else {
            Swal.fire(
                "Empty Values Detected...!",
                "Check Values Before Save..!",
                "error"
            );
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        validateDataset({ customer_id: selectedCustomer.value, vehicle_id: selectedVehicle.value, milage: milage, job_status: status, branch_id: branch_id });
    };

    const [quickButton] = useState({ "link": "/manager/branch/" + branch_id + "/job/list", "text": "Job List" });

    const Content = <div className="row">
        <form onSubmit={handleSubmit}>
            <div className="invoice-card card-style mb-30">
                <div className="invoice-header">
                    <div className="invoice-for">
                        <h2 className="mb-10">Job Details</h2>
                    </div>
                </div>
                <div className="invoice-address">
                    <div className="address-item">
                        <h5 className="text-bold">Customer:</h5>
                        <h6>{f_name} {l_name}</h6>
                        <p className="text-sm">
                            {address} {city_name}
                        </p>
                        <p className="text-sm">
                            {mobile}
                        </p>
                    </div>
                    <div className="address-item">
                        <h5 className="text-bold">Vehicle:</h5>
                        <h6>{vehicle_number}</h6>
                        <p className="text-sm">
                            {make_data.make_name} {make_data.model_name} {model_year}<br></br>Milage : {milage} KM
                        </p>
                    </div>
                    <div className="address-item">
                        <h5 className="text-bold">Status:</h5>
                        <div className="select-position">
                            <select value={status} onChange={e => setStatus(e.target.value)} >
                                <option value="active">Active</option>
                                <option value="on-hold">On Hold</option>
                                <option value="done">Done</option>
                                <option value="canceled">Canceled</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="invoice-table table">
                        <thead>
                            <tr>
                                <th className="service">
                                    <h6 className="text-sm text-medium">Service / Parts</h6>
                                </th>
                                <th className="service">
                                    <h6 className="text-sm text-medium">Qty</h6>
                                </th>
                                <th className="desc">
                                    <h6 className="text-sm text-medium">Actions</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {parts_and_services.map((object, key) => (
                                object.type === "part"
                                    ?
                                    <tr key={key}>
                                        <td className="min-width">
                                            <div className="lead">
                                                <div className="lead-text">
                                                    <p>{object.label}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="min-width">
                                            <div className="lead">
                                                <div className="lead-text">
                                                    <input className="qty" disabled={!add_parts_services_status} value={object.quantity} onChange={e => { setQty(key, e.target.value); validateStock(e.target); }} type="number" name="quantity" min="1" max={object.max}></input>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                <button type="button" className="btn btn-danger float-end" value={key} onClick={e => handleRemoveItem(e, "value")}>Remove</button>
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    <tr key={key}>
                                        <td className="min-width">
                                            <div className="lead">
                                                <div className="lead-text">
                                                    <p>{object.label}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="min-width">
                                            <div className="lead">
                                                <div className="lead-text">
                                                    <p></p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                <button type="button" className="btn btn-danger float-end" value={key} onClick={e => handleRemoveItem(e, "value")}>Remove</button>
                                            </div>
                                        </td>
                                    </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="card-style mb-30">
                    <div className="select-style-1">
                        <label>Parts</label>
                        <div className="select-position">
                            <Select
                                defaultValue={0}
                                onChange={setSelectedPart}
                                options={part_list}
                            />
                            <button disabled={!add_parts_services_status} className="btn btn-success" type="button" onClick={() => addSelectedPart()}>+ Add Part To Job</button>
                        </div>
                    </div>
                    <div className="select-style-1">
                        <label>Services</label>
                        <div className="select-position">
                            <Select
                                defaultValue={0}
                                onChange={setSelectedService}
                                options={service_list}
                            />
                            <button disabled={!add_parts_services_status} className="btn btn-success" type="button" onClick={() => addSelectedService()}>+ Add Service To Job</button>
                        </div>
                    </div>
                    <div className="select-style-1">
                        <label>Change Customer</label>
                        <div className="select-position">
                            <Select
                                defaultValue={customer_id}
                                onChange={setSelectedCustomer}
                                options={customer_data}
                            />
                        </div>
                    </div>
                    <div className="select-style-1">
                        <label>Change Vehicle</label>
                        <div className="select-position">
                            <Select
                                defaultValue={vehicle_id}
                                onChange={setSelectedVehicle}
                                options={vehicle_list}
                            />
                        </div>
                    </div>
                    <div className="input-style-1">
                        <label>Milage</label>
                        <input value={milage} onChange={e => setMilage(e.target.value)} name="milage" type="text" placeholder="Milage" />
                    </div>
                </div>
            </div>
            <EditInvoice show={show_invoice} invoice_id={job.invoice_id} />
            <input disabled={disable_job} className="btn btn-primary float-end" type="submit" value="Update Job" />
        </form>
    </div>;
    return (
        <Layout branch_id={branch_id} loading={loading_status} title={title} component={Content} quickButton={quickButton} />
    );
}

EditJob.propTypes = {
    invoice_id: PropTypes.any,
    show: PropTypes.any,
};

export default EditJob;