import { useState } from "react";
import PropTypes from "prop-types";

function CustomerView(props) {
    const [customer] = useState(props.data);

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Customer Details</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="card-style mb-30">
                            <div className="input-style-1">
                                <label>First Name</label>
                                <input value={customer.f_name} disabled={true} name='f_name' type="text" placeholder="First Name" />
                            </div>
                            <div className="input-style-1">
                                <label>Last Name</label>
                                <input value={customer.l_name} disabled={true} name='l_name' type="text" placeholder="Last Name" />
                            </div>
                            <div className="input-style-2">
                                <label>Address</label>
                                <input value={customer.address} disabled={true} name='address' type="text" placeholder="Address" />
                            </div>
                            <div className="input-style-1">
                                <label>Mobile</label>
                                <input value={customer.mobile} disabled={true} name='mobile' type="text" placeholder="Mobile" />
                            </div>
                            <div className="input-style-1">
                                <label>City</label>
                                <input value={customer.city_name} disabled={true} name='city' type="text" placeholder="City" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={() => { window.location.href = `/manager/branch/${props.branch_id}/customer/edit/${customer.customer_id}`;}}>Edit</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

CustomerView.propTypes = {
    data: PropTypes.any,
    id: PropTypes.any,
    branch_id: PropTypes.any,
};

export default CustomerView;