import { fetchUserShopAndBranches } from "../../../api/userdashboard/UserDashboard";
import UserShopsBranches from "../../../components/userdashboard/UserShopsBranches";
import { LoginCheck } from "../../common/LoginCheck";
import MainLayout from "../MainLayout";
import { useEffect, useState } from "react";

function Dashboard() {
    const title = "Manager Dashboard";
    const [loading_status, setLoadingStatus] = useState("hide");
    const [user_data, setUserData] = useState([]);
    const user_id = window.sessionStorage.getItem("user_id");

    useEffect(function () {
        fetchUserShopAndBranches(user_id, setUserData);
    }, [user_id]);

    useEffect(function () {
        LoginCheck();
        setLoadingStatus("hide");
    }, []);

    const Content = <div className="row">
        <UserShopsBranches user_data={user_data} />
    </div>;
    return (
        <MainLayout title={title} loading={loading_status} component={Content} />
    );

}

export default Dashboard;